import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from 'mdi-react/CheckIcon';

const CheckBox = ({
  className,
  color,
  disabled,
  field: {
    name, value, onChange, onBlur,
  },
  id,
  label,
}) => (
  <label
    className={`checkbox-btn ${disabled ? ' disabled' : ''} ${className ? ` checkbox-btn--${className}` : ''}`}
    htmlFor={id}
  >
    <input
      name={name}
      id={id}
      type="checkbox"
      value={value}
      checked={value}
      onChange={onChange}
      onBlur={onBlur}
      className="checkbox-btn__checkbox"
    />
    <span
      className="checkbox-btn__checkbox-custom"
      style={color ? { background: color, borderColor: color } : {}}
    >
      <CheckIcon />
    </span>
    <span className="checkbox-btn__label">
      {label}
    </span>
  </label>
);

CheckBox.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

CheckBox.defaultProps = {
  className: '',
  color: '',
  disabled: false,

};

export default CheckBox;
