import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import ModalFormWrapper from '../../../../../shared/components/form/ModalFormWrapper';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
});

const PackingSlip = ({ id }) => {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <b className="mr-2">Packing Slip</b>
      <ModalFormWrapper
        visible={visible}
        initialValues={{ id }}
        validationSchema={validationSchema}
        formUrlSuffix={`/${id}/print_packing_slip`}
        formTitle="Confirm Print Packing Slip"
        successModalTitle="Packing Slip Printed!"
        successModalMessage="This page will be reloaded!"
        submitButtonText="Confirm"
        onClose={() => setVisible(false)}
      />
      <span
        style={{ color: '#70bbfd', cursor: 'pointer' }}
        className="ml-3"
        role="link"
        tabIndex={0}
        onClick={() => setVisible(true)}
        onKeyDown={() => setVisible(true)}
      >
        Print Packing Slip
      </span>
    </div>
  );
};

PackingSlip.propTypes = {
  id: PropTypes.number,
};

PackingSlip.defaultProps = {
  id: null,
};

export default withRouter(PackingSlip);
