import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';

const Information = ({
  resourceitem: {
    name,
    business,
    address1,
    address2,
    city,
    state,
    country,
    zip,
  },
}) => (
  <Row>
    <Col xs={12}>
      <h3 className="page-title mb-0">
        Address Details
      </h3>
    </Col>
    <Col md={12}>
      <Card className="pb-0">
        <CardBody className="pt-3 pb-0">
          <div className="project-summary">
            <Row>
              <Col lg={6}>
                <table className="project-summary__info ml-2 mb-0">
                  <tbody>
                    <tr>
                      <th>Recipient:</th>
                      <td>{ name }</td>
                    </tr>
                    <tr>
                      <th>Business:</th>
                      <td>{ business }</td>
                    </tr>
                    <tr>
                      <th>Address 1:</th>
                      <td>{ address1 }</td>
                    </tr>
                    {
                      address2
                        ? (
                          <tr>
                            <th>Address 2:</th>
                            <td>{ address2 }</td>
                          </tr>
                        )
                        : null
                    }
                  </tbody>
                </table>
              </Col>
              <Col lg={6}>
                <table className="project-summary__info ml-2 mb-0">
                  <tbody>
                    <tr>
                      <th>City:</th>
                      <td>{ city }</td>
                    </tr>
                    <tr>
                      <th>State:</th>
                      <td>{ state }</td>
                    </tr>
                    <tr>
                      <th>Postal Code:</th>
                      <td>{ zip }</td>
                    </tr>
                    <tr>
                      <th>Country:</th>
                      <td>{ country }</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

Information.propTypes = {
  resourceitem: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    externalOrderId: PropTypes.string,
    carrierServiceCode: PropTypes.string,
    processedDate: PropTypes.string,
    orderTime: PropTypes.string,
    requestorId: PropTypes.string,
    requestorTeamId: PropTypes.string,
    name: PropTypes.string,
    business: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string,
  }),
};

Information.defaultProps = {
  resourceitem: {},
};

export default Information;
