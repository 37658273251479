import CryptoJS from 'crypto-js';

// Note that for larger files, you may want to hash them incrementally.
// Taken from https://stackoverflow.com/questions/768268/
const md5FromFile = (file) => new Promise((resolve, reject) => {
  // FileReader is event driven, does not return promise
  // Wrap with promise api so we can call w/ async await
  // https://stackoverflow.com/questions/34495796
  const reader = new FileReader();

  reader.onload = (fileEvent) => {
    const binary = CryptoJS.lib.WordArray.create(fileEvent.target.result);
    const md5 = CryptoJS.MD5(binary);
    resolve(md5);
  };
  reader.onerror = () => {
    // eslint-disable-next-line prefer-promise-reject-errors
    reject('oops, something went wrong with the file reader.');
  };
  // For some reason, readAsBinaryString(file) does not work correctly,
  // so we will handle it as a word array
  reader.readAsArrayBuffer(file);
});

export const fileChecksum = async (file) => {
  const md5 = await md5FromFile(file);
  const checksum = md5.toString(CryptoJS.enc.Base64);
  return checksum;
};

export const uploadFileToS3 = async (file, { directUpload }) => {
  const awsRes = await fetch(
    directUpload.url,
    {
      method: 'PUT',
      headers: {
        'Content-Type': directUpload.headers.ContentType,
        'Content-MD5': directUpload.headers.ContentMD5,
      },
      body: file,
    },
  );
  return awsRes;
};

export const generateFileParams = async (file) => {
  const checksum = await fileChecksum(file);
  return (
    {
      file: {
        filename: file.name,
        byte_size: file.size,
        checksum,
        content_type: file.type,
        metadata: {},
      },
    }
  );
};
