import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
});

const Form = () => (
  <Container>
    <ShowContainer>
      {
        ({ id }) => {
          if (!id) return null;
          const initialValues = { id };

          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/cancel`}
              formTitle="Cancel Order Confirmation"
              formSubTitle={`Please confirm that you would like to cancel Order #${id}`}
              successModalTitle="Order Marked As Canceled!"
              successModalMessage={`You'll be sent back to the Order #${id} page`}
              submitButtonText="Confirm Cancel"
              successUrl="/orders/sales-orders"
            />
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Form));
