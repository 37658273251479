import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Container } from 'reactstrap';
import searchApiRequest, {
  updatePageAndSearch as updateApiPageAndSearch,
  updateFilterAndSearch as updateApiFilterAndSearch,
} from '../../../redux/actions/indexActions';

import {
  getErrors,
  getFilter,
  getList,
  getDefaultFilter,
  getSearchMeta,
} from '../../../redux/reducers/indexReducer';
import { getApiPending } from '../../../redux/reducers/apiReducer';
import { getFilterables } from '../../../redux/reducers/authReducer';
import IndexTableContext from './IndexTableContext';
import PageHeader from './Header';
import FilterBarSearchDisplay from './FilterBarSearchDisplay';
import FilterBarSideDisplay from './FilterBarSideDisplay';
import Pagination from './Pagination';
import PaginationInfo from './PaginationInfo';
import BorderedTable from './BorderedTable';
import FilterBadgesDisplay from './FilterBadgesDisplay';

class IndexTableContainer extends Component {
  constructor() {
    super();
    this.state = {
      displayFilter: false,
    };
  }

  componentDidMount() {
    const { searchRequest, history } = this.props;
    searchRequest(history);
  }

  handleToggleDisplayFilter = () => {
    const { displayFilter } = this.state;
    this.setState({ displayFilter: !displayFilter });
  }

  handleResetFilterKey = (key) => {
    const { updateFilterAndSearch: update, defaultFilter, history } = this.props;
    const resetValues = {};
    if (key instanceof Array) {
      // eslint-disable-next-line
      key.map(k => resetValues[k] = defaultFilter[k]);
    } else {
      resetValues[key] = defaultFilter[key];
    }
    update(resetValues, history);
  }

  render() {
    const {
      buildBadgeList,
      createRows,
      defaultFilter,
      errors,
      exportButton,
      filter,
      filterForm,
      filterables,
      heads,
      history,
      list,
      newButton,
      newButtonText,
      newFormUri,
      pageTitle,
      searchMeta,
      searchPlaceholder,
      updateFilterAndSearch,
      updatePageAndSearch,
      validSearchMin,
    } = this.props;

    const { displayFilter } = this.state;
    return (
      <div className="product-list-wrapper">
        {
          errors.length > 1 && errors.map((e) => (
            <span className="product-list-error">{e}</span>
          ))
        }
        {
          !(errors.length > 1) && (
            <IndexTableContext.Provider
              value={{
                buildBadgeList,
                defaultFilter,
                displayFilter,
                exportButton,
                filter,
                filterables,
                filterForm,
                heads,
                history,
                newButton,
                newButtonText,
                newFormUri,
                onResetFilterKey: this.handleResetFilterKey,
                onToggleDisplayFilter: this.handleToggleDisplayFilter,
                pageTitle,
                rows: createRows(list),
                searchMeta,
                searchPlaceholder,
                updateFilterAndSearch,
                updatePageAndSearch,
                validSearchMin,
              }}
            >
              <Container className="dashboard">
                <PageHeader />
                <FilterBarSearchDisplay />
                <FilterBarSideDisplay />
                <FilterBadgesDisplay />
                <PaginationInfo />
                <BorderedTable />
                <Pagination />
              </Container>
            </IndexTableContext.Provider>
          )
        }
      </div>
    );
  }
}

IndexTableContainer.propTypes = {
  createRows: PropTypes.func.isRequired,
  buildBadgeList: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  filter: PropTypes.object.isRequired,
  filterForm: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filterables: PropTypes.object.isRequired,
  // eslint-disable-next-line
  defaultFilter: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  heads: PropTypes.arrayOf(PropTypes.object).isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType(
        [
          PropTypes.string,
          PropTypes.number,
        ],
      ),
    }),
  ),
  exportButton: PropTypes.bool,
  newButton: PropTypes.bool,
  newButtonText: PropTypes.string,
  newFormUri: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
  searchRequest: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  searchMeta: PropTypes.shape({
    totalPages: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
  updateFilterAndSearch: PropTypes.func.isRequired,
  updatePageAndSearch: PropTypes.func.isRequired,
  validSearchMin: PropTypes.number,
};

IndexTableContainer.defaultProps = {
  errors: [],
  list: [],
  newButtonText: 'New',
  newButton: false,
  exportButton: false,
  newFormUri: '',
  validSearchMin: 1,
};

const mapStateToProps = (state, ownProps) => {
  const { history } = ownProps;
  return {
    errors: getErrors(history, state),
    defaultFilter: getDefaultFilter(history),
    filter: getFilter(history, state),
    filterables: getFilterables(state),
    list: getList(history, state),
    pendingRequest: getApiPending(state),
    searchMeta: getSearchMeta(history, state),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  searchRequest: searchApiRequest,
  updateFilterAndSearch: updateApiFilterAndSearch,
  updatePageAndSearch: updateApiPageAndSearch,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IndexTableContainer));
