import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import FormSectionWrapper from '../../shared/components/form/FormSectionWrapper';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
});

const Form = () => (
  <Container>
    <ShowContainer>
      {
        ({ id, fulfillmentType, relatedShipmentIds }) => {
          if (!id || !fulfillmentType) return null;
          const initialValues = { id };
          const successResource = fulfillmentType === 'new_order' ? 'sales' : 'return';
          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/cancel`}
              formTitle="Cancel Shipment Confirmation"
              formSubTitle={`Please confirm that you would like to cancel Shipment #${id}`}
              successModalTitle="Shipment Marked As Canceled!"
              successModalMessage="You'll be sent back to the Shipments index page"
              submitButtonText="Confirm Cancel"
              successUrl={`/orders/${successResource}-shipments`}
            >
              <FormSectionWrapper>
                {
                  relatedShipmentIds.length > 0
                  && (
                    <span className="ml-3">
                      <h5>This will cancel the following shipments:</h5>
                      { relatedShipmentIds.map((s) => <h6 className="ml-3">Shipment ID: {s}</h6>) }
                    </span>
                  )
                }
              </FormSectionWrapper>
            </FormWrapper>
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Form));
