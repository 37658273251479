import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import DatePickerField from '../../shared/components/form/DatePickerFfield';
import TextField from '../../shared/components/form/TextField';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
  processedDate: Yup.string().required('Required'),
  palletCount: Yup.number()
    .required('Required')
    .integer('Must be whole number')
    .moreThan(-1, 'Must be at least 0')
    .typeError('Enter a valid number'),
  actualQuantity: Yup.number()
    .required('Required')
    .integer('Must be whole number')
    .moreThan(-1, 'Must be at least 0')
    .typeError('Enter a valid number'),
});

const Form = () => (
  <Container>
    <ShowContainer>
      {
        ({ id, expectedQuantity, itemName }) => {
          if (!id) return null;
          const initialValues = {
            id, processedDate: '', palletCount: '', actualQuantity: '',
          };

          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/mark_as_processed`}
              formTitle="Mark As Processed Form"
              formSubTitle={`${itemName} - Expecting ${expectedQuantity}`}
              successModalTitle="Receiving Shipment Updated!"
              successModalMessage="You'll be sent back to the Receiving Shipments index"
              submitButtonText="Mark As Processed"
              successUrl="/orders/receiving-shipments"
            >
              <div
                className="customizer__form-group-wrap no-bottom-line col-md-12"
                style={{ width: '100%' }}
              >
                <div className="mx-3">
                  <Row style={{ width: '100%' }}>
                    <Col lg={4}>
                      <div className="form__form-group">
                        <TextField
                          fieldName="palletCount"
                          label="Pallet Count"
                          placeholder="Pallet Count..."
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="form__form-group">
                        <TextField
                          fieldName="actualQuantity"
                          label="Actual Quantity"
                          placeholder="Actual Quantity..."
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="form__form-group">
                        <DatePickerField
                          fieldName="processedDate"
                          label="Processed Date"
                          placeholder="Processed Date..."
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </FormWrapper>
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Form));
