import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import LogInformation from './components/LogInformation';
import LogLedger from './components/LogLedger';
import ShowContainer from '../../shared/components/show/ShowContainer';

const Index = () => (
  <Container>
    <ShowContainer>
      {
        (resourceitem) => {
          if (Object.keys(resourceitem).length === 0) return null;
          return (
            <Container>
              <LogInformation resourceitem={resourceitem} />
              <hr />
              <LogLedger resourceitem={resourceitem} />
            </Container>
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Index));
