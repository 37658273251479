import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { formatStockType } from '../../../helpers/resourceItem';

const ItemInformation = ({
  resourceitem: {
    id,
    description,
    itemName,
    initiatableType,
    initiatableId,
    action,
    toStockType,
    fromStockType,
    diff,
  },
}) => (
  <Row>
    <Col md={12}>
      <h3 className="page-title mb-0">
        Inventory Log Detail Page
      </h3>
      {
        // <Button className="project-summary__btn mr-4" outline size="sm">Edit</Button>
      }
    </Col>
    <Col md={12}>
      <Card className="pb-0">
        <CardBody className="pt-3 pb-0">
          <div className="project-summary">
            <Row>
              <Col lg={6}>
                <table className="project-summary__info ml-2">
                  <tbody>
                    <tr>
                      <th>Database ID:</th>
                      <td>{ id }</td>
                    </tr>
                    <tr>
                      <th>Description:</th>
                      <td>{ description }</td>
                    </tr>
                    <tr>
                      <th>Related Record:</th>
                      <td>{ initiatableType } - { initiatableId}</td>
                    </tr>
                    <tr>
                      <th>Event:</th>
                      <td>{ action }</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
              <Col lg={6}>
                <table className="project-summary__info ml-2">
                  <tbody>
                    <tr>
                      <th>Item Name:</th>
                      <td>{ itemName }</td>
                    </tr>
                    <tr>
                      <th>Amount:</th>
                      <td>{ diff }</td>
                    </tr>
                    <tr>
                      <th>To Stock Type:</th>
                      <td>{ formatStockType(toStockType) }</td>
                    </tr>
                    <tr>
                      <th>From Stock Type:</th>
                      <td>{ formatStockType(fromStockType) }</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

ItemInformation.propTypes = {
  resourceitem: PropTypes.shape({
    id: PropTypes.number,
    itemName: PropTypes.string,
    description: PropTypes.string,
    initiatableType: PropTypes.string,
    initiatableId: PropTypes.number,
    action: PropTypes.string,
    diff: PropTypes.number,
    toStockType: PropTypes.string,
    fromStockType: PropTypes.string,
  }),
};

ItemInformation.defaultProps = {
  resourceitem: {},
};

export default ItemInformation;
