import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { formatOrderStatus } from '../../helpers/resourceItem';

class SalesOrdersIndexContainer extends Component {
  formatContents = (contents, shipmentId) => (
    contents.map((content) => {
      const { quantity, description } = content;
      return (
        <div key={`contents${shipmentId}${description}`} className="ml-4">
          {`Qty ${quantity}: ${description}`}
        </div>
      );
    })
  )

  createRows = (list) => (
    list.map((salesOrder) => {
      const {
        id,
        status,
        business,
        name,
        lineItemsCount,
        shipmentsCount,
        orderDate,
      } = salesOrder;

      let displayCancel;
      if (status === 'unfulfilled') {
        displayCancel = (
          <Link to={`/orders/sales-orders/${id}/cancel`}>
            Cancel
          </Link>
        );
      }

      return (
        <tr key={id}>
          <td>
            {id}
            <br />
            {orderDate}
          </td>
          <td>{formatOrderStatus(status)}</td>
          <td>{name || business}</td>
          <td>{lineItemsCount}</td>
          <td>{shipmentsCount}</td>
          <td>
            <Link to={`/orders/sales-orders/${id}`}>
              Show
            </Link>
            { displayCancel && <span className="mx-2">|</span> }
            { displayCancel }
          </td>
        </tr>
      );
    })
  );

  buildBadgeList = (filter, defaultFilter) => {
    const {
      contentId,
      search,
      shipmentsStatus,
    } = filter;
    const {
      contentId: defaultContentId,
      search: defaultSearch,
      shipmentsStatus: defaultShipmentsStatus,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('contentId'):
          if (contentId && contentId !== defaultContentId) {
            badgeList.push(
              {
                order: 3,
                title: 'Contents',
                descFilterable: 'shipmentProfiles',
                filterKey: key,
              },
            );
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('shipmentsStatus'):
          if (shipmentsStatus && shipmentsStatus !== defaultShipmentsStatus) {
            badgeList.push(
              {
                order: 2,
                title: 'Status',
                desc: filter[key].replace(/_/g, ' '),
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  }

  render() {
    return (
      <IndexTableContainer
        newButtonText="New Sales Order"
        newButton
        newFormUri="sales-orders/new"
        pageTitle="Sales Orders"
        searchPlaceholder="Search by ID or Address..."
        heads={
          [
            { key: 'id', name: 'ID' },
            { key: 'status', name: 'Status' },
            { key: 'recipient', name: 'Recipient' },
            { key: 'lineItems', name: 'Line Item #' },
            { key: 'shipments', name: 'Shipment #' },
            { key: 'actions', name: 'Actions' },
          ]
        }
        filterForm={
          [
            {
              type: 'Select',
              groupLabel: 'Contents',
              filterKey: 'contentId',
              placeholder: 'Filter by Order Contents...',
              optionsKey: 'shipmentProfiles',
            },
          ]
        }
        createRows={this.createRows}
        buildBadgeList={this.buildBadgeList}
      />
    );
  }
}

export default SalesOrdersIndexContainer;
