import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { sortNumericallyBy } from '../../../../helpers/resourceItem';

const ReceivingOrderReceivingShipments = ({ resourceitem: { vendorShipments: receivingShipments } }) => {
  const sorted = sortNumericallyBy(receivingShipments, 'id');
  const displayLineItems = sorted.map((receivingShipment) => {
    const {
      id,
      actualQuantity,
      itemName,
      processedDate,
    } = receivingShipment;
    return (
      <tr key={`lineitem${id}`}>
        <td>{id}</td>
        <td>{itemName}</td>
        <td>{actualQuantity}</td>
        <td>{processedDate}</td>
      </tr>
    );
  });

  const noneFound = <div className="not-found-text ml-4">No Receiving Shiments Exist for this Receiving Order</div>;
  const displayReceivingShipments = (
    <Card className="pb-0">
      <CardBody className="pt-3 pb-0">
        <div className="project-summary">
          <div className="table-responsive">
            <table className="table--bordered table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Item</th>
                  <th>Quantity</th>
                  <th>Processed Date</th>
                </tr>
              </thead>
              <tbody>
                {displayLineItems}
              </tbody>
            </table>
          </div>
        </div>
      </CardBody>
    </Card>
  );

  return (
    <Row>
      <Col xs={12}>
        <h3 className="page-title mb-0">
          Receiving Shipments
        </h3>
      </Col>
      <Col md={12}>
        {receivingShipments.length > 0 ? displayReceivingShipments : noneFound}
      </Col>
    </Row>
  );
};

ReceivingOrderReceivingShipments.propTypes = {
  resourceitem: PropTypes.shape({
    vendorShipments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        actualQuantity: PropTypes.number,
        itemName: PropTypes.string,
        processedDate: PropTypes.string,
      }),
    ),
  }),
};

ReceivingOrderReceivingShipments.defaultProps = {
  resourceitem: {},
};

export default ReceivingOrderReceivingShipments;
