import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import TopbarAccountToggle from './TopbarAccountToggle';
import TopbarLogOut from './TopbarLogOut';

import {
  getCurrentAccountId,
  getAccounts,
  getFirstName,
  getLastName,
} from '../../../redux/reducers/authReducer';

class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  };

  buildAccountsToggle = () => {
    const { accounts, currentAccountId } = this.props;
    const orderedAccounts = accounts.sort((a, b) => {
      if (a.teamName > b.teamName) {
        return 1;
      }
      return (b.teamName > a.teamName) ? -1 : 0;
    });

    return orderedAccounts.map((acc) => (
      <TopbarAccountToggle
        key={acc.accountId}
        title={acc.teamName}
        accountId={acc.accountId}
        currentAccountId={currentAccountId}
      />
    ));
  }

  render() {
    const { collapse } = this.state;
    const { firstName, lastName, accounts } = this.props;
    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <p className="topbar__avatar-name">{firstName} {lastName}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {
          collapse
          && (
            <button
              aria-label="collapse"
              type="button"
              className="topbar__back"
              onClick={this.toggle}
            />
          )
        }
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {
              accounts.length > 1
                ? (
                  <span>
                    <div className="topbar__link">
                      <p className="topbar__link-title">Accounts:</p>
                    </div>
                    {this.buildAccountsToggle()}
                    <div className="topbar__menu-divider" />
                  </span>
                )
                : null
            }
            <TopbarLogOut />
          </div>
        </Collapse>
      </div>
    );
  }
}

TopbarProfile.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  // eslint-disable-next-line
  accounts: PropTypes.array,
  currentAccountId: PropTypes.number,
};

TopbarProfile.defaultProps = {
  firstName: '',
  lastName: '',
  accounts: [],
  currentAccountId: 0,
};

const mapStateToProps = (state) => ({
  accounts: getAccounts(state),
  firstName: getFirstName(state),
  lastName: getLastName(state),
  currentAccountId: getCurrentAccountId(state),
});

export default withRouter(connect(mapStateToProps)(TopbarProfile));
