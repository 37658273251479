import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
  kdvId: Yup.number().required('Required'),
});

const Form = ({ match }) => (
  <Container>
    <ShowContainer>
      {
        ({ id, contentIdentifiableId, returnedData }) => {
          if (!id) return null;
          const { params: { kdvId } } = match;
          const kdv = returnedData.kdvs.find((k) => k.kdvId === Number(kdvId));
          const { serialNumber } = kdv;
          return (
            <FormWrapper
              initialValues={{ id, kdvId }}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/update_returned_kit_device_missing`}
              formTitle="Shipment Remove Device From Kit Form"
              formSubTitle={`Remove Device #${serialNumber} from Kit #${contentIdentifiableId}`}
              successModalTitle="Device Marked as Missing"
              successModalMessage="You'll be sent back to the Return Kit Update page"
              submitButtonText="Mark Device As Missing"
              successUrl={`/orders/assignments/${id}/update-kit/${contentIdentifiableId}`}
            />
          );
        }
      }
    </ShowContainer>
  </Container>
);

Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};

export default withRouter(connect()(Form));
