import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ExpensedVariantExpanded = ({ data, id, status }) => (
  <div className="ml-4">
    <span>Expected: {data.quantity}</span>
    <span className="mx-2">|</span>
    <span>Returned: {data.returnedQuantity}</span>
    <span className="mx-2">|</span>
    <span>Restockable: {data.restockableQuantity}</span>
    { status === 'unprocessed' && <span className="mx-2">|</span> }
    {
      status === 'unprocessed'
      && <Link to={`/orders/return-line-items/${id}/update`}>Update</Link>
    }
  </div>
);

ExpensedVariantExpanded.propTypes = {
  data: PropTypes.shape({
    quantity: PropTypes.number,
    returnedQuantity: PropTypes.number,
    restockableQuantity: PropTypes.number,
  }).isRequired,
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default ExpensedVariantExpanded;
