import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { sortAlphabeticallyBy } from '../../../helpers/resourceItem';

const ContentsInformation = ({ resourceitem: { contents } }) => {
  const sorted = sortAlphabeticallyBy(contents, 'name');

  const displayContents = (sorted).map((item, index) => {
    const { name, quantity } = item;
    return (
      <tr>
        <th>{index + 1})</th>
        <td>{`Qty ${quantity} -- ${name}`}</td>
      </tr>
    );
  });

  const halfzies = Math.ceil(displayContents.length / 2);
  const shouldSplit = displayContents.length > 10;
  const leftSide = shouldSplit ? displayContents.splice(0, halfzies) : displayContents;

  return (
    <Row>
      <Col md={12}>
        <h3 className="page-title mb-0">
          Contents
        </h3>
      </Col>
      <Col xl={shouldSplit ? 6 : 12}>
        <div className="project-summary">
          <table className="project-summary__info ml-2 mb-0">
            <tbody>
              {leftSide}
            </tbody>
          </table>
        </div>
      </Col>
      {
        shouldSplit
          ? (
            <Col xl={6}>
              <div className="project-summary">
                <table className="project-summary__info ml-2">
                  <tbody>
                    {displayContents}
                  </tbody>
                </table>
              </div>
            </Col>
          )
          : null
      }
    </Row>
  );
};

ContentsInformation.propTypes = {
  resourceitem: PropTypes.shape({
    contents: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        quantity: PropTypes.number,
      }),
    ),
  }),
};

ContentsInformation.defaultProps = {
  resourceitem: {
    contents: [],
  },
};

export default ContentsInformation;
