import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const TextField = ({
  fieldName, label, placeholder, disabled,
}) => (
  <div className="form__form-group">
    <span className="form__form-group-label">{label}</span>
    <div className="form__form-group-field">
      <Field
        validateonblur="true"
        type="text"
        name={fieldName}
        className="form__input"
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
    <ErrorMessage name={fieldName} component="div" className="login__error" />
  </div>
);

TextField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

TextField.defaultProps = {
  placeholder: '',
  disabled: false,
};

export default TextField;
