import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TopbarProfile from './TopbarProfile';

const TopbarProfileContainer = ({ name }) => (
  <TopbarProfile name={name} />
);

TopbarProfileContainer.propTypes = {
  name: PropTypes.string,
};

TopbarProfileContainer.defaultProps = {
  name: '',
};

const mapStateToProps = (state) => ({ name: state.auth.name });

export default connect(mapStateToProps)(TopbarProfileContainer);
