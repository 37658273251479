import axios from 'axios';
import { API, apiStart, apiEnd } from '../redux/actions/apiActions';
import { logOutAction } from '../redux/actions/authActions';

import convertKeys from '../helpers/convertKeys';

const apiMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  if (action.type !== API) return;

  const {
    url,
    method,
    params,
    onSuccess,
    onFailure,
    setLoading,
    headers,
    resource,
    history,
    successHistoryPushTo,
    successTimeout,
  } = action.payload;

  // axios default configs
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;

  if (setLoading) dispatch(apiStart());

  // in the case of a search request, set the current filter to the params.
  let requestParams;
  if (params.searchRequest && resource) {
    requestParams = getState().index[resource].filter;
  } else {
    requestParams = params;
  }

  axios
    .request({
      url,
      method,
      headers,
      data: convertKeys(requestParams, 'snakeCase'),
    })
    .then(({ data }) => {
      const camelData = convertKeys(data.data, 'camelCase');
      if (onSuccess) dispatch(onSuccess(camelData, resource, history));
      if (camelData) {
        const { token } = camelData;
        if (token) localStorage.setItem('token', token);
      }
      if (successHistoryPushTo && history) {
        if (history.location.pathname === successHistoryPushTo) {
          setTimeout(() => { history.go(0); }, successTimeout);
        } else {
          setTimeout(() => { history.push(successHistoryPushTo); }, successTimeout);
        }
      }
    })
    .catch((resp) => {
      const { response } = resp;
      const { status, data } = response;
      const { errors } = data;
      if (status === 401 && history) {
        dispatch(logOutAction(history));
      } else if (onFailure) {
        dispatch(onFailure(errors));
      }
    })
    .finally(() => {
      dispatch(apiEnd());
    });
};

export default apiMiddleware;
