import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, connect } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import SelectField from '../../../../shared/components/form/SelectFfield';
import TextField from '../../../../shared/components/form/TextField';
import randInt from '../../../../helpers/randInt';

const LineItemsArrayField = (props) => {
  const { options, formik: { isSubmitting, values } } = props;
  const { lineItems } = values;

  return (
    <FieldArray name="lineItems">
      {({ remove, push }) => (
        <div className="form__form-group">
          {
            lineItems.length > 0 && lineItems.map((sr, index) => (
              <Row key={sr.id} className="border_bottom mb-2">
                <Col className="my-2" md={2}>
                  <TextField
                    fieldName={`lineItems.${index}.quantity`}
                    placeholder="Quantity..."
                    label="Quantity"
                  />
                </Col>
                <Col className="my-2" md={7}>
                  <div className="form__form-group-field">
                    <SelectField
                      label="Stock Item"
                      placeholder="Select Incoming Item..."
                      fieldName={`lineItems.${index}.itemId`}
                      options={options}
                    />
                  </div>
                </Col>
                <Col className="my-2" md={3}>
                  <Button
                    style={{ marginTop: '23px', padding: '8px' }}
                    block
                    onClick={() => { if (values.lineItems.length > 1) remove(index); }}
                    disabled={isSubmitting}
                    outline
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            ))
          }
          <Button
            outline
            className="pull-right"
            color="primary"
            disabled={isSubmitting}
            onClick={() => push({
              id: randInt(),
              itemId: '',
              quantity: '',
            })}
          >
            Add Another Item
          </Button>
        </div>
      )}
    </FieldArray>
  );
};

LineItemsArrayField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
};

export default connect(LineItemsArrayField);
