import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import FormWrapper from '../../../../shared/components/form/FormWrapper';
import TextField from '../../../../shared/components/form/TextField';

class DevicesPartThree extends Component {
  handleValidate = (values) => {
    const errors = {};
    if (!values.container) errors.container = 'Required';
    return errors;
  }

  render() {
    const { devices, goBack, id } = this.props;
    const initialValues = {
      container: '',
      scannedSns: devices,
    };

    return (
      <span style={{ width: '100%', marginTop: '20px' }}>
        <FormWrapper
          initialValues={initialValues}
          onValidate={this.handleValidate}
          formUrlSuffix={`/${id}/add_devices`}
          formTitle="Add Devices Container"
          formSubTitle="Add a container name to be added to each device"
          successModalTitle="Devices Added!"
          successModalMessage="This page will be refreshed to ensure valid data!"
          submitButtonText="Add Devices"
          successUrl={`/orders/receiving-shipments/${id}`}
          goBack={goBack}
          goBackText="Back To Step 2"
        >
          <div
            className="customizer__form-group-wrap no-bottom-line col-md-12"
            style={{ width: '100%' }}
          >
            <div className="mx-3">
              <Row style={{ width: '100%' }}>
                <Col lg={6}>
                  <div className="form__form-group">
                    <TextField
                      fieldName="container"
                      label="Container"
                      placeholder="Container..."
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </FormWrapper>
      </span>
    );
  }
}

DevicesPartThree.propTypes = {
  goBack: PropTypes.func.isRequired,
  devices: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.number.isRequired,
};

export default withRouter(connect()(DevicesPartThree));
