/* eslint-disable react/prop-types */
import React from 'react';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import TextField from '../../shared/components/form/TextField';
import FormWrapper from '../../shared/components/form/FormWrapper';
import SelectField from '../../shared/components/form/SelectFfield';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  insuredPrice: Yup.number()
    .required('Required')
    .integer('Must be whole number')
    .moreThan(0, 'Must be at least 1')
    .typeError('Enter a valid number'),
  itemType: Yup.string().required('Required'),
  sku: Yup.string().required('Required'),
  fulfillmentSku: Yup.string().required('Required'),
  combinableType: Yup.string().required('Required'),
  weight: Yup.number()
    .required('Required')
    .integer('Must be whole number')
    .moreThan(-1, 'Must be at least 0')
    .typeError('Enter a valid number'),
  length: Yup.number()
    .required('Required')
    .integer('Must be whole number')
    .moreThan(-1, 'Must be at least 0')
    .typeError('Enter a valid number'),
  width: Yup.number()
    .required('Required')
    .integer('Must be whole number')
    .moreThan(-1, 'Must be at least 0')
    .typeError('Enter a valid number'),
  height: Yup.number()
    .required('Required')
    .integer('Must be whole number')
    .moreThan(-1, 'Must be at least 0')
    .typeError('Enter a valid number'),
});

const Form = ({ defaultValues, formSuffix }) => {
  const initialValues = defaultValues || {
    name: '',
    insuredPrice: '',
    itemType: '',
    sku: '',
    fulfillmentSku: '',
    combinableType: '',
    weight: '',
    length: '',
    width: '',
    height: '',
  };

  return (
    <Container>
      <FormWrapper
        initialValues={initialValues}
        validationSchema={validationSchema}
        formTitle={`${defaultValues ? 'Edit' : 'New'} Inventory Item Form`}
        successModalTitle={`Inventory Item successfully ${defaultValues ? 'updated' : 'created'}`}
        successModalMessage="You will be redirected back to the Inventory Items index"
        submitButtonText={defaultValues ? 'Update' : 'Create'}
        successUrl="/inventory/items"
        requestMethod={defaultValues && 'PUT'}
        formUrlSuffix={formSuffix || ''}
      >
        <div className="customizer__form-group-wrap no-bottom-line col-md-12" style={{ width: '100%' }}>
          <div className="mx-3">
            <Row style={{ width: '100%' }}>
              <Col lg={4}>
                <TextField
                  label="Name"
                  fieldName="name"
                  placeholder="Name..."
                />
              </Col>
              <Col lg={4}>
                <TextField
                  label="Insured Price"
                  fieldName="insuredPrice"
                  placeholder="Insured Price..."
                />
              </Col>
              <Col lg={4}>
                <SelectField
                  isDisabled={!!defaultValues}
                  label={defaultValues ? 'Item Type (cannot edit)' : 'Item Type'}
                  placeholder="Item Type..."
                  fieldName="itemType"
                  options={[
                    { label: 'Mailing Container', value: 'mailing_container' },
                    { label: 'Expensed', value: 'expensed' },
                    { label: 'Serial Number', value: 'serial_number' },
                  ]}
                />
              </Col>
            </Row>
          </div>
          <div className="mx-3">
            <Row style={{ width: '100%' }}>
              <Col lg={4}>
                <TextField
                  label="SKU"
                  fieldName="sku"
                  placeholder="Packaging SKU..."
                />
              </Col>
              <Col lg={4}>
                <TextField
                  disabled={!!defaultValues}
                  label={defaultValues ? 'Fulfillment SKU (cannot edit)' : 'Fulfillment SKU'}
                  fieldName="fulfillmentSku"
                  placeholder="Fulfillment SKU..."
                />
              </Col>
              <Col lg={4}>
                <SelectField
                  label="Combinable Type"
                  placeholder="Combinable Type..."
                  fieldName="combinableType"
                  options={[
                    { label: 'Combinable', value: 'combinable' },
                    { label: 'Separate Combinable', value: 'separate_combinable' },
                    { label: 'Separate Not Combinable', value: 'separate_not_combinable' },
                  ]}
                />
              </Col>
            </Row>
          </div>
          <div className="mx-3">
            <Row style={{ width: '100%' }}>
              <Col lg={3}>
                <TextField
                  label="Weight(oz)"
                  fieldName="weight"
                  placeholder="Weight..."
                />
              </Col>
              <Col lg={3}>
                <TextField
                  label="Length(in)"
                  fieldName="length"
                  placeholder="Length..."
                />
              </Col>
              <Col lg={3}>
                <TextField
                  label="Width(in)"
                  fieldName="width"
                  placeholder="Width..."
                />
              </Col>
              <Col lg={3}>
                <TextField
                  label="Height(in)"
                  fieldName="height"
                  placeholder="Height..."
                />
              </Col>
            </Row>
          </div>
        </div>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Form);
