import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container, Col } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import FormSectionWrapper from '../../shared/components/form/FormSectionWrapper';
import SelectField from '../../shared/components/form/SelectFfield';
import { getFilterables } from '../../redux/reducers/authReducer';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
  mailingContainerId: Yup.number().required('Required'),
});

const Form = ({ mailingContainers }) => {
  const options = mailingContainers.map((m) => ({ label: m.name, value: m.id }));

  return (
    <Container>
      <ShowContainer>
        {
          ({ id, mailingContainerId }) => {
            if (!id) return null;
            const initialValues = { id, mailingContainerId };
            return (
              <FormWrapper
                initialValues={initialValues}
                validationSchema={validationSchema}
                formUrlSuffix={`/${id}/set_mailing_container`}
                formTitle="Set Mailing Container Form"
                formSubTitle={`Fill in relevant Mailing Container info for Shipment #${id}`}
                successModalTitle="Shipment Mailing Container Set!"
                successModalMessage="You'll be sent back to the Shipments index page"
                submitButtonText="Set Mailing Container"
                successUrl="/orders/sales-shipments"
              >
                <FormSectionWrapper>
                  <Col md={6}>
                    <SelectField
                      label="Mailing Container"
                      placeholder="Mailing Container..."
                      fieldName="mailingContainerId"
                      options={options}
                    />
                  </Col>
                </FormSectionWrapper>
              </FormWrapper>
            );
          }
        }
      </ShowContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  mailingContainers: getFilterables(state).mailingContainers,
});

Form.propTypes = {
  mailingContainers: PropTypes.arrayOf(PropTypes.object),
};

Form.defaultProps = {
  mailingContainers: [],
};

export default withRouter(connect(mapStateToProps)(Form));
