import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container, Col } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import FormSectionWrapper from '../../shared/components/form/FormSectionWrapper';
import TextField from '../../shared/components/form/TextField';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
  kdvId: Yup.number().required('Required'),
});

const Form = ({ match }) => (
  <Container>
    <ShowContainer>
      {
        ({ id, contentIdentifiableId, returnedData }) => {
          if (!id) return null;
          const { params: { kdvId } } = match;
          const kdv = returnedData.kdvs.find((k) => k.kdvId === Number(kdvId));
          const { serialNumber } = kdv;
          return (
            <FormWrapper
              initialValues={{ id, kdvId, serialNumber: '' }}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/update_returned_kit_device`}
              formTitle="Shipment Exchange Returned Kit Device Form"
              formSubTitle={`Change Device ${serialNumber ? `#${serialNumber}` : ''} in Kit #${contentIdentifiableId}`}
              successModalTitle="Device Serial Number Updated"
              successModalMessage="You'll be sent back to the Return Kit Update page"
              submitButtonText="Update Serial Number"
              successUrl={`/orders/assignments/${id}/update-kit/${contentIdentifiableId}`}
            >
              <FormSectionWrapper>
                <Col md={6}>
                  <TextField
                    label="Serial Number"
                    placeholder="Serial Number..."
                    fieldName="serialNumber"
                  />
                </Col>
              </FormSectionWrapper>
            </FormWrapper>
          );
        }
      }
    </ShowContainer>
  </Container>
);

Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};

export default withRouter(connect()(Form));
