import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import TextField from '../../../shared/components/form/TextField';
import SelectField from '../../../shared/components/form/SelectFfield';

const DevicesBlock = ({ kdv }) => (
  <div
    key={`sunset${kdv.name}block`}
    className="customizer__form-group-wrap no-bottom-line col-md-12"
    style={{ width: '100%' }}
  >
    <div className="customizer__form-group-title-wrap mb-3">
      <h4>{kdv.name}:
        <span className="ml-3">
          {kdv.serialNumber}
        </span>
      </h4>
    </div>
    <div className="mx-3">
      <Row style={{ width: '100%' }}>
        <Col lg={6}>
          <div className="form__form-group">
            <TextField
              fieldName={`sunsetKdvs.${kdv.id}.returnToContainer`}
              label="Container"
              placeholder="Container"
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="form__form-group">
            <SelectField
              fieldName={`sunsetKdvs.${kdv.id}.returnToStock`}
              label="Stock Type"
              placeholder="Stock Type..."
              options={
                [
                  { label: 'A-Stock', value: 'a_stock' },
                  { label: 'B-Stock', value: 'b_stock' },
                  { label: 'Defective Stock', value: 'defective_stock' },
                ]
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

DevicesBlock.propTypes = {
  kdv: PropTypes.shape({
    name: PropTypes.string,
    serialNumber: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

export default DevicesBlock;
