import React, { PureComponent } from 'react';
import { Col, Card, Row } from 'reactstrap';
// import PropTypes from 'prop-types';
import DevicesFormOne from './components/forms/DevicesPartOne';
import DevicesFormTwo from './components/forms/DevicesPartTwo';
import DevicesFormThree from './components/forms/DevicesPartThree';
import ShowContainer from '../../shared/components/show/ShowContainer';

export default class WizardForm extends PureComponent {
  // static propTypes = {
  //   // onSubmit: PropTypes.func.isRequired,
  // };

  constructor() {
    super();
    this.state = {
      page: 1,
      intialDevices: [],
      finalDevices: [],
      devicesString: '',
    };
  }

  setInitialDevices = (intialDevices, original) => {
    const devs = intialDevices || [''];
    this.setState({ intialDevices: devs, devicesString: original });
    this.nextPage();
  }

  setFinalDevices = (finalDevices) => {
    this.setState({ finalDevices });
    this.nextPage();
  }

  nextPage = () => {
    this.setState((prevState) => ({ page: prevState.page + 1 }));
  }

  previousPage = () => {
    this.setState((prevState) => ({ page: prevState.page - 1 }));
  }

  render() {
    const {
      page, intialDevices, finalDevices, devicesString,
    } = this.state;

    return (
      <ShowContainer>
        {
          ({ id, devices }) => {
            if (!id || !devices) return null;
            const persistedDevices = devices.map((d) => d.serialNumber);

            return (
              <Row>
                <Col md={12} lg={12}>
                  <Card>
                    <div className="wizard">
                      <div className="wizard__steps">
                        <div
                          className={
                            `wizard__step${page === 1 ? ' wizard__step--active' : ''}`
                          }
                        >
                          <p>Step 1</p>
                        </div>
                        <div
                          className={
                            `wizard__step${page === 2 ? ' wizard__step--active' : ''}`
                          }
                        >
                          <p>Step 2</p>
                        </div>
                        <div
                          className={
                            `wizard__step${page === 3 ? ' wizard__step--active' : ''}`
                          }
                        >
                          <p>Step 3</p>
                        </div>
                      </div>
                      <div className="wizard__form-wrapper">
                        {
                          page === 1
                          && (
                            <DevicesFormOne
                              onSubmit={this.setInitialDevices}
                              devicesString={devicesString}
                            />
                          )
                        }
                        {
                          page === 2
                          && (
                            <DevicesFormTwo
                              onSubmit={this.setFinalDevices}
                              devices={intialDevices}
                              goBack={this.previousPage}
                              persistedDevices={persistedDevices}
                            />
                          )
                        }
                        {
                          page === 3
                          && (
                            <DevicesFormThree
                              devices={finalDevices}
                              goBack={this.previousPage}
                              id={id}
                            />
                          )
                        }
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            );
          }
        }
      </ShowContainer>
    );
  }
}
