import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Alert from '../general/Alert';
import SuccessModal from '../general/SuccessModal';
import { apiAction } from '../../../redux/actions/apiActions';
import setResourceData from '../../../helpers/setResourceData';
import searchApiRequest from '../../../redux/actions/indexActions';

const ModalFormWrapper = ({
  validationSchema,
  requestMethod,
  successModalTitle,
  formTitle,
  submitButtonText,
  children,
  formUrlSuffix,
  initialValues,
  onClose,
  goBackText,
  visible,
  history: domHistory,
  searchRequest,
  sendApiRequest,
}) => {
  const [formSuccess, setFormSuccess] = useState(false);

  const { resource, resourceExtension } = setResourceData(domHistory);

  if (formSuccess) {
    return (
      <SuccessModal
        colored
        isOpen
        title={successModalTitle}
        btn="Success"
        message="Reloading..."
      />
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        setTimeout(() => {
          sendApiRequest(
            {
              resource,
              method: requestMethod,
              params: values,
              url: `/${resourceExtension}${formUrlSuffix}`,
              successTimeout: 1000,
              history: domHistory,
              onSuccess: () => {
                setFormSuccess(true);
                setTimeout(() => {
                  setFormSuccess(false);
                  searchRequest(domHistory);
                  onClose();
                }, 1000);

                return { type: 'FORM_REQUEST' };
              },
              onFailure: (resErrors) => {
                setErrors(resErrors);
                return { type: 'FORM_REQUEST' };
              },
              domHistory,
            },
          );
          setSubmitting(false);
        }, 400);
      }}
    >
      {
        (formProps) => {
          const {
            handleSubmit,
            isSubmitting,
            errors,
            setErrors,
            handleReset,
          } = formProps;
          return (
            <Modal
              isOpen={visible}
              toggle={onClose}
              contentClassName="modal-wide"
            >
              <ModalHeader toggle={onClose}>
                <div className="page-title page-title--no-bottom-margin">
                  {formTitle}
                </div>
              </ModalHeader>
              {
                children && (
                  <ModalBody>
                    {children}
                    <Alert
                      color="danger"
                      className="alert--bordered"
                      icon
                      visible={!!Object.keys(errors).length}
                      onDismiss={() => setErrors({})}
                    >
                      <p>
                        <span className="bold-text mr-2 red-text">Warning!</span>
                        <span className="red-text">
                          One or more errors are preventing this record from being saved!
                        </span>
                      </p>
                      {
                        Object.keys(errors).map((key) => (
                          <p className="ml-2" key="error">
                            <b className="mr-1 red-text">{key}:</b>
                            <span className="red-text">
                              {errors[key]}
                            </span>
                          </p>
                        ))
                      }
                    </Alert>
                  </ModalBody>
                )
              }
              <ModalFooter>
                <Row style={{ width: '100%' }}>
                  <Col>
                    <Button
                      block
                      onClick={
                        () => {
                          onClose();
                          handleReset();
                        }
                      }
                      disabled={isSubmitting}
                    >
                      {goBackText || 'Cancel'}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      block
                      color="primary"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>
          );
        }
      }
    </Formik>
  );
};

ModalFormWrapper.propTypes = {
  visible: PropTypes.bool.isRequired,
  resourceitem: PropTypes.shape({
    id: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  requestMethod: PropTypes.string,
  successModalTitle: PropTypes.string,
  formTitle: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string,
  initialValues: PropTypes.shape(),
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object,
  formUrlSuffix: PropTypes.string,
  onClose: PropTypes.func,
  goBackText: PropTypes.string,
  children: PropTypes.oneOfType(
    [
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.object,
    ],
  ),
  searchRequest: PropTypes.func.isRequired,
  sendApiRequest: PropTypes.func.isRequired,
};

ModalFormWrapper.defaultProps = {
  resourceitem: {},
  requestMethod: 'POST',
  successModalTitle: '',
  submitButtonText: 'Submit',
  initialValues: {},
  formUrlSuffix: '',
  children: null,
  validationSchema: null,
  onClose: null,
  goBackText: '',
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  searchRequest: searchApiRequest,
  sendApiRequest: apiAction,
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(ModalFormWrapper));
