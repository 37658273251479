import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import apiMiddleware from '../../middleware/api';
import {
  apiReducer,
  authReducer,
  sidebarReducer,
  themeReducer,
  indexReducer,
} from '../../redux/reducers/index';

const reducers = combineReducers({
  api: apiReducer,
  auth: authReducer,
  sidebar: sidebarReducer,
  theme: themeReducer,
  index: indexReducer,
});

const store = createStore(reducers, composeWithDevTools(
  applyMiddleware(...[thunk, apiMiddleware]),
));

export default store;
