import React from 'react';
import {
  Button, ButtonToolbar, Col, Row,
} from 'reactstrap';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import FilterIcon from 'mdi-react/FilterIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';

import { Link } from 'react-router-dom';
import IndexTableContext from './IndexTableContext';
import ExportButton from './ExportButton';
// import ShipmentReportExportButton from './ShipmentReportExportButton';

const FilterBarSearchDisplay = () => (
  <IndexTableContext.Consumer>
    {
      ({
        exportButton,
        filter,
        history,
        newButton,
        newButtonText,
        newFormUri,
        onToggleDisplayFilter,
        searchPlaceholder,
        updateFilterAndSearch,
        validSearchMin,
        filterForm,
      }) => (
        <Row>
          <Col>
            <Formik
              enableReinitialize
              validateOnBlur
              initialValues={{ search: filter.search }}
              validate={(values) => {
                const errors = {};
                const { search } = values;
                const { length } = search;
                if (length !== 0 && length < validSearchMin) {
                  errors.search = 'Search term must be at least 4 characters long.';
                } else if (length === 0) {
                  errors.search = '';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  updateFilterAndSearch(values, history);
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Row>
                    <Col lg={8} xl={7}>
                      <ButtonToolbar>
                        <div className="form__form-group">
                          <div className="form__form-group-field" lg={6}>
                            <Field
                              type="text"
                              name="search"
                              placeholder={searchPlaceholder}
                              style={{ width: '100%' }}
                            />
                            {
                              newButton
                                ? (
                                  <button
                                    className="customizer__btn d-lg-none"
                                    type="button"
                                  >
                                    <Link to={newFormUri}>
                                      <PlusIcon className="customizer__btn-icon" />
                                    </Link>
                                  </button>
                                )
                                : null
                            }
                            <Button
                              type="submit"
                              color="primary"
                              className="icon d-none d-lg-block ml-2"
                              disabled={isSubmitting}
                              outline
                            >
                              <MagnifyIcon />
                            </Button>
                          </div>
                          <ErrorMessage name="search" component="div" className="login__error" />
                        </div>
                      </ButtonToolbar>
                    </Col>

                    <Col lg={4} xl={5}>
                      <div className="d-none d-lg-block">
                        <div className="d-flex flex-row-reverse">
                          {
                            newButton
                              ? (
                                <Button
                                  className="icon icon--right"
                                  color="primary"
                                  type="button"
                                >
                                  <Link to={newFormUri}>
                                    <span
                                      style={{ color: 'white' }}
                                      className="d-xl-none"
                                    >
                                      New
                                      <PlusIcon />
                                    </span>
                                    <span
                                      style={{ color: 'white' }}
                                      className="d-none d-xl-block"
                                    >
                                      {newButtonText}
                                      <PlusIcon />
                                    </span>
                                  </Link>
                                </Button>
                              )
                              : null
                          }
                          {
                            filterForm && (
                              <Button
                                className="icon icon--right mr-2"
                                color="primary"
                                outline
                                onClick={onToggleDisplayFilter}
                              >
                                Filters
                                <FilterIcon />
                              </Button>
                            )
                          }
                          { exportButton && <ExportButton className="mr-2" /> }
                        </div>
                      </div>

                      <Row className="d-lg-none">
                        {
                          exportButton
                          && (
                          <Col xs={4} className="pl-3">
                            <ExportButton block />
                          </Col>
                          )
                        }
                        {
                          filterForm && (
                            <Col xs={exportButton ? 4 : 6} className="pr-3">
                              <Button
                                block
                                className="icon icon--right"
                                color="primary"
                                outline
                                onClick={onToggleDisplayFilter}
                              >
                                Filters
                                <FilterIcon className="d-none d-sm-inline" />
                              </Button>
                            </Col>
                          )
                        }
                        <Col xs={exportButton ? 4 : 6} className="pl-3">
                          <Button
                            block
                            className="icon icon--right"
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Search <MagnifyIcon className="d-none d-sm-inline" />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      )
    }
  </IndexTableContext.Consumer>
);

export default FilterBarSearchDisplay;
