import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const MailingContainer = ({ mailingContainerId, mailingContainerName, id }) => (
  mailingContainerId
    ? (
      <div>
        <b className="mr-2">Mailing Container</b>
        {mailingContainerName}
        <Link className="ml-3" to={`/orders/sales-shipments/${id}/drop-mailing-container`}>
          Remove
        </Link>
      </div>
    )
    : (
      <div>
        <b className="mr-2">Mailing Container</b>
        {mailingContainerName}
        <Link className="ml-3" to={`/orders/sales-shipments/${id}/set-mailing-container`}>
          Set Container
        </Link>
      </div>
    )
);

MailingContainer.propTypes = {
  id: PropTypes.number,
  mailingContainerId: PropTypes.number,
  mailingContainerName: PropTypes.string,
};

MailingContainer.defaultProps = {
  id: null,
  mailingContainerId: null,
  mailingContainerName: null,
};

export default MailingContainer;
