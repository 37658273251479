import React from 'react';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';
import DatePickerField from '../../shared/components/form/DatePickerFfield';
import LineItemsArrayField from './components/form/LineItemsArrayField';
import { getFilterables } from '../../redux/reducers/authReducer';
import TextField from '../../shared/components/form/TextField';
import FormWrapper from '../../shared/components/form/FormWrapper';
import randInt from '../../helpers/randInt';
import { sortAlphabeticallyBy } from '../../helpers/resourceItem';

const validationSchema = Yup.object().shape({
  vendor: Yup.string().required('Required'),
  orderDate: Yup.string().required('Required'),
  lineItems: Yup.array()
    .of(
      Yup.object().shape({
        quantity: Yup.number()
          .required('Required')
          .integer('Must be whole number')
          .moreThan(0, 'Must be at least 1')
          .typeError('Enter a valid number'),
        itemId: Yup.string().required('Required'),
      }),
    )
    .min(1, 'Must have at least one line item'),
});

const Form = (props) => {
  const initialValues = {
    receiving: '',
    poNumber: '',
    invoiceNumber: '',
    orderDate: '',
    lineItems: [
      {
        id: randInt(),
        itemId: '',
        quantity: '',
      },
    ],
  };

  const { items, mailingContainers } = props;
  const sortedList = sortAlphabeticallyBy([...items, ...mailingContainers], 'name');
  const contentsList = sortedList.map((itm) => ({ label: itm.name, value: itm.id }));

  return (
    <Container>
      <FormWrapper
        initialValues={initialValues}
        validationSchema={validationSchema}
        formTitle="Receiving Order Form"
        successModalTitle="Receiving Order successfully created"
        successModalMessage="You will be redirected back to the Receiving Orders index"
        submitButtonText="Create"
        successUrl="/orders/receiving-orders"
      >
        <div className="customizer__form-group-wrap no-bottom-line col-md-12" style={{ width: '100%' }}>
          <div className="customizer__form-group-title-wrap mb-3">
            <h4>Receiving Information</h4>
          </div>
          <div className="mx-3">
            <Row style={{ width: '100%' }}>
              <Col lg={6}>
                <TextField
                  label="Vendor"
                  fieldName="vendor"
                  placeholder="Vendor..."
                />
              </Col>
              <Col lg={6}>
                <DatePickerField
                  label="Order Date"
                  fieldName="orderDate"
                  placeholder="Order Date..."
                />
              </Col>
            </Row>
            <Row style={{ width: '100%' }}>
              <Col lg={6}>
                <TextField
                  label="PO Number"
                  fieldName="poNumber"
                  placeholder="PO Number..."
                />
              </Col>
              <Col lg={6}>
                <TextField
                  label="Invoice Number"
                  fieldName="invoiceNumber"
                  placeholder="Invoice Number"
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="customizer__form-group-wrap no-bottom-lin col-md-12" style={{ width: '100%' }}>
          <div className="customizer__form-group-title-wrap">
            <h4>Line Items</h4>
          </div>
          <div className="mx-3">
            <Row style={{ width: '100%' }}>
              <Col>
                <LineItemsArrayField options={contentsList} />
              </Col>
            </Row>
          </div>
        </div>
      </FormWrapper>
    </Container>
  );
};


Form.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  mailingContainers: PropTypes.arrayOf(PropTypes.object),
};

Form.defaultProps = {
  items: [],
  mailingContainers: [],
};

const mapStateToProps = (state) => ({
  items: getFilterables(state).items,
  mailingContainers: getFilterables(state).mailingContainers,
});


export default withRouter(connect(mapStateToProps)(Form));
