import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import { formatReceivingStatus } from '../../../../helpers/resourceItem';

const ReceivingShipmentInformation = ({
  resourceitem: {
    id,
    receivingOrderId,
    itemName,
    expectedQuantity,
    actualQuantity,
    status,
    trackingNumber,
    carrier,
    processedDate,
    palletCount,
    devices,
  },
}) => {
  const csvData = devices.map((d) => d.serialNumber).join(',');
  const fileName = `VectorDevicesVS${id}`;
  return (
    <Row>
      <Col xs={12}>
        <h3 className="page-title mb-0">
          Receiving Shipment Detail Page
        </h3>
      </Col>
      <Col md={12}>
        <Card className="pb-0">
          <CardBody className="pt-3 pb-0">
            <div className="project-summary">
              <Row>
                <Col lg={6}>
                  <table className="project-summary__info ml-2 mb-0">
                    <tbody>
                      <tr>
                        <th>Database ID:</th>
                        <td>{ id }</td>
                      </tr>
                      <tr>
                        <th>Item:</th>
                        <td>{ itemName }</td>
                      </tr>
                      <tr>
                        <th>Expected Quantity:</th>
                        <td>{ expectedQuantity }</td>
                      </tr>
                      <tr>
                        <th>Actual Quantity:</th>
                        <td>{ actualQuantity }</td>
                      </tr>
                      <tr>
                        <th>Status:</th>
                        <td>{ formatReceivingStatus(status) }</td>
                      </tr>
                      {
                        devices && devices.length > 0
                          ? (
                            <tr>
                              <th>Download Devices:</th>
                              <td>
                                <CSVLink
                                  data={csvData}
                                  filename={fileName}
                                >
                                  {fileName}.csv
                                </CSVLink>
                              </td>
                            </tr>
                          )
                          : null
                      }
                    </tbody>
                  </table>
                </Col>
                <Col lg={6}>
                  <table className="project-summary__info ml-2">
                    <tbody>
                      <tr>
                        <th>Receiving Order ID:</th>
                        <td>{ receivingOrderId }</td>
                      </tr>
                      <tr>
                        <th>Carrier:</th>
                        <td>{ carrier }</td>
                      </tr>
                      <tr>
                        <th>Tracking Number:</th>
                        <td>{ trackingNumber }</td>
                      </tr>
                      <tr>
                        <th>Processed Date:</th>
                        <td>{ processedDate }</td>
                      </tr>
                      <tr>
                        <th>Pallet Count:</th>
                        <td>{ palletCount }</td>
                      </tr>
                      {
                        devices && devices.length > 0
                          ? (
                            <tr>
                              <th>Devices Count:</th>
                              <td>{devices.length}</td>
                            </tr>
                          )
                          : null
                      }
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

ReceivingShipmentInformation.propTypes = {
  resourceitem: PropTypes.shape({
    receiving: PropTypes.string,
    id: PropTypes.number,
    receivingOrderId: PropTypes.string,
    itemName: PropTypes.string,
    expectedQuantity: PropTypes.number,
    actualQuantity: PropTypes.number,
    status: PropTypes.string,
    trackingNumber: PropTypes.string,
    carrier: PropTypes.string,
    processedDate: PropTypes.string,
    palletCount: PropTypes.number,
    devices: PropTypes.arrayOf(PropTypes.object),
  }),
};

ReceivingShipmentInformation.defaultProps = {
  resourceitem: {},
};

export default ReceivingShipmentInformation;
