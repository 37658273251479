import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { splitList, sortNumericallyBy } from '../../../helpers/resourceItem';

const VariantsInformation = ({ resourceitem: { variants } }) => {
  const sortedVariants = sortNumericallyBy(variants, 'id');
  const list = sortedVariants.map((variant) => {
    const { id, name, shipmentProfile } = variant;
    const { contentId, weight, combinableType } = shipmentProfile;

    return (
      <Col xs={12} key={`variant${id}`}>
        <Card className="pb-0">
          <CardBody className="pt-3 pb-0 pl-3">
            <div className="project-summary">
              <div className="card__title mb-3">
                <h5 className="bold-text">{name}</h5>
              </div>
              <table className="project-summary__info ml-2">
                <tbody>
                  <tr>
                    <th>Fulfillment SKU:</th>
                    <td>{ contentId }</td>
                  </tr>
                  <tr>
                    <th>Database ID:</th>
                    <td>{ id }</td>
                  </tr>
                  <tr>
                    <th>Shipment Weight:</th>
                    <td>{ weight } oz</td>
                  </tr>
                  <tr>
                    <th>Combinable Type:</th>
                    <td>{ combinableType }</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  });
  const split = splitList(list);

  return (
    <Row>
      <Col xs={12}>
        <h3 className="page-title mb-0">
          Variants
        </h3>
      </Col>
      <Col lg={split.right ? 6 : 12}>{split.left}</Col>
      { split.right ? <Col lg={6}>{split.right}</Col> : null }
    </Row>
  );
};

VariantsInformation.propTypes = {
  resourceitem: PropTypes.shape({
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      }),
    ),
  }),
};

VariantsInformation.defaultProps = {
  resourceitem: {
    variants: [],
  },
};

export default VariantsInformation;
