import React from 'react';
import { Container } from 'reactstrap';

const NotFound = () => (
  <Container>
    <div>
      404 Page Not Found
    </div>
  </Container>
);

export default NotFound;
