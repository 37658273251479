import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container, Col } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import FormSectionWrapper from '../../shared/components/form/FormSectionWrapper';
import TextField from '../../shared/components/form/TextField';
import SelectField from '../../shared/components/form/SelectFfield';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
  contentIdentifiableId: Yup.string().required('Required'),
  returnToContainer: Yup.string().required('Required')
    .min(6, 'Must be 6 characters'),
  returnToStock: Yup.string().required('Required'),
});

const Form = () => (
  <Container>
    <ShowContainer>
      {
        ({
          id,
          contentableType,
          contentableName,
          shipmentId,
        }) => {
          if (!id || !contentableType || !shipmentId) return null;
          const initialValues = {
            id,
            contentIdentifiableType: 'Device',
            contentIdentifiableId: '',
            returnToContainer: '',
            returnToStock: 'b_stock',
          };
          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/assign`}
              formTitle="Shipment Assignment Form"
              formSubTitle={`Add ${contentableName} Device to Shipment #${shipmentId}`}
              successModalTitle="Device Added!"
              successModalMessage="You'll be sent back to the Shipments index page"
              submitButtonText="Assign"
              successUrl="/orders/return-shipments"
            >
              <FormSectionWrapper>
                <Col md={4}>
                  <TextField
                    label="Serial Number"
                    placeholder="Serial Number..."
                    fieldName="contentIdentifiableId"
                  />
                </Col>
                <Col md={4}>
                  <TextField
                    label="Container"
                    placeholder="Container..."
                    fieldName="returnToContainer"
                  />
                </Col>
                <Col md={4}>
                  <SelectField
                    label="To Stock Type"
                    placeholder="To Stock Type..."
                    fieldName="returnToStock"
                    options={[
                      { label: 'A-Stock', value: 'a_stock' },
                      { label: 'B-Stock', value: 'b_stock' },
                      { label: 'Defective Stock', value: 'defective_stock' },
                    ]}
                  />
                </Col>
              </FormSectionWrapper>
            </FormWrapper>
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Form));
