import React, { PureComponent } from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import ThumbUpOutlineIcon from 'mdi-react/ThumbUpOutlineIcon';
import CommentAlertOutlineIcon from 'mdi-react/CommentAlertOutlineIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';

export default class AlertComponent extends PureComponent {
  render() {
    const {
      color,
      className,
      icon,
      children,
      visible,
      onDismiss,
    } = this.props;
    let Icon;

    switch (color) {
      case 'info':
        Icon = <InformationOutlineIcon />;
        break;
      case 'success':
        Icon = <ThumbUpOutlineIcon />;
        break;
      case 'warning':
        Icon = <CommentAlertOutlineIcon />;
        break;
      case 'danger':
        Icon = <CloseCircleOutlineIcon />;
        break;
      default:
        break;
    }

    return (
      <Alert color={color} className={className} isOpen={visible}>
        {icon && <div className="alert__icon">{Icon}</div>}
        <button className="close" type="button" onClick={onDismiss}>
          <span className="lnr lnr-cross" />
        </button>
        <div className="alert__content">
          {children}
        </div>
      </Alert>
    );
  }
}

AlertComponent.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  visible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

AlertComponent.defaultProps = {
  color: '',
  icon: false,
  className: '',
};
