import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import TextField from '../../shared/components/form/TextField';

class Form extends Component {
  handleValidate = (values) => {
    const errors = {};
    if (!values.id) errors.id = 'Required';
    if (!values.container) errors.container = 'Required';
    return errors;
  }

  render() {
    return (
      <Container>
        <ShowContainer>
          {
            ({ id }) => {
              if (!id) return null;
              const { match: { params: { serialNumber } } } = this.props;
              const initialValues = { id, serialNumber, container: '' };

              return (
                <FormWrapper
                  initialValues={initialValues}
                  onValidate={this.handleValidate}
                  formUrlSuffix={`/${id}/remove_device/${serialNumber}`}
                  formTitle="Confirm Remove Device"
                  formSubTitle={`Please confirm that you would like to remove Device #${serialNumber}`}
                  successModalTitle="Device Sucessfully Removed!"
                  successModalMessage="You will be redirected back to the scan form"
                  submitButtonText="Confirm Remove Device"
                  successUrl={`/inventory/batches/${id}/devices`}
                >
                  <div className="customizer__form-group-wrap no-bottom-line" style={{ width: '100%' }}>
                    <div className="mx-3">
                      <Row style={{ width: '100%' }}>
                        <Col lg={6}>
                          <TextField
                            label="Container"
                            fieldName="container"
                            placeholder="Container..."
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </FormWrapper>
              );
            }
          }
        </ShowContainer>
      </Container>
    );
  }
}

Form.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
};

export default withRouter(connect()(Form));
