/* eslint-disable */
import React from 'react';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFilterables } from '../../redux/reducers/authReducer';

import { Container, Col, Row } from 'reactstrap';
import TextField from '../../shared/components/form/TextField';
import FormWrapper from '../../shared/components/form/FormWrapper';
import SelectField from '../../shared/components/form/SelectFfield';
import VariantArrayField from './components/form/VariantQuantitiesInput';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  fulfillmentSku: Yup.string().required('Required'),
  buildType: Yup.string().required('Required'),
  combinableType: Yup.string().when('buildType', {
    is: (t) => t !== 'shipment_grouping',//just an e.g. you can return a function
    then: Yup.string().required('Required'),
    otherwise: Yup.string()
  }),
  weight: Yup.number().when('buildType', {
    is: (t) => t !== 'shipment_grouping',//just an e.g. you can return a function
    then: Yup.number().required('Required')
      .integer('Must be whole number')
      .moreThan(-1, 'Must be at least 0')
      .typeError('Enter a valid number'),
    otherwise: Yup.number()
  })
});

const Form = ({ defaultValues, formSuffix, itemsList, mailingContainersList }) => {
  const initialValues = defaultValues || {
      name: '',
      description: '',
      fulfillmentSku: '',
      buildType: '',
      combinableType: '',
      weight: '',
      mailing_container_id: '',
      variants: [
        {
          variantId: '',
          quantity: 1,
          defaultRestock: true
        },
        {
          variantId: '',
          quantity: 1,
          defaultRestock: false
        },
      ]
  };

  return (
    <Container>
      <FormWrapper
        initialValues={initialValues}
        validationSchema={validationSchema}
        formTitle="New Bundle Form"
        successModalTitle="Bundle successfully created"
        successModalMessage="You will be redirected back to the Bundles index"
        submitButtonText="Create"
        successUrl="/inventory/bundles"
        requestMethod={defaultValues && 'PUT'}
        formUrlSuffix={formSuffix || ''}
      >
        <FormBody itemsList={itemsList} mailingContainersList={mailingContainersList} />
      </FormWrapper>
    </Container>
  );
};

const FormBody = ({itemsList, mailingContainersList}) => (
  <div className="customizer__form-group-wrap no-bottom-line col-md-12" style={{ width: '100%' }}>
    <div className="mx-3">
      <Row style={{ width: '100%' }}>
        <Col lg={3}>
          <TextField
            label="Name"
            fieldName="name"
            placeholder="Name..."
          />
        </Col>
        <Col lg={6}>
          <TextField
            label="Description"
            fieldName="description"
            placeholder="Description..."
            />
        </Col>
        <Col lg={3}>
          <SelectField
            label="Mailing Container"
            placeholder="Default Mailing Container..."
            fieldName="mailingContainerId"
            options={mailingContainersList}
          />
        </Col>
      </Row>
    </div>
    <div className="mx-3">
      <Row style={{ width: '100%' }}>
        <Col lg={3}>
          <TextField
            label="Fulfillment SKU"
            fieldName="fulfillmentSku"
            placeholder="Fulfillment SKU..."
          />
        </Col>
        <Col lg={3}>
          <SelectField
            label="Build Type"
            placeholder="Build Type..."
            fieldName="buildType"
            options={[
              { label: 'Built On Order', value: 'build_on_order' },
              { label: 'Shipment Grouping', value: 'shipment_grouping' },
              { label: 'Prebuilt', value: 'prebuilt' },
              { label: 'Network', value: 'network' },
            ]}
          />
        </Col>
        <Col lg={3}>
          <SelectField
            label="Combinable Type"
            placeholder="Combinable Type..."
            fieldName="combinableType"
            options={[
              { label: 'Combinable', value: 'combinable' },
              { label: 'Separate Combinable', value: 'separate_combinable' },
              { label: 'Separate Not Combinable', value: 'separate_not_combinable' },
            ]}
          />
        </Col>
        <Col lg={3}>
          <TextField
            label="Weight (oz)"
            fieldName="weight"
            placeholder="Weight..."
          />
        </Col>
      </Row>
    </div>
    <div className="customizer__form-group-wrap no-bottom-line" style={{ width: '100%' }}>
      <div className="customizer__form-group-title-wrap">
        <h4>Line Items</h4>
      </div>
      <div className="mx-3">
        <Row style={{ width: '100%' }}>
          <Col>
            <VariantArrayField variantsList={itemsList.map((i) => i.variants).flat()} />
          </Col>
        </Row>
      </div>
    </div>
  </div>
)

Form.propTypes = {
  itemsList: PropTypes.arrayOf(PropTypes.object),
  mailingContainersList: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

Form.defaultProps = {
  mailingContainersList: [],
  itemsList: [],
};

const mapStateToProps = (state) => ({
  itemsList: getFilterables(state).items,
  mailingContainersList: getFilterables(state).mailingContainers,
});

export default withRouter(connect(mapStateToProps)(Form));
