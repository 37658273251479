/* eslint-disable */
import React from 'react';
import { Col } from 'reactstrap';
import { Field } from 'formik';
import ReactTooltip from 'react-tooltip';
import RadioButton from '../../../../../../shared/components/form/RadioButton';
import {sumAmounts, carrierToTitleCase, sortedPackages, packagesPricingToolTip} from './utils';
import formatter from '../../../../../../helpers/currencyHelper';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';

const ServiceOption = ({serviceCode, packages}) => {
  const sortedByPrice = sortedPackages(packages);
  return (
    <Field
      id={serviceCode}
      name="carrierServiceCode"
      value={serviceCode}
      component={RadioButton}
      label={
        <div>
          <div className="text-left">
            <b>
              {carrierToTitleCase(serviceCode)}
            </b>
          </div>
          <div className="text-left ml-2">
            Delivery Date: {new Date(packages[0].estimatedDeliveryDate).toDateString()}
          </div>
          <div className="text-left ml-2">
            Delivery Days: {packages[0].deliveryDays}
          </div>
            {
              packages.length === 1
              ? (
                <div className="text-left ml-2">
                  Price: {formatter.format(sumAmounts(packages[0]))}
                </div>
              )
              : (
                <div className="text-left ml-2">
                  Price:
                  {" "}
                  {formatter.format(sumAmounts(sortedByPrice[0]))}
                  {" "}
                  -
                  {" "}
                  {formatter.format(sumAmounts(sortedByPrice[sortedByPrice.length - 1]))}
                  <span className="ml-2" data-tip={packagesPricingToolTip(sortedByPrice)}>
                    <InformationOutlineIcon size={16} />
                  </span>
                  <ReactTooltip multiline />
                </div>
              )
            }
        </div>
      }
    />
  );
};

export default ServiceOption;