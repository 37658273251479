import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container, Col } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import FormSectionWrapper from '../../shared/components/form/FormSectionWrapper';
import TextField from '../../shared/components/form/TextField';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
  weight: Yup.number().required('Required'),
});

const Form = () => (
  <Container>
    <ShowContainer>
      {
        ({ id, fulfillmentType }) => {
          if (!id || !fulfillmentType) return null;
          const initialValues = { id, weight: '' };
          const successResource = fulfillmentType === 'new_order' ? 'sales' : 'return';
          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/set_weight`}
              formTitle="Set Weight Form"
              formSubTitle={`Fill in relevant weight info for Shipment #${id}`}
              successModalTitle="Shipment Weight Set!"
              successModalMessage="You'll be sent back to the Shipments index page"
              submitButtonText="Set Weight"
              successUrl={`/orders/${successResource}-shipments`}
            >
              <FormSectionWrapper>
                <Col md={6}>
                  <TextField
                    placeholder="Weight (oz)..."
                    label="Weight (oz)"
                    fieldName="weight"
                  />
                </Col>
              </FormSectionWrapper>
            </FormWrapper>
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Form));
