import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Col, Row, Button } from 'reactstrap';
import TextArea from '../../../../shared/components/form/TextArea';

class DevicesPartOne extends Component {
  handleParseDevices = (str) => (
    str.match(/\b\w+(?:-\w+)|\w+\b/g)
  )

  render() {
    const { history: domHistory, devicesString } = this.props;
    return (
      <Formik
        initialValues={{ devicesString }}
        onSubmit={(values) => {
          const { onSubmit } = this.props;
          const parsed = this.handleParseDevices(values.devicesString);
          onSubmit(parsed, values.devicesString);
        }}
      >
        {
          (props) => (
            <Form className="form" style={{ width: '100%', marginTop: '20px' }}>
              <div className="col-md-12">
                <h3 className="page-title">
                  Convert Devices (optional)
                </h3>
                <h3 className="page-subhead subhead">
                  This form is optional to create Devices from a Spreadsheet
                </h3>
              </div>
              <div
                className="customizer__form-group-wrap no-bottom-line col-md-12"
                style={{ width: '100%' }}
              >
                <div className="mx-3">
                  <Row style={{ width: '100%' }}>
                    <Col lg={12}>
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <TextArea
                            fieldName="devicesString"
                            label="Devices From Spreadsheet"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="customizer__form-group-wrap no-bottom-line col-md-12" style={{ width: '100%' }}>
                <Row>
                  <Col xs={6}>
                    <Button block color="secondary" disabled={props.isSubmitting} onClick={domHistory.goBack}>
                      Take Me Back
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button
                      block
                      type="submit"
                      color="primary"
                      disabled={props.isSubmitting}
                      onClick={props.handleSubmit}
                    >
                      { props.values.devicesString.length > 0 ? 'Step 2: Convert To Devices' : 'Skip To Step 2' }
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          )
        }
      </Formik>
    );
  }
}

DevicesPartOne.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  devicesString: PropTypes.string.isRequired,
};

export default withRouter(connect()(DevicesPartOne));
