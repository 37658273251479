import React from 'react';
import AcceptInviteForm from './components/AcceptInviteForm';

const AcceptInvite = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <span className="login__logo" />
          <h3 className="account__title">Welcome aboard!</h3>
        </div>
        <AcceptInviteForm />
      </div>
    </div>
  </div>
);

export default AcceptInvite;
