import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FieldArray, Field } from 'formik';
import { Container, Col, Row } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import FormSectionWrapper from '../../shared/components/form/FormSectionWrapper';
import TextField from '../../shared/components/form/TextField';
import SelectField from '../../shared/components/form/SelectFfield';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
});

const Form = ({ history: domHistory }) => (
  <Container>
    <ShowContainer>
      {
        ({
          id,
          shipmentId,
          contentIdentifiableId,
          returnedData,
          returnedKitAction,
        }) => {
          if (!id) return null;
          const { expensedVqs } = returnedData;
          const initialValues = {
            id,
            kdvs: returnedData.kdvs,
            expensedVqs: expensedVqs.map((vq, index) => ({ ...vq, formId: index })),
          };

          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/update_returned_data`}
              formTitle="Update Kit Data For Return"
              formSubTitle={`Update Kit #${contentIdentifiableId} from Shipment #${shipmentId}`}
              successModalTitle="Kit Updated"
              successModalMessage="You'll be sent back to the Shipments index page"
              submitButtonText="Update Kit"
              successUrl="/orders/return-shipments"
              goBack={() => { domHistory.push('/orders/return-shipments'); }}
            >
              <FieldArray name="kdvs">
                <FormSectionWrapper>
                  {
                    returnedData.kdvs.map((kdv, i) => {
                      const { kdvId, variantName } = kdv;
                      const placeholder = initialValues.kdvs[i].serialNumber ? 'Serial Number' : 'Device Missing';
                      return (
                        <div key={`kdv${kdvId}`} className="form__form-group">
                          <h4 className="form__form-group-label">{variantName}</h4>
                          <Row style={{ width: '100%' }}>
                            <Col md={returnedKitAction === 'sunset' ? 4 : 6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">Serial Number</span>
                                <span className="float-right">
                                  {
                                    initialValues.kdvs[i].serialNumber
                                    && (
                                      <Link to={`/orders/assignments/${id}/kit-device-missing/${kdvId}`}>
                                        Device Missing
                                      </Link>
                                    )
                                  }
                                  {
                                    initialValues.kdvs[i].serialNumber
                                    && (
                                      <span className="form__form-group-label mx-2">|</span>
                                    )
                                  }
                                  <Link to={`/orders/assignments/${id}/kit-device-change/${kdvId}`}>
                                    Change Serial Number
                                  </Link>
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    validateonblur="true"
                                    name={`kdvs.${i}.serialNumber`}
                                    className="form__input"
                                    placeholder={placeholder}
                                    disabled
                                  />
                                </div>
                              </div>
                            </Col>
                            {
                              returnedKitAction === 'sunset'
                              && (
                                <Col md={4}>
                                  <SelectField
                                    label="Return To Stock"
                                    placeholder="Return To Stock..."
                                    fieldName={`kdvs.${i}.returnToStock`}
                                    options={[
                                      { label: 'A-Stock', value: 'a_stock' },
                                      { label: 'B-Stock', value: 'b_stock' },
                                      { label: 'A-Stock', value: 'defective_stock' },
                                    ]}
                                  />
                                </Col>
                              )
                            }
                            {
                              returnedKitAction === 'sunset'
                              && (
                                <Col md={4}>
                                  <TextField
                                    label="Return To Container"
                                    placeholder="Return To Container..."
                                    fieldName={`kdvs.${i}.returnToContainer`}
                                  />
                                </Col>
                              )
                            }
                          </Row>
                        </div>
                      );
                    })
                  }
                </FormSectionWrapper>
              </FieldArray>
              <FieldArray name="expensedVqs">
                <FormSectionWrapper>
                  {
                    returnedData.expensedVqs.map((vq, i) => (
                      <div key={vq.variantId} className="form__form-group">
                        <h4 className="form__form-group-label">{vq.itemName}</h4>
                        <Row key={vq.variantId} style={{ width: '100%' }}>
                          <Col md={4}>
                            <TextField
                              label="Expected Quantity"
                              fieldName={`expensedVqs.${i}.quantity`}
                              disabled
                            />
                          </Col>
                          <Col md={4}>
                            <TextField
                              label="Returned Quantity"
                              fieldName={`expensedVqs.${i}.returnedQuantity`}
                              placeholder="Returned Quantity..."
                            />
                          </Col>
                          <Col md={4}>
                            <TextField
                              label="Restockable Quantity"
                              fieldName={`expensedVqs.${i}.restockableQuantity`}
                              placeholder="Restockable Quantity..."
                            />
                          </Col>
                        </Row>
                      </div>
                    ))
                  }
                </FormSectionWrapper>
              </FieldArray>
            </FormWrapper>
          );
        }
      }
    </ShowContainer>
  </Container>
);

Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default withRouter(connect()(Form));
