import React from 'react';
import ForgotPasswordForm from './components/ForgotPasswordForm';

const ForgotPassword = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <span className="login__logo" />
          <h3 className="account__title">Let&apos;s reset your password!</h3>
        </div>
        <ForgotPasswordForm />
      </div>
    </div>
  </div>
);

export default ForgotPassword;
