import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
});

const Form = () => (
  <Container>
    <ShowContainer>
      {
        ({ id }) => {
          if (!id) return null;
          const initialValues = { id };

          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/create_label`}
              formTitle="Create Label Confirmation"
              formSubTitle={`Please confirm that you would like to create a label for Shipment #${id}`}
              successModalTitle="Label Created!"
              successModalMessage="You'll be sent back to the Shipment index page"
              submitButtonText="Confirm Create"
              successUrl="/orders/sales-shipments"
            />
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Form));
