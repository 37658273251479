import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, connect } from 'formik';

class TextArea extends React.Component {
  handleChange = (e) => {
    const { formik: { setFieldValue }, fieldName } = this.props;
    setFieldValue(fieldName, e.target.value);
  }

  handleBlur = () => {
    const { formik: { setFieldTouched }, fieldName } = this.props;
    setFieldTouched(fieldName, true);
  }

  render() {
    const {
      fieldName,
      placeholder,
      label,
      formik: { values },
    } = this.props;

    const hasLabel = !!label;
    return (
      <div className="form__form-group" style={{ width: '100%' }}>
        { hasLabel ? <span className="form__form-group-label">{label}</span> : null }
        <div className="form__form-group-field">
          <textarea
            id={fieldName}
            placeholder={placeholder}
            onChange={(e) => this.handleChange(e)}
            onBlur={() => this.handleBlur()}
            value={values[fieldName]}
          />
        </div>
        <ErrorMessage
          name={fieldName}
          component="div"
          className="login__error"
          style={{ marginTop: '6px' }}
        />
      </div>
    );
  }
}

TextArea.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  /* eslint-disable-next-line */
  formik: PropTypes.object.isRequired,
};

TextArea.defaultProps = {
  placeholder: '',
  label: '',
};

export default connect(TextArea);
