import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import DownloadOutlineIcon from 'mdi-react/DownloadOutlineIcon';
import { getList } from '../../../redux/reducers/indexReducer';

const ExportCSV = ({ list, block, className }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const exportToCSV = () => {
    const header = [
      [
        'A: Name', 'B: School Name', 'C: Country', 'D: Address 1', 'E: Address 2',
        'F:', 'G: City', 'H: State', 'I: Zip Code', 'J:', 'K:', 'L:', 'M:', 'N:',
        'O:', 'P:', 'Q:', 'R:', 'S:', 'T:', 'U:', 'V:', 'W:', 'X:', 'Y: Shipping Speed Code',
        'Z:', 'AA:', 'AB', 'AC:', 'AD:', 'AE:', 'Order Date(Delete)', 'AF:',
      ],
    ];

    const wsData = list.map((i) => (
      [
        /* A */ (i.name || '').trim(),
        /* B */ (i.business || '').trim(),
        /* C */ (i.country || '').trim(),
        /* D */ (i.address1 || '').trim(),
        /* E */ (i.address2 || '').trim(),
        /* F */ '',
        /* G */ (i.city || '').trim(),
        /* H */ (i.state || '').trim(),
        /* I */ (i.zip || '').trim(),
        /* J */ '',
        /* K */ '',
        /* L */ '',
        /* M */ '',
        /* N */ '2',
        /* O */ '',
        /* P */ '',
        /* Q */ '',
        /* R */ '',
        /* S */ '',
        /* T */ '',
        /* U */ '',
        /* V */ '',
        /* W */ '',
        /* X */ '',
        /* Y */ i.carrierServiceCodeNumber.replace(/^0+/, '').trim(),
        /* Z */ '',
        /* AA */ '',
        /* AB */ '',
        /* AC */ '',
        /* AD */ '',
        /* AE */ '',
        /* Date(Delete) */ i.createdAt, // .strftime('%b %e, %l:%M %p %Z'),
        /* AF */ i.shipmentRequests.map((sr) => sr.description).join('; ').trim(),
      ]
    ));
    const ws = XLSX.utils.aoa_to_sheet(header.concat(wsData));
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    const date = new Date().toDateString();
    FileSaver.saveAs(data, `Shipments ${date}.xlsx`);
  };

  return (
    <Button
      block={block}
      className={`icon icon--right ${className}`}
      color="primary"
      onClick={() => exportToCSV()}
      outline
    >
      Export
      <DownloadOutlineIcon />
    </Button>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { history } = ownProps;
  return {
    list: getList(history, state),
  };
};

ExportCSV.propTypes = {
  block: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};

ExportCSV.defaultProps = {
  block: false,
  className: '',
};

export default withRouter(connect(mapStateToProps)(ExportCSV));
