import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, Field, ErrorMessage } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import SelectField from '../../../../shared/components/form/SelectField';
import randInt from '../../../../helpers/randInt';

const ContentsArrayField = ({
  shipmentProfiles,
  values,
  touched,
  errors,
  setFieldValue,
  setFieldTouched,
  isSubmitting,
}) => (
  <FieldArray name="lineItems">
    {({ remove, push }) => (
      <div className="form__form-group">
        {
          values.lineItems.length > 0 && values.lineItems.map((sr, index) => (
            <span key={sr.id}>
              <Row className="my-3">
                <Col md={4} lg={12}>
                  <span style={{ color: 'white' }}><b>Name:</b> {sr.name}</span>
                  <br />
                  <span style={{ color: 'white' }}><b>Fulfillment SKU:</b> {sr.contentId}</span>
                </Col>
              </Row>
              <Row className="my-3">
                <Col md={2} lg={2}>
                  <span className="form__form-group-label">Quantity</span>
                  <div className="form__form-group-field">
                    <Field
                      validateonblur="true"
                      type="text"
                      name={`lineItems.${index}.quantity`}
                      className="form__input"
                      placeholder="Quantity..."
                    />
                  </div>
                  <ErrorMessage
                    name={`lineItems.${index}.quantity`}
                    component="div"
                    className="login__error"
                  />
                </Col>
                <Col md={7} lg={8}>
                  <div className="form__form-group-field">
                    <SelectField
                      label="Inventory Item or Kit"
                      value={values.lineItems[index].contentId}
                      filterKey={`lineItems.${index}.contentId`}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors[`lineItems.${index}.contentId`]}
                      touched={touched[`lineItems.${index}.contentId`]}
                      placeholder="Select Contents..."
                      options={shipmentProfiles}
                    />
                  </div>
                </Col>
                <Col md={3} lg={2}>
                  <Button
                    style={{ marginTop: '23px', padding: '8px' }}
                    block
                    onClick={() => { if (values.lineItems.length > 1) remove(index); }}
                    disabled={isSubmitting}
                    outline
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            </span>
          ))
        }
        <Button
          outline
          color="primary"
          disabled={isSubmitting}
          onClick={() => push({ contentId: '', quantity: '', id: randInt() })}
        >
          Add Line Item
        </Button>
      </div>
    )}
  </FieldArray>
);

ContentsArrayField.propTypes = {
  shipmentProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.value,
    }),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  touched: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default ContentsArrayField;
