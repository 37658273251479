import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';

class Index extends Component {
  createRows = (list) => (
    list.map((og) => {
      const {
        id, ordersCount, orderTime, status,
      } = og;

      return (
        <tr key={id}>
          <td>{id}</td>
          <td>{orderTime}</td>
          <td>{ordersCount}</td>
          <td>{status}</td>
        </tr>
      );
    })
  );

  buildBadgeList = (filter, defaultFilter) => {
    const { search } = filter;
    const { search: defaultSearch } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  }

  render() {
    return (
      <IndexTableContainer
        newButtonText="New Sales Batch"
        newButton
        newFormUri="batch-sales-orders/new"
        pageTitle="Sales Orders Batch Files"
        searchPlaceholder="Search by ID or Contact Name..."
        heads={
          [
            { key: 'id', name: 'ID' },
            { key: 'timeStamp', name: 'Time Stamp' },
            { key: 'orders', name: 'Order #' },
            { key: 'status', name: 'Status' },
          ]
        }
        filterForm={[]}
        createRows={this.createRows}
        buildBadgeList={this.buildBadgeList}
      />
    );
  }
}

export default Index;
