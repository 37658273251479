import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { sortNumericallyBy } from '../../../../helpers/resourceItem';
import { formatStatus } from '../../../../helpers/shipments';

const Shipemnts = ({ resourceitem: { shipments } }) => {
  const sorted = sortNumericallyBy(shipments, 'id');
  const displayShipments = sorted.map((li) => {
    const {
      id, name: contactName, status, shipmentRequests,
    } = li;

    return (
      <tr key={`shipments${id}`}>
        <td>{id}</td>
        <td>{contactName}</td>
        <td>{formatStatus(status)}</td>
        <td>
          {
            shipmentRequests.map((sr) => {
              const { quantity, name } = sr;
              return <div key={`sr${id}`}>Qty {quantity} - {name}</div>;
            })
          }
        </td>
      </tr>
    );
  });

  return (
    <Row>
      <Col xs={12}>
        <h3 className="page-title mb-0">
          Shipments
        </h3>
      </Col>
      <Col md={12}>
        <Card className="pb-0">
          <CardBody className="pt-3 pb-0">
            <div className="project-summary">
              <div className="table-responsive">
                <table className="table--bordered table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Status</th>
                      <th>Recipient</th>
                      <th>Line Items</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayShipments}
                  </tbody>
                </table>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

Shipemnts.propTypes = {
  resourceitem: PropTypes.shape({
    shipments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        itemId: PropTypes.number,
        name: PropTypes.string,
        quantity: PropTypes.number,
      }),
    ),
  }),
};

Shipemnts.defaultProps = {
  resourceitem: {},
};

export default Shipemnts;
