import './dymo';

const labelXml = () => (
  // eslint-disable-next-line no-multi-str
  '<?xml version="1.0" encoding="utf-8"?>\
  <DieCutLabel Version="8.0" Units="twips" MediaType="Default">\
  <PaperOrientation>Landscape</PaperOrientation>\
  <Id>LW_DURABLE_25X89mm</Id>\
  <PaperName>1933081 Drbl 1 x 3-1/2 in</PaperName>\
  <DrawCommands>\
  <RoundRectangle X="0" Y="0" Width="1440" Height="5040" Rx="90.70866" Ry="90.70866"/>\
  </DrawCommands>\
  <ObjectInfo>\
  <TextObject>\
  <Name>TEXT</Name>\
  <ForeColor Alpha="255" Red="255" Green="255" Blue="255"/>\
  <BackColor Alpha="255" Red="0" Green="0" Blue="0"/>\
  <LinkedObjectName></LinkedObjectName>\
  <Rotation>Rotation0</Rotation>\
  <IsMirrored>False</IsMirrored>\
  <IsVariable>True</IsVariable>\
  <HorizontalAlignment>Center</HorizontalAlignment>\
  <VerticalAlignment>Top</VerticalAlignment>\
  <TextFitMode>AlwaysFit</TextFitMode>\
  <UseFullFontHeight>True</UseFullFontHeight>\
  <Verticalized>False</Verticalized>\
  <StyledText>\
  <Element>\
  <String>PROPERTY</String>\
  <Attributes>\
  <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False"/>\
  <ForeColor Alpha="255" Red="255" Green="255" Blue="255"/>\
  </Attributes>\
  </Element>\
  </StyledText>\
  </TextObject>\
  <Bounds X="330" Y="72" Width="4395" Height="582.5134"/>\
  </ObjectInfo>\
  <ObjectInfo>\
  <TextObject>\
  <Name>TEXT_1</Name>\
  <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
  <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>\
  <LinkedObjectName></LinkedObjectName>\
  <Rotation>Rotation0</Rotation>\
  <IsMirrored>False</IsMirrored>\
  <IsVariable>True</IsVariable>\
  <HorizontalAlignment>Left</HorizontalAlignment>\
  <VerticalAlignment>Top</VerticalAlignment>\
  <TextFitMode>ShrinkToFit</TextFitMode>\
  <UseFullFontHeight>True</UseFullFontHeight>\
  <Verticalized>False</Verticalized>\
  <StyledText>\
  <Element>\
  <String>TXT</String>\
  <Attributes>\
  <Font Family="Arial" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>\
  <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
  </Attributes>\
  </Element>\
  </StyledText>\
  </TextObject>\
  <Bounds X="417.6" Y="720" Width="2390.4" Height="319.68"/>\
  </ObjectInfo>\
  <ObjectInfo>\
  <BarcodeObject>\
  <Name>BARCODE</Name>\
  <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
  <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>\
  <LinkedObjectName></LinkedObjectName>\
  <Rotation>Rotation0</Rotation>\
  <IsMirrored>False</IsMirrored>\
  <IsVariable>True</IsVariable>\
  <Text></Text>\
  <Type>Code128Auto</Type>\
  <Size>Small</Size>\
  <TextPosition>Bottom</TextPosition>\
  <TextFont Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False"/>\
  <CheckSumFont Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False"/>\
  <TextEmbedding>None</TextEmbedding>\
  <ECLevel>0</ECLevel>\
  <HorizontalAlignment>Center</HorizontalAlignment>\
  <QuietZonesPadding Left="0" Right="0" Top="0" Bottom="0"/>\
  </BarcodeObject>\
  <Bounds X="2973.523" Y="716.4" Width="1706.476" Height="633.6"/>\
  </ObjectInfo>\
  <ObjectInfo>\
  <TextObject>\
  <Name>TEXT_2</Name>\
  <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
  <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>\
  <LinkedObjectName></LinkedObjectName>\
  <Rotation>Rotation0</Rotation>\
  <IsMirrored>False</IsMirrored>\
  <IsVariable>True</IsVariable>\
  <HorizontalAlignment>Left</HorizontalAlignment>\
  <VerticalAlignment>Middle</VerticalAlignment>\
  <TextFitMode>ShrinkToFit</TextFitMode>\
  <UseFullFontHeight>True</UseFullFontHeight>\
  <Verticalized>False</Verticalized>\
  <StyledText>\
  <Element>\
  <String>TXT2</String>\
  <Attributes>\
  <Font Family="Helvetica" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>\
  <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
  </Attributes>\
  </Element>\
  </StyledText>\
  </TextObject>\
  <Bounds X="409.0242" Y="1046.908" Width="2389.011" Height="321.0924"/>\
  </ObjectInfo>\
  </DieCutLabel>'
);

const printLabel = (framework, type, id, ssid) => {
  // print the label
  const label = framework.openLabelXml(labelXml());
  label.setObjectText('TEXT', `${type}`);
  label.setObjectText('TEXT_1', ssid ? `SSID: ${ssid}` : '');
  label.setObjectText('TEXT_2', `Vector ID: ${id}`);
  label.setObjectText('BARCODE', `${id}`);
  label.print('DYMO LabelWriter 450 Turbo');
  return { success: true };
};

export default printLabel;
