import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';

const ItemLedger = ({
  resourceitem: {
    initialCounts,
    finalCounts,
  },
}) => (
  <Row>
    <Col md={12}>
      <h3 className="page-title mb-0">
        Inventory Ledger
      </h3>
    </Col>
    <Col md={12}>
      <Card className="pb-0">
        <CardBody className="pt-3 pb-0">
          <div className="project-summary">
            <Row>
              <Col lg={6}>
                <table className="project-summary__info ml-2">
                  <tbody>
                    <tr>
                      <th>Before A-Stock:</th>
                      <td>{ (initialCounts && initialCounts.aStock) }</td>
                    </tr>
                    <tr>
                      <th>Before A-Stock Assigned:</th>
                      <td>{ (initialCounts && initialCounts.aStockAssigned) }</td>
                    </tr>
                    <tr>
                      <th>Before B-Stock:</th>
                      <td>{ (initialCounts && initialCounts.bStock) }</td>
                    </tr>
                    <tr>
                      <th>Before B-Stock Assigned:</th>
                      <td>{ (initialCounts && initialCounts.bStockAssigned) }</td>
                    </tr>
                    <tr>
                      <th>Before Defective Stock:</th>
                      <td>{ (initialCounts && initialCounts.defectiveStock) }</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
              <Col lg={6}>
                <table className="project-summary__info ml-2">
                  <tbody>
                    <tr>
                      <th>After A-Stock:</th>
                      <td>{ (finalCounts && finalCounts.aStock) }</td>
                    </tr>
                    <tr>
                      <th>After A-Stock Assigned:</th>
                      <td>{ (finalCounts && finalCounts.aStockAssigned) }</td>
                    </tr>
                    <tr>
                      <th>After B-Stock:</th>
                      <td>{ (finalCounts && finalCounts.bStock) }</td>
                    </tr>
                    <tr>
                      <th>After B-Stock Assigned:</th>
                      <td>{ (finalCounts && finalCounts.bStockAssigned) }</td>
                    </tr>
                    <tr>
                      <th>After Defective Stock:</th>
                      <td>{ (finalCounts && finalCounts.defectiveStock) }</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

ItemLedger.propTypes = {
  resourceitem: PropTypes.shape({
    initialCounts: PropTypes.shape({
      aStock: PropTypes.number,
      aStockAssigned: PropTypes.number,
      bStock: PropTypes.number,
      bStockAssigned: PropTypes.number,
      defectiveStock: PropTypes.number,
    }),
    finalCounts: PropTypes.shape({
      aStock: PropTypes.number,
      aStockAssigned: PropTypes.number,
      bStock: PropTypes.number,
      bStockAssigned: PropTypes.number,
      defectiveStock: PropTypes.number,
    }),
  }),
};

ItemLedger.defaultProps = {
  resourceitem: {
    initialCounts: [],
    finalCounts: [],
  },
};

export default ItemLedger;
