import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { sortAlphabeticallyBy } from '../../../helpers/resourceItem';
import randInt from '../../../helpers/randInt';

const ContentsInformation = ({ resourceitem: { variants } }) => {
  const sorted = sortAlphabeticallyBy(variants, 'name');
  const display = sorted.map((itm, index) => {
    const { name, quantity } = itm;
    return (
      <tr key={randInt()}>
        <th>{index + 1}) </th>
        <td>{name} -- Qty {quantity}</td>
      </tr>
    );
  });

  const halfzies = Math.ceil(display.length / 2);
  const shouldSplit = display.length > 10;
  const leftSide = shouldSplit ? display.splice(0, halfzies) : display;

  return (
    <Row>
      <Col md={12}>
        <h3 className="page-title mb-0">
          Built Kit Contents
        </h3>
      </Col>
      <Col xl={shouldSplit ? 6 : 12}>
        <div className="project-summary">
          <table className="project-summary__info ml-2 mb-0">
            <tbody>
              {leftSide}
            </tbody>
          </table>
        </div>
      </Col>
      {
        shouldSplit
          ? (
            <Col xl={6}>
              <div className="project-summary">
                <table className="project-summary__info ml-2">
                  <tbody>
                    {display}
                  </tbody>
                </table>
              </div>
            </Col>
          )
          : null
      }
    </Row>
  );
};

ContentsInformation.propTypes = {
  resourceitem: PropTypes.shape({
    variants: PropTypes.arrayOf(PropTypes.object),
  }),
};

ContentsInformation.defaultProps = {
  resourceitem: {
    variants: [],
  },
};

export default ContentsInformation;
