import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
});

const Form = () => (
  <Container>
    <ShowContainer>
      {
        ({ id, fulfillmentType }) => {
          if (!id || !fulfillmentType) return null;
          const initialValues = { id };
          const successResource = fulfillmentType === 'new_order' ? 'sales' : 'return';
          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/drop_tracking`}
              formTitle="Remove Tracking Confirmation"
              formSubTitle={`Please confirm that you would like to remove tracking for Shipment #${id}`}
              successModalTitle="Shipment Tracking Removed!"
              successModalMessage="You'll be sent back to the Shipment index page"
              submitButtonText="Confirm Remove Tracking"
              successUrl={`/orders/${successResource}-shipments`}
            />
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Form));
