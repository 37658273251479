import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { sortServiceByMin } from './utils';
import ServiceOption from './ServiceOption';

const CarrierColumn = ({ shippingEstimates }) => (
  <>
    {
      shippingEstimates.map(({ carrierName, carrierCode, estimates }) => (
        <Col key={carrierCode}>
          <fieldset>
            <div style={{ marginBottom: 10 }}>
              <h3 className="page-subhead modal-subhead">
                {carrierName}
              </h3>
            </div>
            {
              sortServiceByMin(estimates).map(({ serviceCode, packages }) => (
                <div className="form__form-group-field" key={serviceCode}>
                  <div className="ml-5">
                    <ServiceOption
                      serviceCode={serviceCode}
                      packages={packages}
                    />
                  </div>
                </div>
              ))
            }
          </fieldset>
        </Col>
      ))
    }
  </>
);

CarrierColumn.propTypes = {
  shippingEstimates: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CarrierColumn;
