import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import randInt from '../../helpers/randInt';
import { formatReceivingStatus } from '../../helpers/resourceItem';

class ReceivingOrdersIndexContainer extends Component {
  createRows = (list) => (
    list.map((receivingOrder) => {
      const {
        id,
        poNumber,
        vendor,
        status,
        lineItems,
      } = receivingOrder;

      let displayNewShipment;
      if (status !== 'processed') {
        displayNewShipment = (
          <Link key={randInt()} to={`receiving-orders/${id}/receiving-shipments/new`}>
            New Shipment
          </Link>
        );
      }

      let displayProcess;
      if (status !== 'processed') {
        displayProcess = (
          <Link key={randInt()} to={`receiving-orders/${id}/mark-as-processed`}>
            Process
          </Link>
        );
      }

      return (
        <tr key={randInt()}>
          <td>{id}</td>
          <td>{vendor}</td>
          <td>{formatReceivingStatus(status)}</td>
          <td>{poNumber}</td>
          <td>
            {
              lineItems.map((li) => (
                <div key={li.id}>{li.quantity} - {li.name}</div>
              ))
            }
          </td>
          <td>
            <Link key={randInt()} to={`receiving-orders/${id}`}>
              Info
            </Link>
            { displayNewShipment && <span className="mx-2">|</span> }
            { displayNewShipment }
            { displayProcess && <span className="mx-2">|</span> }
            { displayProcess }
          </td>
        </tr>
      );
    })
  );


  buildBadgeList = (filter, defaultFilter) => {
    const {
      itemId,
      search,
      searchReceivingShipments,
      status,
    } = filter;
    const {
      itemId: defaultItemId,
      search: defaultSearch,
      searchReceivingShipments: defaultSearchReceivingShipments,
      status: defaultStatus,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('itemId'):
          if (itemId && itemId !== defaultItemId) {
            badgeList.push(
              {
                order: 4,
                title: 'For Item',
                descFilterable: 'items',
                filterKey: key,
              },
            );
          }
          break;
        case ('searchReceivingShipments'):
          if (searchReceivingShipments && searchReceivingShipments !== defaultSearchReceivingShipments) {
            badgeList.push(
              {
                order: 3,
                title: 'Search Receiving Shipments',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('status'):
          if (status && status !== defaultStatus) {
            const desc = filter[key].join(', ').replace('unprocessed', 'not processed');
            badgeList.push(
              {
                order: 2,
                title: 'Status',
                desc,
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  }

  render() {
    return (
      <IndexTableContainer
        newButtonText="New Receiving Order"
        newButton
        newFormUri="receiving-orders/new"
        pageTitle="Receiving Orders"
        searchPlaceholder="Search by ID, Receiving, PO #, or Invoice #..."
        heads={
          [
            { key: 'id', name: 'ID' },
            { key: 'vendor', name: 'Vendor' },
            { key: 'status', name: 'Status' },
            { key: 'poNumber', name: 'PO #' },
            { key: 'lineItems', name: 'Line Items' },
            { key: 'actions', name: 'Actions' },
          ]
        }
        filterForm={
          [
            {
              type: 'CheckBoxGroup',
              groupLabel: 'Status',
              filterKey: 'status',
              options: [
                { value: 'processed', label: 'Processed' },
                { value: 'unprocessed', label: 'Not Processed' },
                { value: 'canceled', label: 'Canceled' },
              ],
            },
            {
              type: 'Text',
              groupLabel: 'Receiving Shipment Search',
              filterKey: 'searchReceivingShipments',
              placeholder: 'Tracking Number or ID...',
            },
            {
              type: 'Select',
              groupLabel: 'Item Type',
              filterKey: 'itemId',
              placeholder: 'Filter by Item Type...',
              optionsKey: 'items',
            },
          ]
        }
        createRows={this.createRows}
        buildBadgeList={this.buildBadgeList}
      />
    );
  }
}

export default withRouter(ReceivingOrdersIndexContainer);
