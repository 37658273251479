import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import Information from './components/show/Information';
import AddressInformation from './components/show/AddressInformation';
import LineItems from './components/show/LineItems';
import Shipments from './components/show/Shipments';
import ShowContainer from '../../shared/components/show/ShowContainer';

const ShowIndex = () => (
  <Container>
    <ShowContainer>
      {
        (resourceitem) => {
          if (Object.keys(resourceitem).length === 0) return null;
          return (
            <>
              <Information resourceitem={resourceitem} />
              <hr />
              <AddressInformation resourceitem={resourceitem} />
              <hr />
              <LineItems resourceitem={resourceitem} />
              <hr />
              <Shipments resourceitem={resourceitem} />
            </>
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(ShowIndex));
