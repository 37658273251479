import React from 'react';
import { Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import randInt from '../../helpers/randInt';

const ShipStationSalesOrders = () => {
  const createRows = (list) => (
    list.map((salesOrder) => {
      const {
        orderId,
        orderNumber,
        createTimeFriendly,
        shipTo,
        items,
        customerEmail,
        orderStatus,
        vectorStatus,
        customerNotes,
        internalNotes,
        requestedShippingService,
        serviceCode,
      } = salesOrder;

      return (
        <tr key={orderId}>
          <td>
            <b>Order Number: </b>{orderNumber}<br />
            <b>Order ID: </b>{orderId}<br />
            <b>Time: </b>{createTimeFriendly}<br />
            <b>Service: </b>{requestedShippingService}
          </td>
          <td>
            <b>ShipStation: </b>{orderStatus}<br />
            <b>Vector: </b> {vectorStatus}
          </td>
          <td>
            <div>{shipTo.name} {shipTo.residential && ' (residential)'}</div>
            {shipTo.company && <div>{shipTo.company}</div>}
            <div>{shipTo.street1}</div>
            {shipTo.street2 && <div>{shipTo.street2}</div>}
            {shipTo.street3 && <div>{shipTo.street3}</div>}
            <div>
              {shipTo.city}, {shipTo.state}
              <span className="ml-2">{shipTo.postalCode}</span>
            </div>
          </td>
          <td>
            {
              items.map(({
                name, quantity, fulfillmentSku, sku,
              }) => (
                <div>
                  Qty {quantity} - {fulfillmentSku || sku} - {name}
                </div>
              ))
            }
          </td>
          <td>
            {
              vectorStatus === 'not_imported'
              && (
                <Link
                  to={{
                    pathname: '/orders/sales-orders/new',
                    state: {
                      customerNotes,
                      internalNotes,
                      requestedShippingService,
                      serviceCode,
                      initialValues: {
                        source: 'ship_station',
                        external_order_id: orderId,
                        name: shipTo.name,
                        contactEmail: customerEmail,
                        business: shipTo.company,
                        address1: shipTo.street1,
                        address2: shipTo.street2,
                        city: shipTo.city,
                        state: shipTo.state,
                        zip: shipTo.postalCode,
                        country: shipTo.country,
                        isResidential: shipTo.residential ? 'true' : 'false',
                        carrierServiceCode: '',
                        fulfillmentType: 'new_order',
                        lineItems: items.map((i) => (
                          {
                            contentId: i.fulfillmentSku || i.sku,
                            quantity: i.quantity,
                            name: i.name,
                            options: i.options,
                            imageUrl: i.imageUrl,
                            id: randInt(),
                          }
                        )),
                      },
                    },
                  }}
                >
                  Create
                </Link>
              )
            }
          </td>
        </tr>
      );
    })
  );

  // eslint-disable-next-line
  const buildBadgeList = (filter, defaultFilter) => {
    const {
      orderStatus,
      search,
    } = filter;
    const {
      search: defaultSearch,
      orderStatus: defaultOrderStatus,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('orderStatus'):
          if (orderStatus && orderStatus !== defaultOrderStatus) {
            badgeList.push(
              {
                order: 2,
                title: 'Status',
                desc: filter[key].replace(/_/g, ' '),
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  };

  return (
    <IndexTableContainer
      newFormUri="sales-orders/new"
      pageTitle="ShipStation Sales Orders"
      searchPlaceholder="Search by ShipStation Order Number..."
      filterForm={
        [
          {
            type: 'RadioButtonGroup',
            groupLabel: 'Status',
            filterKey: 'orderStatus',
            options: [
              { value: 'awaiting_shipment', label: 'Awaiting Shipment' },
              { value: 'awaiting_payment', label: 'Awaiting Payment' },
              { value: 'pending_fulfillment', label: 'Pending Fulfillment' },
              { value: 'shipped', label: 'Shipped' },
              { value: 'on_hold', label: 'On Hold' },
              { value: 'cancelled', label: 'Cancelled' },
            ],
          },
        ]
      }
      heads={
        [
          { key: 'orderId', name: 'Info' },
          { key: 'status', name: 'Status' },
          { key: 'recipient', name: 'Recipient' },
          { key: 'lineItems', name: 'Line Items' },
          { key: 'actions', name: 'Actions' },
        ]
      }
      createRows={createRows}
      buildBadgeList={buildBadgeList}
    />
  );
};

export default ShipStationSalesOrders;
