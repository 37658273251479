import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import CheckBoxIcon from 'mdi-react/CheckBoxIcon';
import CheckboxBlankOutlineIcon from 'mdi-react/CheckboxBlankOutlineIcon';
import { switchAccount } from '../../../redux/actions/authActions';

const TopbarAccountToggle = ({
  dispatch, history, title, accountId, currentAccountId,
}) => {
  const icon = accountId === currentAccountId
    ? <CheckBoxIcon className="topbar__icon" />
    : <CheckboxBlankOutlineIcon className="topbar__icon" />;
  return (
    <button
      className="topbar__link"
      type="button"
      onClick={() => dispatch(switchAccount(accountId, history))}
    >
      { icon }
      <p className="topbar__link-title">{title}</p>
    </button>
  );
};

TopbarAccountToggle.propTypes = {
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  accountId: PropTypes.number.isRequired,
  currentAccountId: PropTypes.number.isRequired,
};

export default withRouter(connect()(TopbarAccountToggle));
