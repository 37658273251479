import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { formatBuildType } from '../../../helpers/resourceItem';

const Information = ({
  resourceitem: {
    id,
    name,
    description,
    buildType,
    contentId,
    plannedCount,
    inStockCount,
    untestedCount,
    shipmentProfile: { weight, combinableType },
    mailingContainer: {
      length, width, height, name: containerName,
    },
  },
}) => (
  <Row>
    <Col md={12}>
      <h3 className="page-title mb-0">
        Bundle Detail Page
      </h3>
      {
        // <Button className="project-summary__btn mr-4" outline size="sm">Edit</Button>
      }
    </Col>
    <Col md={12}>
      <div className="project-summary">
        <Row>
          <Col lg={6}>
            <table className="project-summary__info ml-2">
              <tbody>
                <tr>
                  <th>Name:</th>
                  <td>{ name }</td>
                </tr>
                <tr>
                  <th>Fulfillment SKU:</th>
                  <td>{ contentId }</td>
                </tr>
                <tr>
                  <th>Database ID:</th>
                  <td>{ id }</td>
                </tr>
                <tr>
                  <th>Build Type:</th>
                  <td>{ formatBuildType(buildType) }</td>
                </tr>
                <tr>
                  <th>Description:</th>
                  <td>{ description }</td>
                </tr>
                <tr>
                  <th>Mailing Container:</th>
                  <td>{ containerName }</td>
                </tr>
                <tr>
                  <th>Combinable Type:</th>
                  <td>{ combinableType }</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={6}>
            <table className="project-summary__info ml-2">
              <tbody>
                <tr>
                  <th>Planned To Build:</th>
                  <td>{ (buildType === 'build_on_order' ? '-' : plannedCount) }</td>
                </tr>
                <tr>
                  <th>Build In Stock:</th>
                  <td>{ (buildType === 'build_on_order' ? '-' : inStockCount) }</td>
                </tr>
                <tr>
                  <th>Untested In Stock:</th>
                  <td>{ (buildType === 'build_on_order' ? '-' : untestedCount) }</td>
                </tr>
                <tr>
                  <th>Shipment Weight:</th>
                  <td>{ weight } oz</td>
                </tr>
                <tr>
                  <th>Shipment Length:</th>
                  <td>{ length } in</td>
                </tr>
                <tr>
                  <th>Shipment Width:</th>
                  <td>{ width } in</td>
                </tr>
                <tr>
                  <th>Shipment Height:</th>
                  <td>{ height } in</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    </Col>
  </Row>
);

Information.propTypes = {
  resourceitem: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    buildType: PropTypes.string,
    contentId: PropTypes.string,
    plannedCount: PropTypes.number,
    inStockCount: PropTypes.number,
    untestedCount: PropTypes.number,
    shipmentProfile: PropTypes.shape({
      weight: PropTypes.number,
      combinableType: PropTypes.string,
    }).isRequired,
    mailingContainer: PropTypes.shape({
      name: PropTypes.string,
      weight: PropTypes.number,
      length: PropTypes.number,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    id: PropTypes.number,
  }),
};

Information.defaultProps = {
  resourceitem: {},
};

export default Information;
