import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';

const IdentifiableBundle = ({ status, trackingNumber, assignments }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <div>
      {
        assignments.map((asgn, index) => {
          const { id: asgnId, contentIdentifiableId, returnedKitAction } = asgn;
          return (
            <div key={`asgn${asgnId}`} className="ml-4">
              <span className="mr-1">
                { `Kit ${index + 1}: `}
              </span>
              { contentIdentifiableId }
              {
                contentIdentifiableId
                && status === 'unprocessed'
                && <Link className="ml-1" to={`/orders/assignments/${asgnId}/remove`}><b>x</b></Link>
              }
              { contentIdentifiableId && <span className="mx-2">|</span> }
              { contentIdentifiableId && returnedKitAction === 'refurbish' ? 'Refurb' : 'Sunset' }
              {
                contentIdentifiableId
                && status === 'unprocessed'
                && <span className="ml-2">|</span>
              }
              {
                contentIdentifiableId && status === 'unprocessed'
                && (
                  <Link className="ml-2" to={`/orders/assignments/${asgnId}/update-kit/${contentIdentifiableId}`}>
                    Update
                  </Link>
                )
              }
              {
                !contentIdentifiableId && trackingNumber && status === 'unprocessed'
                  && (
                    <Link className="ml-2" to={`/orders/assignments/${asgnId}/assign-kit`}>
                      Assign
                    </Link>
                  )
              }
              {
                !contentIdentifiableId && !trackingNumber && status === 'unprocessed'
                  && (
                    <span>
                      <span className="link-text ml-2" id={`Tooltip-${asgnId}`}>Assign</span>
                      <Tooltip
                        placement="right"
                        target={`Tooltip-${asgnId}`}
                        isOpen={tooltipOpen}
                        toggle={toggle}
                      >
                        Add Tracking First!
                      </Tooltip>
                    </span>
                  )
              }
            </div>
          );
        })
      }
    </div>
  );
};

IdentifiableBundle.propTypes = {
  assignments: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  status: PropTypes.string.isRequired,
  trackingNumber: PropTypes.string,
};

IdentifiableBundle.defaultProps = {
  trackingNumber: null,
};

export default IdentifiableBundle;
