import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import TextField from '../../shared/components/form/TextField';
import SelectField from '../../shared/components/form/SelectFfield';

class Form extends Component {
  handleValidate = (values) => {
    const errors = {};
    if (!values.serialNumber) errors.serialNumber = 'Required';
    return errors;
  }

  render() {
    return (
      <Container>
        <ShowContainer>
          {
            ({ serialNumber: id, status, container }) => {
              if (!id || !status) return null;
              const initialValues = { serialNumber: id, toStock: status, toContainer: container };

              return (
                <FormWrapper
                  initialValues={initialValues}
                  onValidate={this.handleValidate}
                  requestMethod="PUT"
                  formUrlSuffix={`/${id}`}
                  formTitle="Update Device"
                  formSubTitle={`Enter information to update Device #${id}`}
                  successModalTitle="Device Sucessfully Updated!"
                  successModalMessage="You will be redirected back to the Devices index"
                  submitButtonText="Update"
                  successUrl="/inventory/devices"
                >
                  <div className="customizer__form-group-wrap no-bottom-line" style={{ width: '100%' }}>
                    <div className="mx-3">
                      <Row style={{ width: '100%' }}>
                        <Col lg={6}>
                          <TextField
                            label="Container"
                            fieldName="toContainer"
                            placeholder="Container..."
                          />
                        </Col>
                        <Col lg={6}>
                          <SelectField
                            fieldName="toStock"
                            label="Stock Type"
                            placeholder="Select Stock Type..."
                            options={
                              [
                                { label: 'A-Stock', value: 'a_stock' },
                                { label: 'B-Stock', value: 'b_stock' },
                                { label: 'Defective Stock', value: 'defective_stock' },
                              ]
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </FormWrapper>
              );
            }
          }
        </ShowContainer>
      </Container>
    );
  }
}

Form.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
};

export default withRouter(connect()(Form));
