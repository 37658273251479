import React from 'react';
import { withRouter } from 'react-router-dom';
import FetchDataHoc from '../../shared/components/FetchDataHoc';
import setResourceData from '../../helpers/setResourceData';
import ItemForm from './formIndex';

// eslint-disable-next-line react/prop-types
const EditForm = ({ history: domHistory, match: { params } }) => {
  const resourceData = setResourceData(domHistory, params);
  const { resourceExtension, id } = resourceData;
  if (!id) return null;
  return (
    <FetchDataHoc fetchDataUrl={`/${resourceExtension}/${id}`}>
      {
        (fetchedData) => (
          <ItemForm
            defaultValues={{
              ...fetchedData,
              combinableType: fetchedData.shipmentProfile.combinableType,
              fulfillmentSku: fetchedData.shipmentProfile.contentId,
              weight: fetchedData.shipmentProfile.weight || 0,
              length: fetchedData.shipmentProfile.length || 0,
              width: fetchedData.shipmentProfile.width || 0,
              height: fetchedData.shipmentProfile.height || 0,
            }}
            formSuffix={`/${id}`}
          />
        )

      }
    </FetchDataHoc>
  );
};

export default withRouter(EditForm);
