/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import randInt from '../../../helpers/randInt';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 5,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 16,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const ImageField = ({files: allFiles, setFieldValue, maxNumberOfFiles, label, formKey}) => {
  const files = allFiles[formKey];
  
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      // Grab the only file we care about and merge a preview
      const acceptedFile = { ...acceptedFiles[0],  preview: URL.createObjectURL(acceptedFiles[0]) }

      if (files.length < (maxNumberOfFiles)) {
        // Filter our files to ensure that we don't attach the same file twice
        const newFilesOfThisKeyType = files.filter(({name}) => name !== acceptedFile.name).concat([acceptedFile])
        setFieldValue("files", {...allFiles, [formKey]: newFilesOfThisKeyType})
      }
    }
  });

  const removeFile = (fileName) => {
    const newFilesOfThisKeyType = files.files.filter(({name}) => name !== fileName)
    setFieldValue("files", {...allFiles, [formKey]: newFilesOfThisKeyType})
  }
  
  const thumbs = files.map(file => (
    <div key={randInt()}>
      <div
        style={{color: 'white', float: 'right', cursor: 'pointer', marginRight: '10px'}}
        onClick={() => removeFile(file.name)}
      >
        x
      </div>
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            style={img}
          />
        </div>
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <div className="form__form-group">
      <span className="form__form-group-label">{label}</span>
      <div className="form__form-group-field">
        <div {...getRootProps({className: 'dropzone'})} style={{ width: '96%', height: '50px', border: "2px dashed gray", borderRadius: '5px'}}>
          <input {...getInputProps()} />
          { !files.length && <p style={{textAlign: "center"}}>Drop files here or click to select!</p> }
          { files.length && files.length < maxNumberOfFiles && <p style={{textAlign: "center"}}>Drop files here or click to select! You can add more files!</p> }
          { files.length === maxNumberOfFiles && <p style={{textAlign: "center"}}>Max number of files uploaded!</p> }
        </div>
      </div>
      <div style={{width: '100%'}}>
        { files.map((f) => <p>{f.name}</p>) }
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </div>
  );
}

export default ImageField