import React from 'react';
import IndexTableContext from './IndexTableContext';

const PaginationInfo = () => (
  <IndexTableContext.Consumer>
    {
      ({ searchMeta: { offset, limit, totalResults } }) => {
        const body = totalResults > 0
          ? (
            <span>
              Showing {offset + 1} to {
                (offset + limit) < totalResults ? (offset + limit) : totalResults
              } of {
                totalResults && totalResults.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              } total results.
            </span>
          )
          : (<span>Showing 0 total results.</span>);
        return (
          <div className="pagination__wrap">
            <div className="pagination-info">
              {body}
            </div>
          </div>
        );
      }
    }
  </IndexTableContext.Consumer>
);

export default PaginationInfo;
