import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SidebarCategory = ({
  title, icon, isNew, children, display,
}) => {
  const [collapse, setCollapse] = useState(false);
  const categoryClass = classNames({
    'sidebar__category-wrap': true,
    'sidebar__category-wrap--open': collapse,
  });

  if (!display) return null;

  return (
    <div className={categoryClass}>
      <button
        type="button"
        className="sidebar__link sidebar__category"
        onClick={() => setCollapse(!collapse)}
      >
        <span className="sidebar__link-icon">
          {icon}
        </span>
        <p className="sidebar__link-title">{title}
          {isNew && <span className="sidebar__category-new" />}
        </p>
        <span className="sidebar__category-icon lnr lnr-chevron-right" />
      </button>
      <Collapse isOpen={collapse} className="sidebar__submenu-wrap">
        <ul className="sidebar__submenu">
          <div>
            {children}
          </div>
        </ul>
      </Collapse>
    </div>
  );
};

SidebarCategory.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.shape(),
  isNew: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  display: PropTypes.bool.isRequired,
};

SidebarCategory.defaultProps = {
  icon: {},
  isNew: false,
  children: null,
};

export default SidebarCategory;
