import React from 'react';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import randInt from '../../helpers/randInt';

const OrderImportLogs = () => {
  const createRows = (list) => (
    list.map((log) => {
      const {
        id,
        createdAt,
        friendlySource,
        pollResults,
        createOrderResults,
      } = log;

      return (
        <tr key={randInt()}>
          <td>{id}</td>
          <td>{createdAt}</td>
          <td>{friendlySource}</td>
          <td>
            {
              pollResults.map(({
                page,
                pages,
                idsThatExist,
                idsToBeAdded,
              }) => (
                <div>
                  <b>Page:</b> {page}/{pages}
                  <b className="ml-3">Attempted:</b> {idsToBeAdded.length}
                  <b className="ml-3">Exist:</b> {idsThatExist.length}
                </div>
              ))
            }
          </td>
          <td>
            <div>
              <b>Successful:</b> {createOrderResults.filter((r) => r.success).length}
              <b className="ml-3">Failed:</b> {createOrderResults.filter((r) => !r.success).length}
            </div>
          </td>
        </tr>
      );
    })
  );

  // eslint-disable-next-line
  const buildBadgeList = (filter, defaultFilter) => {
    const {
      search,
    } = filter;
    const {
      search: defaultSearch,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  };

  return (
    <IndexTableContainer
      pageTitle="Sales Order Import Logs"
      searchPlaceholder="Search by Log ID..."
      filterForm={[]}
      heads={
        [
          { key: 'id', name: 'ID' },
          { key: 'createdAt', name: 'Created At' },
          { key: 'source', name: 'Source' },
          { key: 'pollResults', name: 'Poll Results' },
          { key: 'createResults', name: 'Create Results' },
        ]
      }
      createRows={createRows}
      buildBadgeList={buildBadgeList}
    />
  );
};

export default OrderImportLogs;
