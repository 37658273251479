import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import { sortAlphabeticallyBy } from '../../helpers/resourceItem';
import DevicesBlock from './components/DevicesBlock';

class Form extends Component {
  handleValidate = (values) => {
    const errors = {};
    if (!values.id) errors.id = 'Required';
    if (!values.returnToContainer) errors.returnToContainer = 'Required';
    if (!values.returnToStock) errors.returnToStock = 'Required';
    if (!values.kdvId) errors.kdvId = 'Required';
    return errors;
  }

  initialKdvs = (kdvs) => {
    if (!kdvs) return [];
    const initials = {};
    kdvs.forEach((kdv) => {
      initials[kdv.id] = {
        returnToContainer: '',
        returnToStock: 'b_stock',
        kdvId: kdv.id,
      };
    });
    return initials;
  }

  buildDeviceSections = (kdvs) => {
    const sorted = sortAlphabeticallyBy(kdvs, 'name');
    return sorted.map((kdv) => (
      <DevicesBlock
        key={`set${kdv.id}`}
        kdv={kdv}
      />
    ));
  }

  render() {
    return (
      <Container>
        <ShowContainer>
          {
            ({ id, kdvs }) => {
              if (!id || !kdvs) return null;
              const initialValues = { id, sunsetKdvs: this.initialKdvs(kdvs) };
              return (
                <FormWrapper
                  initialValues={initialValues}
                  onValidate={this.handleValidate}
                  formUrlSuffix={`/${id}/sunset`}
                  formTitle="Kit Sunset Form"
                  formSubTitle={`Please fill out the relevant information to sunset Kit #${id}`}
                  successModalTitle="Kit Sunset Successful!"
                  successModalMessage="You will be redirected back to the Kits index"
                  submitButtonText="Sunset Kit"
                  successUrl="/inventory/kits"
                >
                  { this.buildDeviceSections(kdvs) }
                </FormWrapper>
              );
            }
          }
        </ShowContainer>
      </Container>
    );
  }
}

export default withRouter(connect()(Form));
