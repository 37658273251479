import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import FormSectionWrapper from '../../shared/components/form/FormSectionWrapper';
import TextField from '../../shared/components/form/TextField';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
});

const Form = () => (
  <Container>
    <ShowContainer>
      {
        ({
          id,
          contentableType,
          contentIdentifiableId,
          shipmentId,
          shipmentFulfillmentType,
        }) => {
          if (!id || !contentableType || !shipmentId) return null;
          const ciType = contentableType === 'BuildList' ? 'Kit' : 'Device';
          const initialValues = { id, toContainer: '' };
          const successResource = shipmentFulfillmentType === 'new_order' ? 'sales' : 'return';
          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/remove`}
              formTitle="Shipment Remove Assignment Form"
              formSubTitle={`Remove ${ciType} #${contentIdentifiableId} from Shipment #${shipmentId}`}
              successModalTitle={`${ciType} Added!`}
              successModalMessage="You'll be sent back to the Shipments index page"
              submitButtonText={`Remove ${ciType}`}
              successUrl={`/orders/${successResource}-shipments`}
            >
              {
                ciType === 'Device'
                && (
                  <FormSectionWrapper>
                    <Row style={{ width: '100%' }}>
                      <Col lg={6}>
                        <TextField
                          label="Container"
                          fieldName="toContainer"
                          placeholder="Container..."
                        />
                      </Col>
                    </Row>
                  </FormSectionWrapper>
                )
              }
            </FormWrapper>
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Form));
