import React from 'react';
import DatePicker from 'react-datepicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import PropTypes from 'prop-types';
import { ErrorMessage, connect } from 'formik';

const DatePickerField = ({
  fieldName, placeholder, maxDate, label, formik: {
    values, setFieldValue, setFieldTouched,
  },
}) => {
  const value = values[fieldName];
  return (
    <div className="form__form-group" style={{ width: '100%' }}>
      { label && <span className="form__form-group-label">{label}</span> }
      <div className="form__form-group-field">
        <div className="date-picker">
          <DatePicker
            isClearable
            maxDate={maxDate}
            placeholderText={placeholder}
            className="form__form-group-datepicker"
            selected={(value && new Date(value.replace(/-/g, '/'))) || null}
            onChange={(val) => setFieldValue(fieldName, (val && val.toISOString().substring(0, 10)))}
            onBlur={() => setFieldTouched(fieldName, true)}
            dropDownMode="select"
          />
        </div>
        <div className="form__form-group-icon input-icon-right">
          <CalendarBlankIcon />
        </div>
      </div>
      <ErrorMessage
        name={fieldName}
        component="div"
        className="login__error"
        style={{ marginTop: '6px' }}
      />
    </div>
  );
};

DatePickerField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  maxDate: PropTypes.number,
  label: PropTypes.string,
  /* eslint-disable-next-line */
  formik: PropTypes.object.isRequired,
};

DatePickerField.defaultProps = {
  maxDate: null,
  label: null,
};


export default connect(DatePickerField);
