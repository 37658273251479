import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import TextField from '../../shared/components/form/TextField';
import SelectField from '../../shared/components/form/SelectFfield';

class Form extends Component {
  handleValidate = (values) => {
    const errors = {};
    if (!values.id) errors.id = 'Required';
    if (!values.oldSerialNumber) errors.oldSerialNumber = 'Required';
    if (!values.newSerialNumber) errors.newSerialNumber = 'Required';
    if (!values.toContainer) errors.toContainer = 'Required';
    if (!values.toStock) errors.toStock = 'Required';
    return errors;
  }

  render() {
    return (
      <Container>
        <ShowContainer>
          {
            ({ id }) => {
              const { match: { params: { serialNumber } } } = this.props;
              if (!id || !serialNumber) return null;
              const initialValues = {
                id,
                oldSerialNumber: serialNumber,
                newSerialNumber: '',
                toContainer: '',
                toStock: 'defective_stock',
              };

              return (
                <FormWrapper
                  initialValues={initialValues}
                  onValidate={this.handleValidate}
                  formUrlSuffix={`/${id}/replace_device`}
                  formTitle="Kit Replace Device Form"
                  formSubTitle={`Please fill out relevant information to replace Device ${serialNumber}`}
                  successModalTitle="Device replaced!"
                  successModalMessage={`You will be redirected back to the Kits ${id} info page`}
                  submitButtonText="Replace Device"
                  successUrl={`/inventory/kits/${id}`}
                >
                  <div
                    className="customizer__form-group-wrap no-bottom-line col-md-12"
                    style={{ width: '100%' }}
                  >
                    <div className="mx-3">
                      <Row style={{ width: '100%' }}>
                        <Col lg={4}>
                          <div className="form__form-group">
                            <TextField
                              fieldName="toContainer"
                              label="Old Device To Container"
                              placeholder="To Container..."
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="form__form-group">
                            <SelectField
                              fieldName="toStock"
                              label="Old Device To Stock Type"
                              placeholder="Stock Type..."
                              options={
                                [
                                  { label: 'A-Stock', value: 'a_stock' },
                                  { label: 'B-Stock', value: 'b_stock' },
                                  { label: 'Defective Stock', value: 'defective_stock' },
                                ]
                              }
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="form__form-group">
                            <TextField
                              fieldName="newSerialNumber"
                              label="New Device Serial Number"
                              placeholder="Serial Number..."
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </FormWrapper>
              );
            }
          }
        </ShowContainer>
      </Container>
    );
  }
}

Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};

export default withRouter(connect()(Form));
