import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import XLSX from 'xlsx';

class ExcelReader extends Component {
  handleChange = (e) => {
    const { files } = e.target;
    const file = files[0];
    const {
      formik: { setFieldValue },
      fileNameFieldName,
      fileDataFieldName,
      mapData,
    } = this.props;

    if (files && file) {
      const reader = new FileReader();
      reader.onload = (ev) => {
        const wb = XLSX.read(ev.target.result, { type: 'binary', bookVBA: true });
        const ws = wb.Sheets[wb.SheetNames[0]];
        const data = XLSX.utils.sheet_to_json(ws).map((d, i) => ({ ...d, FormIndex: i + 2 }));
        const mappedData = mapData ? mapData(data) : data;
        setFieldValue(fileNameFieldName, file.name);
        setFieldValue(fileDataFieldName, mappedData);
      };
      reader.readAsBinaryString(file);
    }
  }

  render() {
    const { formik: { values }, fileDataFieldName, fileNameFieldName } = this.props;
    return (
      <div>
        <input
          type="file"
          className="form-control"
          id="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={this.handleChange}
        />
        {
          values[fileDataFieldName].length > 0
          && (
            <h3 className="page-subhead subhead">
              { values[fileDataFieldName].length } Orders in { values[fileNameFieldName] }
            </h3>
          )
        }
      </div>
    );
  }
}

ExcelReader.propTypes = {
  /* eslint-disable-next-line */
  formik: PropTypes.object,
  fileNameFieldName: PropTypes.string.isRequired,
  fileDataFieldName: PropTypes.string.isRequired,
  mapData: PropTypes.func,
};

ExcelReader.defaultProps = {
  mapData: null,
};

export default connect(ExcelReader);
