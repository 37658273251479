import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import FormSectionWrapper from '../../shared/components/form/FormSectionWrapper';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
});

const Form = () => (
  <Container>
    <ShowContainer>
      {
        ({
          id, fulfillmentType, relatedShipmentIds, userFlag,
        }) => {
          if (!id || !fulfillmentType) return null;

          let flag;
          let submitButtonText;
          let formTitle;
          let formSubTitle;
          let additionalMessage;
          if (userFlag === 'hold') {
            flag = '';
            submitButtonText = 'Confirm Remove Hold';
            formTitle = 'Remove Shipment Hold Confirmation';
            formSubTitle = `Please confirm that you would like to remove hold from Shipment #${id}`;
            additionalMessage = 'This will remove hold from the following shipments';
          } else {
            flag = 'hold';
            submitButtonText = 'Confirm Hold';
            formTitle = 'Hold Shipment Confirmation';
            formSubTitle = `Please confirm that you would like to hold Shipment #${id}`;
            additionalMessage = 'This will hold the following shipments';
          }

          const initialValues = { id, userFlag: flag };
          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/update_user_flag`}
              formTitle={formTitle}
              formSubTitle={formSubTitle}
              successModalTitle="Shipment Updated!"
              successModalMessage="You'll be sent back to the Shipments index page"
              submitButtonText={submitButtonText}
              successUrl="/orders/sales-shipments"
            >
              <span>
                {
                  relatedShipmentIds.length > 0
                  && (
                    <FormSectionWrapper>
                      <span className="ml-3">
                        <h5>{additionalMessage}:</h5>
                        { relatedShipmentIds.map((s) => <h6 className="ml-3">Shipment ID: {s}</h6>) }
                      </span>
                    </FormSectionWrapper>
                  )
                }
              </span>
            </FormWrapper>
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Form));
