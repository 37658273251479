import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';

class Form extends Component {
  handleValidate = (values) => {
    const errors = {};
    if (!values.id) errors.id = 'Required';
    return errors;
  }

  render() {
    return (
      <Container>
        <ShowContainer>
          {
            ({ id }) => {
              if (!id) return null;
              const initialValues = { id };

              return (
                <FormWrapper
                  initialValues={initialValues}
                  onValidate={this.handleValidate}
                  formUrlSuffix={`/${id}/mark_as_completed`}
                  formTitle="Confirm Mark As Completed"
                  formSubTitle={`Please confirm that you would like to mark Batch #${id} as Completed`}
                  successModalTitle="Batch Marked as Completed!"
                  successModalMessage="You will be redirected back to the Batches index"
                  submitButtonText="Confirm Mark as Completed"
                  successUrl="/inventory/batches"
                />
              );
            }
          }
        </ShowContainer>
      </Container>
    );
  }
}

export default withRouter(connect()(Form));
