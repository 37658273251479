import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import CloseIcon from 'mdi-react/CloseIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Formik, Field } from 'formik';
import CheckBoxGroup from '../form/CheckBoxGroup';
import RadioButtonGroup from '../form/RadioButtonGroup';
import SelectField from '../form/SelectField';
import DatePickerGroup from '../form/DatePickerGroup';
import IndexTableContext from './IndexTableContext';

export default class FilterBarSideDisplay extends PureComponent {
  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  buildChildren = (formProps, filterForm, filterables) => {
    const {
      setFieldValue,
      setFieldTouched,
      values,
      errors,
      touched,
    } = formProps;

    if (!filterForm) return null;
    return filterForm.map((childData) => {
      const {
        type,
        groupLabel,
        filterKey,
        options,
        placeholder,
        filterKeys,
        filterTypes,
        optionsKey,
      } = childData;

      const filterableOptions = filterables[optionsKey] || [];
      let child;
      switch (type) {
        case 'CheckBoxGroup':
          child = (
            <CheckBoxGroup
              id={filterKey}
              value={values[filterKey]}
              error={errors[filterKey]}
              touched={touched[filterKey]}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              options={options}
            />
          );
          break;
        case 'RadioButtonGroup':
          child = (
            <RadioButtonGroup
              id={filterKey}
              value={values[filterKey]}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors[filterKey]}
              touched={touched[filterKey]}
              options={options}
            />
          );
          break;
        case 'Select':
          child = (
            <SelectField
              value={values[filterKey]}
              filterKey={filterKey}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors[filterKey]}
              touched={touched[filterKey]}
              options={filterableOptions.map((i) => ({ label: i.name, value: i.id }))}
              placeholder={placeholder}
            />
          );
          break;
        case 'Text':
          child = (
            <Field
              className="customizer__text-input"
              name={filterKey}
              type="text"
              placeholder={placeholder}
            />
          );
          break;
        case 'DateTime':
          child = (
            <DatePickerGroup
              values={values}
              groupLabel={groupLabel}
              filterKeys={filterKeys}
              filterTypes={filterTypes}
              setFieldValue={setFieldValue}
            />
          );
        // no default
      }

      const key = `FilterSideBar${groupLabel}`;
      return (
        <div key={key} className="customizer__form-group-wrap">
          <div className="customizer__form-group-title-wrap">
            <h5>{groupLabel}</h5>
          </div>
          {child}
        </div>
      );
    });
  }

  render() {
    return (
      <IndexTableContext.Consumer>
        {
          ({
            displayFilter,
            onToggleDisplayFilter,
            filter,
            filterForm,
            filterables,
            history,
            updateFilterAndSearch,
          }) => (
            <Formik
              enableReinitialize
              initialValues={filter}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  updateFilterAndSearch(values, history);
                  setSubmitting(false);
                }, 400);
              }}
            >
              {(formProps) => {
                const { handleSubmit, isSubmitting } = formProps;
                return (
                  <form className="form" onSubmit={handleSubmit}>
                    <div className="customizer">
                      <div className={`customizer__wrap ${displayFilter && 'customizer__wrap--open'}`}>
                        <div className="customizer__title-wrap">
                          <h4>Advanced Filter</h4>
                          <button className="customizer__close-btn" type="button" onClick={onToggleDisplayFilter}>
                            <CloseIcon />
                          </button>
                        </div>
                        {this.buildChildren(formProps, filterForm, filterables)}
                        <div className="customizer__form-group-wrap no-bottom-line">
                          <Button
                            block
                            className="icon icon--right"
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Filter <MagnifyIcon className="d-none d-sm-inline" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          )
        }
      </IndexTableContext.Consumer>
    );
  }
}
