import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { formatOrderStatus } from '../../helpers/resourceItem';

class IndexContainer extends Component {
  formatContents = (contents, shipmentId) => (
    contents.map((content) => {
      const { quantity, description } = content;
      return (
        <div key={`contents${shipmentId}${description}`} className="ml-4">
          {`Qty ${quantity}: ${description}`}
        </div>
      );
    })
  )

  createRows = (list) => (
    list.map((o) => {
      const {
        id,
        status,
        business,
        name,
        lineItemsCount,
        shipmentsCount,
      } = o;

      return (
        <tr key={id}>
          <td>{id}</td>
          <td>{formatOrderStatus(status)}</td>
          <td>{name || business}</td>
          <td>{lineItemsCount}</td>
          <td>{shipmentsCount}</td>
          <td><Link to={`/orders/return-orders/${id}`}>Show</Link></td>
        </tr>
      );
    })
  );

  buildBadgeList = (filter, defaultFilter) => {
    const {
      contentId,
      search,
      shipmentsStatus,
    } = filter;
    const {
      contentId: defaultContentId,
      search: defaultSearch,
      shipmentsStatus: defaultShipmentsStatus,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('contentId'):
          if (contentId && contentId !== defaultContentId) {
            badgeList.push(
              {
                order: 3,
                title: 'Contents',
                descFilterable: 'shipmentProfiles',
                filterKey: key,
              },
            );
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('shipmentsStatus'):
          if (shipmentsStatus && shipmentsStatus !== defaultShipmentsStatus) {
            badgeList.push(
              {
                order: 2,
                title: 'Status',
                desc: filter[key].replace(/_/g, ' '),
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  }

  render() {
    return (
      <IndexTableContainer
        newButtonText="New Sales Return"
        newButton
        newFormUri="return-orders/new"
        pageTitle="Return Orders"
        searchPlaceholder="Search by ID or Address..."
        heads={[
          { key: 'id', name: 'ID' },
          { key: 'status', name: 'Status' },
          { key: 'recipient', name: 'Recipient' },
          { key: 'lineItems', name: 'Line Item #' },
          { key: 'shipments', name: 'Shipment #' },
          { key: 'information', name: 'Info' },
        ]}
        filterForm={[
          {
            type: 'Select',
            groupLabel: 'Contents',
            filterKey: 'contentId',
            placeholder: 'Filter by Order Contents...',
            optionsKey: 'shipmentProfiles',
          },
        ]}
        createRows={this.createRows}
        buildBadgeList={this.buildBadgeList}
      />
    );
  }
}

export default IndexContainer;
