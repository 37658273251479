import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const GenericBundleExpanded = ({ data, id, status }) => (
  <>
    {
      Object.keys(data).map((key) => (
        <div key={key} className="ml-4">
          Kit {key.charAt(0).toUpperCase() + key.slice(1)}
          <span className="mx-2">|</span>
          {
            status === 'unprocessed'
            && <Link to={`/orders/return-line-items/${id}/update-kit/${key}`}>Update</Link>
          }
          {
            data[key].map((vq) => (
              <div key={vq.variantId} className="ml-4">
                {vq.itemName}
                <div className="ml-4">
                  <span>Expected: {vq.quantity}</span>
                  <span className="mx-2">|</span>
                  <span>Returned: {vq.returnedQuantity}</span>
                  <span className="mx-2">|</span>
                  <span>Restockable: {vq.restockableQuantity}</span>
                </div>
              </div>
            ))
          }
        </div>
      ))
    }
  </>
);

GenericBundleExpanded.propTypes = {
  data: PropTypes.shape({
    quantity: PropTypes.number,
    returnedQuantity: PropTypes.number,
    restockableQuantity: PropTypes.number,
  }).isRequired,
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default GenericBundleExpanded;
