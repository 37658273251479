import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { formatOrderStatus } from '../../../../helpers/resourceItem';

const Information = ({
  resourceitem: {
    id,
    status,
    externalOrderId,
    carrierServiceCode,
    processedDate,
    orderTime,
    requestorId,
    requestorTeamId,
  },
}) => (
  <Row>
    <Col xs={12}>
      <h3 className="page-title mb-0">
        Sales Order Detail Page
      </h3>
    </Col>
    <Col md={12}>
      <Card className="pb-0">
        <CardBody className="pt-3 pb-0">
          <div className="project-summary">
            <Row>
              <Col lg={6}>
                <table className="project-summary__info ml-2 mb-0">
                  <tbody>
                    <tr>
                      <th>Database ID:</th>
                      <td>{ id }</td>
                    </tr>
                    <tr>
                      <th>Status:</th>
                      <td>{ formatOrderStatus(status) }</td>
                    </tr>
                    <tr>
                      <th>Carrier Service:</th>
                      <td>{ carrierServiceCode }</td>
                    </tr>
                    {
                      externalOrderId
                        ? (
                          <tr>
                            <th>External Order ID:</th>
                            <td>{ externalOrderId }</td>
                          </tr>
                        )
                        : null
                    }
                  </tbody>
                </table>
              </Col>
              <Col lg={6}>
                <table className="project-summary__info ml-2">
                  <tbody>
                    <tr>
                      <th>Order Date:</th>
                      <td>{ orderTime }</td>
                    </tr>
                    <tr>
                      <th>Requestor ID:</th>
                      <td>{ requestorId }</td>
                    </tr>
                    <tr>
                      <th>Database ID:</th>
                      <td>{ requestorTeamId }</td>
                    </tr>
                    {
                      processedDate
                        ? (
                          <tr>
                            <th>Processed Date:</th>
                            <td>{ processedDate }</td>
                          </tr>
                        )
                        : null
                    }
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

Information.propTypes = {
  resourceitem: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    externalOrderId: PropTypes.string,
    carrierServiceCode: PropTypes.string,
    processedDate: PropTypes.string,
    orderTime: PropTypes.string,
    requestorId: PropTypes.string,
    requestorTeamId: PropTypes.string,
  }),
};

Information.defaultProps = {
  resourceitem: {},
};

export default Information;
