import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import DevicesBlock from './components/DevicesBlock';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import { sortNumericallyBy, sortAlphabeticallyBy } from '../../helpers/resourceItem';

class Form extends Component {
  handleValidate = (values) => {
    const errors = {};
    if (!values.id) errors.status = 'Required';
    return errors;
  }

  initialDevices = (kits) => {
    if (!kits) return [];
    const sortedKits = sortNumericallyBy(kits, 'id');
    const scannedDevices = {};
    sortedKits.forEach((kit) => {
      kit.kdvs.forEach((kdv) => {
        scannedDevices[kdv.id] = {
          kdvId: kdv.id,
          serialNumber: kdv.serialNumber || '',
        };
      });
    });
    return scannedDevices;
  }

  buildDeviceSections = (kits, variants, id) => {
    if (!kits || !variants || !id) return [];
    const snVars = variants.filter((v) => v.itemType === 'serial_number');
    return sortAlphabeticallyBy(snVars, 'name').map((variant) => (
      <DevicesBlock
        key={`var${variant.variantId}`}
        variant={variant}
        kits={kits}
        id={id}
      />
    ));
  }

  render() {
    return (
      <Container>
        <ShowContainer>
          {
            ({ id, kits, variants }) => {
              if (!id || !kits || !variants) return null;
              const initialValues = {
                id,
                scannedDevices: this.initialDevices(kits),
              };

              return (
                <FormWrapper
                  initialValues={initialValues}
                  onValidate={this.handleValidate}
                  formUrlSuffix={`/${id}/add_devices`}
                  formTitle="Batch Devices Form"
                  formSubTitle={`Scan Devices for Batch #${id}`}
                  successModalTitle="Devices successfully added!"
                  successModalMessage="This page will reload shortly!"
                  submitButtonText="Add Devices"
                  // successUrl={`/inventory/batches/${id}/devices`}
                  successUrl="/inventory/batches"
                >
                  { this.buildDeviceSections(kits, variants, id) }
                </FormWrapper>
              );
            }
          }
        </ShowContainer>
      </Container>
    );
  }
}

export default withRouter(connect()(Form));
