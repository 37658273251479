import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import RadioButton from './RadioButton';
import randInt from '../../../helpers/randInt';

class RadioButtonGroup extends React.Component {
  handleBlur = () => {
    const { onBlur, id } = this.props;
    onBlur(id, true);
  };

  buildChildren = () => {
    const {
      id,
      options,
    } = this.props;

    return options.map((opt) => {
      const { label, value } = opt;
      const hasValue = !!value;
      const identifier = hasValue ? value : randInt();
      return (
        <div className="form__form-group-field" key={identifier}>
          <Field
            component={RadioButton}
            name={id}
            id={identifier}
            label={label}
            value={value}
          />
        </div>
      );
    });
  }

  render() {
    const { error, touched } = this.props;

    return (
      <fieldset>
        {this.buildChildren()}
        {touched && error}
      </fieldset>
    );
  }
}

RadioButtonGroup.propTypes = {
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  touched: PropTypes.bool,
};

RadioButtonGroup.defaultProps = {
  error: '',
  onBlur: () => {},
  touched: false,
};

export default RadioButtonGroup;
