import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { ErrorMessage, Field } from 'formik';
import { Link } from 'react-router-dom';

const DevicesBlock = ({ variant, kits, id }) => (
  <div
    key={`scan${variant.variantId}`}
    className="customizer__form-group-wrap no-bottom-line col-md-12"
    style={{ width: '100%' }}
  >
    <div className="customizer__form-group-title-wrap mb-3">
      <h4>{variant.name}</h4>
    </div>
    <div className="mx-3">
      <Row style={{ width: '100%' }}>
        {
          kits.map((kit) => {
            const kdv = kit.kdvs.find((k) => k.variantId === variant.variantId);
            const { serialNumber } = kdv;
            return (
              <Col lg={6} key={`kdv${kdv.id}`}>
                <div className="form__form-group">
                  <span className="form__form-group-label">{`Kit ID: ${kit.id}`}</span>
                  {
                    serialNumber
                      ? (
                        <Link
                          className="float-right"
                          to={`/inventory/batches/${id}/remove-device/${serialNumber}`}
                        >
                          Remove
                        </Link>
                      )
                      : null
                  }
                  <div className="form__form-group-field">
                    <Field
                      validateonblur="true"
                      name={`scannedDevices.${kdv.id}.serialNumber`}
                      className="form__input"
                      placeholder="Serial Number..."
                      disabled={serialNumber}
                    />
                  </div>
                  <ErrorMessage
                    name={`scannedDevices.${kdv.id}.serialNumber`}
                    component="div"
                    className="login__error"
                  />
                </div>
              </Col>
            );
          })
        }
      </Row>
    </div>
  </div>
);

DevicesBlock.propTypes = {
  variant: PropTypes.shape().isRequired,
  kits: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.number.isRequired,
};

export default DevicesBlock;
