import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';

import EyeIcon from 'mdi-react/EyeIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';

import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Alert from '../../../shared/components/general/Alert';
import SuccessModal from '../../../shared/components/general/SuccessModal';
import { loginSuccess } from '../../../redux/actions/authActions';
import { getReturnedInvite } from '../../../redux/reducers/authReducer';
import { apiAction } from '../../../redux/actions/apiActions';
import { toCamelCase, humanizeCamelCase } from '../../../helpers/convertKeys';

class AcceptInviteForm extends Component {
  constructor() {
    super();
    this.state = {
      showPassword: false,
      showPasswordConfirmation: false,
      serverErrors: [],
      createSuccess: false,
      invite: {},
    };
  }

  componentDidMount() {
    const { match, dispatch, history } = this.props;
    const { inviteToken } = match.params;
    dispatch(
      apiAction({
        method: 'GET',
        url: `/invites/${inviteToken}`,
        onSuccess: (res) => {
          this.setState({ invite: res });
          if (res.accepted) {
            this.setState({ serverErrors: { invite: 'This invite has already been accepted' } });
            setTimeout(() => { history.push('/login'); }, 3000);
          }
          return { type: 'FORM_REQUEST' };
        },
        onFailure: (errors) => {
          const fieldErrors = {};
          errors.forEach((e) => { fieldErrors[toCamelCase(e.field)] = e.message; });
          this.setState({ serverErrors: fieldErrors });
          setTimeout(() => { history.push('/login'); }, 3000);
          return { type: 'FORM_REQUEST' };
        },
        history,
      }),
    );
  }

  handleClearServerErrors = () => {
    this.setState({ serverErrors: {} });
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  showPasswordConfirmation = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPasswordConfirmation: !prevState.showPasswordConfirmation }));
  };

  render() {
    const {
      showPassword,
      showPasswordConfirmation,
      serverErrors,
      invite,
      createSuccess,
    } = this.state;

    const {
      expired,
      existingUserId,
      existingUserName,
      teamName,
    } = invite;

    const { match } = this.props;
    const { inviteToken } = match.params;
    const expiredOrExisting = expired
      ? (
        <div className="login__error">
          Sorry { existingUserName }! Unfortunately for security purposes,
          this invite has expired. Please have the original sender re-send.
        </div>
      )
      : (
        <span className="ml-2 account__form-text">
          Hello { existingUserName }, click the button below to join
          the { teamName } account!
        </span>
      );

    return (
      <Formik
        initialValues={{
          passwordConfirmation: '',
          password: '',
          inviteToken,
          firstName: '',
          lastName: '',
        }}
        validate={(values) => {
          const {
            password,
            passwordConfirmation,
            firstName,
            lastName,
          } = values;
          const errors = {};
          if (!firstName) errors.firstName = 'Required';
          if (!lastName) errors.lastName = 'Required';
          if (!password) errors.password = 'Required';
          if (!passwordConfirmation) errors.passwordConfirmation = 'Required';
          if (!inviteToken) errors.inviteToken = 'Required';

          const passwordValueExists = !!password;
          const passwordConfirmationValueExists = !!passwordConfirmation;
          if (passwordValueExists && passwordConfirmationValueExists && password !== passwordConfirmation) {
            errors.password = 'Password values do not match';
            errors.passwordConfirmation = 'Password values do not match';
          }

          if (
            passwordValueExists
              && (
                password.length < 8
                || password.match(/[A-Z]/).length < 1
                || password.match(/[a-z]/).length < 1
                || password.match(/[0-9]/).length < 1
                || password.match(/[\W]/).length < 1
              )
          ) {
            errors.password = (
              <span>
                Password must contain at least:
                <ul>
                  <li>8 characters</li>
                  <li>1 uppercase letter</li>
                  <li>1 lowercase letter</li>
                  <li>1 number</li>
                  <li>1 special character</li>
                </ul>
              </span>
            );
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          setTimeout(() => {
            const { dispatch, history } = this.props;
            dispatch(
              apiAction({
                method: 'POST',
                params: values,
                url: '/invites/accept_invite',
                onSuccess: (data) => {
                  this.setState({ createSuccess: true });
                  dispatch(loginSuccess(data));
                  setTimeout(() => { history.push('/login'); }, 2000);
                  return { type: 'FORM_REQUEST' };
                },
                onFailure: (errors) => {
                  const fieldErrors = {};
                  errors.forEach((e) => { fieldErrors[toCamelCase(e.field)] = e.message; });
                  this.setState({ serverErrors: fieldErrors });
                  setErrors(fieldErrors);
                  return { type: 'FORM_REQUEST' };
                },
                history,
              }),
            );
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <SuccessModal
              isOpen={createSuccess}
              title="Account Successfully Created!"
              colored
              btn="Success"
              message="You will be redirected into Vector Fulfillment Dashboard."
            />
            {
              (existingUserId || expired)
                ? expiredOrExisting
                : (
                  <span style={{ width: '100%' }}>
                    <div className="mb-5 account__form-text">
                      Complete the form below to join the { teamName } account!
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">First Name</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon input-icon-left">
                          <AccountOutlineIcon />
                        </div>
                        <Field type="firstName" name="firstName" className="login__input" />
                      </div>
                      <ErrorMessage name="firstName" component="div" className="login__error" />
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Last Name</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon input-icon-left">
                          <AccountOutlineIcon />
                        </div>
                        <Field type="lastName" name="lastName" className="login__input" />
                      </div>
                      <ErrorMessage name="lastName" component="div" className="login__error" />
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Password</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon input-icon-left">
                          <KeyVariantIcon />
                        </div>
                        <Field
                          validateonblur="true"
                          type={`${showPassword ? 'text' : 'password'}`}
                          name="password"
                          className="login__input icon-right"
                        />
                        <button
                          className={`form__form-group-button login__input${showPassword ? ' active' : ''}`}
                          onClick={(e) => this.showPassword(e)}
                          type="button"
                        ><EyeIcon />
                        </button>
                      </div>
                      <ErrorMessage name="password" component="div" className="login__error" />
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Password Confirmation</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon input-icon-left">
                          <KeyVariantIcon />
                        </div>
                        <Field
                          validateonblur="true"
                          type={`${showPasswordConfirmation ? 'text' : 'password'}`}
                          name="passwordConfirmation"
                          className="login__input icon-right"
                        />
                        <button
                          className={`form__form-group-button login__input${showPasswordConfirmation ? ' active' : ''}`}
                          onClick={(e) => this.showPasswordConfirmation(e)}
                          type="button"
                        ><EyeIcon />
                        </button>
                      </div>
                      <ErrorMessage name="passwordConfirmation" component="div" className="login__error" />
                    </div>
                  </span>
                )
            }
            <Alert
              color="danger"
              className="alert--bordered"
              icon
              visible={Object.keys(serverErrors).length > 0}
              onDismiss={this.handleClearServerErrors}
            >
              <p>
                <span className="bold-text mr-2">
                  Warning!
                </span>
                An error has occurred!
              </p>
              {
                Object.keys(serverErrors).map((errorKey) => (
                  <p className="ml-2" key={`error-${errorKey}`}>
                    <b className="mr-1">{humanizeCamelCase(errorKey)}:</b>
                    { serverErrors[errorKey] }
                  </p>
                ))
              }
            </Alert>
            <button
              type="submit"
              disabled={isSubmitting || expired}
              className="btn btn-secondary account__btn account__btn--small account__login__btn"
            >
              Accept Invite
            </button>
          </Form>
        )}
      </Formik>
    );
  }
}

AcceptInviteForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  invite: getReturnedInvite(state),
});

export default withRouter(connect(mapStateToProps)(AcceptInviteForm));
