import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line object-curly-newline
import { FieldArray, Field, ErrorMessage, connect } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import SelectField from '../../../../shared/components/form/SelectField';
import randInt from '../../../../helpers/randInt';

const VariantsArrayField = ({
  variantsList,
  formik,
}) => {
  const {
    values: { variants },
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = formik;

  return (
    <FieldArray name="variants">
      {({ remove, push }) => (
        <div className="form__form-group">
          {
            variants.length > 0 && variants.map((_v, index) => (
              <Row key={randInt()} className="my-3">
                <Col md={2} lg={2}>
                  <span className="form__form-group-label">Quantity</span>
                  <div className="form__form-group-field">
                    <Field
                      validateonblur="true"
                      type="text"
                      name={`variants.${index}.quantity`}
                      className="form__input"
                      placeholder="Quantity..."
                    />
                  </div>
                  <ErrorMessage
                    name={`variants.${index}.quantity`}
                    component="div"
                    className="login__error"
                  />
                </Col>
                <Col md={7} lg={8}>
                  <div className="form__form-group-field">
                    <SelectField
                      label="Inventory Item or Kit"
                      value={variants[index].variantId}
                      filterKey={`variants.${index}.variantId`}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors[`variants.${index}.variantId`]}
                      touched={touched[`variants.${index}.variantId`]}
                      placeholder="Select Contents..."
                      options={variantsList}
                    />
                  </div>
                </Col>
                <Col md={3} lg={2}>
                  <Button
                    style={{ marginTop: '23px', padding: '8px' }}
                    block
                    onClick={() => { if (variants.length > 1) remove(index); }}
                    disabled={isSubmitting}
                    outline
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            ))
          }
          <Button
            outline
            color="primary"
            disabled={isSubmitting}
            onClick={() => push({
              variantId: '',
              quantity: '',
              defaultRestock: false,
              id: randInt(),
            })}
          >
            Add Line Item
          </Button>
        </div>
      )}
    </FieldArray>
  );
};

VariantsArrayField.propTypes = {
  variantsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.value,
    }),
  ).isRequired,
  formik: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    values: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    touched: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(VariantsArrayField);
