import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ShippingWeight = ({ setWeight, calculatedWeight, id }) => (
  setWeight
    ? (
      <div>
        <b className="mr-2">Set Weight</b>
        {setWeight} oz
        <Link className="ml-3" to={`/orders/sales-shipments/${id}/drop-set-weight`}>
          Drop
        </Link>
      </div>
    )
    : (
      <div>
        <b className="mr-2">Calculated Weight</b>
        {calculatedWeight} oz
        <Link className="ml-3" to={`/orders/sales-shipments/${id}/set-weight`}>
          Set Manually
        </Link>
      </div>
    )
);

ShippingWeight.propTypes = {
  id: PropTypes.number.isRequired,
  calculatedWeight: PropTypes.number.isRequired,
  setWeight: PropTypes.number,
};

ShippingWeight.defaultProps = {
  setWeight: null,
};

export default ShippingWeight;
