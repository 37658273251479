import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import { Col, Row, Button } from 'reactstrap';
import TextField from '../../../../shared/components/form/TextField';

class DevicesPartTwo extends Component {
  render() {
    const { goBack, devices, persistedDevices } = this.props;
    const validationSchema = Yup.object().shape({
      devices: Yup.array()
        .of(
          Yup.string()
            .required('Required')
            .test('len', 'Must be at least 5 characters', (val) => val.length > 4)
            .test(
              'duplicate',
              'Duplicate',
              // We need to use 'function' to access `this`
              // eslint-disable-next-line
              function (item) {
                const dups = this.parent.filter((e, i, a) => a.indexOf(e) !== i);
                const included = dups.includes(item);
                return !(dups && included);
              },
            )
            .test(
              'persisted',
              'Device Already Exists in Shipment',
              // eslint-disable-next-line
              function (item) {
                return !(persistedDevices.includes(item));
              },
            ),
        )
        .min(1, 'Must have at least one device'),
    });

    return (
      <Formik
        validateOnMount
        initialValues={{ devices }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const { onSubmit } = this.props;
          onSubmit(values.devices);
        }}
      >
        {
          (props) => (
            <Form className="form" style={{ width: '100%', marginTop: '20px' }}>
              <div className="col-md-12">
                <h3 className="page-title">
                  Review/ Manually Add or Remove Devices
                </h3>
                <h3 className="page-subhead subhead">
                  <span>
                    Current Devices: {props.values.devices.length}
                  </span>
                </h3>
              </div>
              <div
                className="customizer__form-group-wrap no-bottom-line col-md-12"
                style={{ width: '100%' }}
              >
                <div className="mx-3">
                  <FieldArray name="devices">
                    {({ remove, push }) => (
                      <div className="form__form-group">
                        <Row className="border_bottom mb-2">
                          {
                            props.values.devices.length > 0 && props.values.devices.map((d, index) => (
                              // without index this breaks the input field
                              // eslint-disable-next-line
                              <Col key={`${index}`} md={6}>
                                <Row>
                                  <Col className="my-2" md={10}>
                                    <TextField
                                      fieldName={`devices.${index}`}
                                      placeholder="Serial Number..."
                                      label="Serial Number"
                                    />
                                  </Col>
                                  <Col className="my-2" md={2}>
                                    <Button
                                      style={{ marginTop: '23px', padding: '7px' }}
                                      block
                                      onClick={() => { if (props.values.devices.length > 1) remove(index); }}
                                      outline
                                    >
                                      x
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            ))
                          }
                        </Row>
                        <Button
                          outline
                          className="pull-right"
                          color="primary"
                          onClick={() => push('')}
                        >
                          Add Another Device
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </div>
              </div>
              <div className="customizer__form-group-wrap no-bottom-line col-md-12" style={{ width: '100%' }}>
                <Row>
                  <Col xs={6}>
                    <Button block color="secondary" onClick={goBack}>
                      Back To Step 1
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button
                      block
                      type="submit"
                      color="primary"
                      disabled={props.isSubmitting}
                      onClick={props.handleSubmit}
                    >
                      Step 3: Add Container
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          )
        }
      </Formik>
    );
  }
}

DevicesPartTwo.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  devices: PropTypes.arrayOf(PropTypes.string).isRequired,
  persistedDevices: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withRouter(connect()(DevicesPartTwo));
