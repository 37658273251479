import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import FormWrapper from '../../shared/components/form/FormWrapper';
import SelectField from '../../shared/components/form/SelectFfield';
import TextField from '../../shared/components/form/TextField';
import DatePickerField from '../../shared/components/form/DatePickerFfield';
import { getFilterables } from '../../redux/reducers/authReducer';

class Form extends Component {
  handleValidate = (values) => {
    const errors = {};
    if (!values.buildListId) errors.buildListId = 'Required';
    if (!values.startDate) errors.startDate = 'Required';
    if (!values.orderSize) errors.orderSize = 'Required';
    if (values.orderSize.match(/[^$,.\d]/)) errors.orderSize = 'Invalid Order Size';
    return errors;
  }

  render() {
    const initialValues = {
      buildListId: '',
      orderSize: '',
      startDate: '',
    };

    const { bundles } = this.props;
    const blList = [];
    const filteredBls = bundles.filter((bl) => bl.buildType !== 'build_on_order');
    if (filteredBls) {
      filteredBls.forEach((bl) => blList.push({ label: bl.name, value: bl.id }));
    }

    return (
      <Container>
        <FormWrapper
          initialValues={initialValues}
          onValidate={this.handleValidate}
          formTitle="Batch Form"
          successModalTitle="Batch successfully created"
          successModalMessage="You will be redirected back to the Batches index"
          submitButtonText="Create"
          successUrl="/inventory/batches"
        >
          <div className="customizer__form-group-wrap no-bottom-line" style={{ width: '100%' }}>
            <div className="mx-3">
              <Row style={{ width: '100%' }}>
                <Col lg={4}>
                  <SelectField
                    label="Bundle"
                    placeholder="Bundle..."
                    fieldName="buildListId"
                    options={blList}
                  />
                </Col>
                <Col lg={4}>
                  <TextField
                    label="Order Size"
                    fieldName="orderSize"
                    placeholder="Order Size..."
                  />
                </Col>
                <Col lg={4}>
                  <DatePickerField
                    label="Estimated Start Date"
                    fieldName="startDate"
                    placeholder="Estimated Start Date..."
                  />
                </Col>
              </Row>
            </div>
          </div>
        </FormWrapper>
      </Container>
    );
  }
}

Form.propTypes = {
  bundles: PropTypes.arrayOf(PropTypes.object),
};

Form.defaultProps = {
  bundles: [],
};

const mapStateToProps = (state) => ({
  bundles: getFilterables(state).bundles,
});

export default withRouter(connect(mapStateToProps)(Form));
