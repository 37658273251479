import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import CachedIcon from 'mdi-react/CachedIcon';
import { logOutAction } from '../../../redux/actions/authActions';

const TopbarLogOut = ({ dispatch, history }) => (
  <button
    className="topbar__link"
    type="button"
    onClick={() => dispatch(logOutAction(history))}
  >
    <CachedIcon className="topbar__icon" />
    <p className="topbar__link-title">Log out</p>
  </button>
);

TopbarLogOut.propTypes = {
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default withRouter(connect()(TopbarLogOut));
