import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';

import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link, withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { loginAction } from '../../../redux/actions/authActions';

import { getAuthenticated, getErrors } from '../../../redux/reducers/authReducer';

const LogInForm = ({
  history, authenticated, dispatch, errors: errorMessages,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (authenticated) history.push('/inventory/items');
  }, [authenticated]);

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          dispatch(loginAction(values, history));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({ isSubmitting }) => (
        <Form className="form">
          <div className="form__form-group">
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon input-icon-left">
                <AccountOutlineIcon />
              </div>
              <Field type="email" name="email" className="login__input" />
            </div>
            <ErrorMessage name="email" component="div" className="login__error" />
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon input-icon-left">
                <KeyVariantIcon />
              </div>
              <Field
                type={`${showPassword ? 'text' : 'password'}`}
                name="password"
                className="login__input icon-right"
              />
              <button
                className={`form__form-group-button login__input${showPassword ? ' active' : ''}`}
                onClick={() => setShowPassword(!showPassword)}
                type="button"
              ><EyeIcon />
              </button>
              <ErrorMessage name="password" component="div" />
              <Link className="account__forgot-password" to="/forgot-password">Forgot your password?</Link>
            </div>
          </div>
          <div className="login__error">
            { errorMessages.map((e) => <div key={e}>{e}</div>) }
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="btn btn-secondary account__btn account__btn--small account__login__btn"
          >
            Sign In
          </button>
        </Form>
      )}
    </Formik>
  );
};

LogInForm.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
};

LogInForm.defaultProps = {
  errors: [],
};

const mapStateToProps = (state) => ({
  authenticated: getAuthenticated(state),
  errors: getErrors(state),
});

export default withRouter(connect(mapStateToProps)(LogInForm));
