import React, { Component } from 'react';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';

import PropTypes from 'prop-types';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import DatePickerField from './DatePickerField';

export default class DatePickerGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'on',
      lastOn: '',
      lastBefore: '',
      lastAfter: '',
      lastRangeAfter: '',
      lastRangeBefore: '',
      currentValues: props.values,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { filterKeys: keys, values: next } = props;
    const { currentValues, activeTab } = state;

    const { [keys.before]: nextBefore, [keys.after]: nextAfter } = next;
    const { [keys.before]: currentBefore, [keys.after]: currentAfter } = currentValues;

    if (activeTab === 'range') {
      if (currentBefore && !currentAfter && !nextAfter) {
        return { activeTab: 'before', currentValues: next };
      }
      if (currentAfter && !currentBefore && !nextBefore) {
        return { activeTab: 'after', currentValues: next };
      }
    }
    return { currentValues: next };
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    const { setFieldValue, filterKeys, values } = this.props;

    // set last known value for our previously active tab
    if (activeTab === 'on') {
      this.setState({ lastOn: values[filterKeys.on] });
    } else if (activeTab === 'before') {
      this.setState({ lastBefore: values[filterKeys.before] });
    } else if (activeTab === 'after') {
      this.setState({ lastAfter: values[filterKeys.after] });
    } else if (activeTab === 'range') {
      this.setState({
        lastRangeBefore: values[filterKeys.before],
        lastRangeAfter: values[filterKeys.after],
      });
    }

    // reset values
    Object.values(filterKeys).forEach((key) => setFieldValue(key, ''));

    // set field value for newly opened tab
    const {
      lastOn, lastBefore, lastAfter, lastRangeAfter, lastRangeBefore,
    } = this.state;

    if (tab === 'on') {
      setFieldValue(filterKeys.on, lastOn);
    } else if (tab === 'before') {
      setFieldValue(filterKeys.before, lastBefore);
    } else if (tab === 'after') {
      setFieldValue(filterKeys.after, lastAfter);
    } else if (tab === 'range') {
      setFieldValue(filterKeys.before, lastRangeBefore);
      setFieldValue(filterKeys.after, lastRangeAfter);
    }

    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const {
      values, filterKeys, filterTypes, setFieldValue,
    } = this.props;
    const { activeTab } = this.state;

    return (
      <div className="tabs tabs--justify tabs--bordered-bottom">
        <div className="tabs__wrap">
          <Nav tabs className="filter-tabs">
            {
              filterTypes.map((filterType) => (
                <NavItem key={filterType}>
                  <NavLink
                    className={`filter-link${activeTab === filterType ? ' active' : ''}`}
                    onClick={() => this.toggle(filterType)}
                  >
                    {filterType}
                  </NavLink>
                </NavItem>
              ))
            }
          </Nav>
          {
            filterTypes.map((filterType) => {
              let picker;
              switch (filterType) {
                case 'on':
                  picker = (
                    <TabContent activeTab={activeTab} key={filterType}>
                      <TabPane tabId={filterType}>
                        <div className="form__form-group-field mb-3">
                          <DatePickerField
                            maxDate={Date.now()}
                            name={filterKeys.on}
                            value={values[filterKeys.on]}
                            onChange={setFieldValue}
                            placeholder="Select Date"
                          />
                          <div className="form__form-group-icon input-icon-right">
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  );
                  break;
                case 'before':
                  picker = (
                    <TabContent activeTab={activeTab} key={filterType}>
                      <TabPane tabId={filterType}>
                        <div className="form__form-group-field mb-3">
                          <DatePickerField
                            maxDate={Date.now()}
                            name={filterKeys.before}
                            value={values[filterKeys.before]}
                            onChange={setFieldValue}
                            placeholder="Select Before Date"
                          />
                          <div className="form__form-group-icon input-icon-right">
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  );
                  break;
                case 'after':
                  picker = (
                    <TabContent activeTab={activeTab} key={filterType}>
                      <TabPane tabId={filterType}>
                        <div className="form__form-group-field mb-3">
                          <DatePickerField
                            maxDate={Date.now()}
                            name={filterKeys.after}
                            value={values[filterKeys.after]}
                            onChange={setFieldValue}
                            placeholder="Select After Date"
                          />
                          <div className="form__form-group-icon input-icon-right">
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  );
                  break;
                case 'range':
                  picker = (
                    <TabContent activeTab={activeTab} key={filterType}>
                      <TabPane tabId={filterType}>
                        <div className="form__form-group-field mb-3">
                          <DatePickerField
                            maxDate={Date.now()}
                            name={filterKeys.after}
                            value={values[filterKeys.after]}
                            onChange={setFieldValue}
                            placeholder="Select After Date"
                          />
                          <div className="form__form-group-icon input-icon-right">
                            <CalendarBlankIcon />
                          </div>
                        </div>
                        <div className="form__form-group-field mb-3">
                          <DatePickerField
                            maxDate={Date.now()}
                            name={filterKeys.before}
                            value={values[filterKeys.before]}
                            onChange={setFieldValue}
                            placeholder="Select Before Date"
                          />
                          <div className="form__form-group-icon input-icon-right">
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  );
                  break;
                // no default
              }
              return picker;
            })
          }
        </div>
      </div>
    );
  }
}

DatePickerGroup.propTypes = {
  values: PropTypes.shape({
    processedDate: PropTypes.string,
    processedAfterDate: PropTypes.string,
    processedBeforeDate: PropTypes.string,
  }).isRequired,
  filterTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterKeys: PropTypes.shape({
    on: PropTypes.string,
    before: PropTypes.string,
    after: PropTypes.string,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
