import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { ErrorMessage, connect } from 'formik';

class SelectField extends React.Component {
  handleChange = (value) => {
    const updatedVal = value ? value.value : '';
    const { formik: { setFieldValue }, fieldName } = this.props;
    setFieldValue(fieldName, updatedVal);
  }

  handleBlur = () => {
    const { formik: { setFieldTouched }, fieldName } = this.props;
    setFieldTouched(fieldName, true);
  }

  setValue = () => {
    const { formik: { values }, options, fieldName } = this.props;
    const splitFieldName = fieldName.split('.');
    let value;
    splitFieldName.forEach((n) => { value = (value || values)[n]; });
    return options.find((v) => v.value === value) || null;
  }

  orderedOptions = (options) => (
    options.sort((a, b) => {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();
      if (labelA < labelB) return -1;
      if (labelA > labelB) return 1;
      return 0;
    })
  )

  render() {
    const {
      multiSelect,
      options,
      fieldName,
      placeholder,
      label,
      sorted,
      isDisabled,
    } = this.props;

    const optionsList = sorted ? this.orderedOptions(options) : options;
    const customStyles = {
      input: () => ({
        paddingBottom: 7,
      }),
    };

    return (
      <div className="form__form-group" style={{ width: '100%' }}>
        { label && <span className="form__form-group-label">{label}</span> }
        <div className="form__form-group-field">
          <Select
            isDisabled={isDisabled}
            id={`${fieldName}-select`}
            options={optionsList}
            multi={multiSelect}
            placeholder={placeholder}
            isClearable
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            value={this.setValue()}
            className="react-select"
            classNamePrefix="react-select"
            styles={customStyles}
          />
        </div>
        <ErrorMessage
          name={fieldName}
          component="div"
          className="login__error"
          style={{ marginTop: '6px' }}
        />
      </div>
    );
  }
}

SelectField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiSelect: PropTypes.bool,
  sorted: PropTypes.bool,
  /* eslint-disable-next-line */
  formik: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType(
        [
          PropTypes.number,
          PropTypes.string,
        ],
      ),
      label: PropTypes.string,
    }),
  ).isRequired,
};

SelectField.defaultProps = {
  multiSelect: false,
  placeholder: '',
  label: '',
  sorted: true,
  isDisabled: false,
};

export default connect(SelectField);
