import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import FormSectionWrapper from '../../shared/components/form/FormSectionWrapper';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
});

const Form = () => (
  <Container>
    <ShowContainer>
      {
        ({ id }) => {
          if (!id) return null;
          const initialValues = { id };
          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/acknowledge_updates`}
              formTitle="Acknowledge Updated Shipment Confirmation"
              formSubTitle={`Please confirm that you would like to acknowledge updates to Shipment #${id}`}
              successModalTitle="Shipment Updated!"
              successModalMessage="You'll be sent back to the Shipments index page"
              submitButtonText="Confirm Acknowledge"
              successUrl="/orders/sales-shipments"
            >
              <FormSectionWrapper>
                <span className="ml-3">
                  <h5>This will also acknowledge updates on the following shipments:</h5>
                </span>
              </FormSectionWrapper>
            </FormWrapper>
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Form));
