import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Col } from 'reactstrap';
import FormWrapper from '../../shared/components/form/FormWrapper';
import FormSectionWrapper from '../../shared/components/form/FormSectionWrapper';
import ExcelUpload from '../../shared/components/form/ExcelUpload';
import { getFilterables } from '../../redux/reducers/authReducer';
import randInt from '../../helpers/randInt';
import { humanizeSnakeCase } from '../../helpers/convertKeys';

const validationSchema = Yup.object().shape({
  fileName: Yup.string().required('Required'),
});

class Form extends Component {
  mapData = (ordersArr) => {
    const { filterables: { carrierServices: services } } = this.props;
    const codes = services.map((s) => s.serviceCode);
    return ordersArr.map((ord) => {
      const code = codes.includes(ord.CarrierServiceCode) && ord.CarrierServiceCode;
      return (
        {
          ...ord,
          Name: ord.Name,
          CarrierServiceCode: code,
          LineItems: JSON.parse(ord.LineItems),
        }
      );
    });
  }

  errorMapper = (errors) => {
    // Pull al indexes, sort numerically, and use Set to remove duplicates
    const indexes = [...new Set(errors.map((e) => e.form_index).sort((a, b) => a - b))];
    return indexes.map((i) => (
      <div key={`error-${randInt()}`}>
        <p><b className="mr-1 ml-2">Row {i}:</b></p>
        {
          errors.map((e) => (
            e.form_index === i && (
              <p className="ml-3" key={randInt()}>
                {humanizeSnakeCase(e.field)}:
                <span className="ml-2">{e.message}</span>
              </p>
            )
          ))
        }
      </div>
    ));
  };

  render() {
    return (
      <Container>
        <FormWrapper
          initialValues={{ fileName: '', orders: [] }}
          validationSchema={validationSchema}
          formTitle="Batch Orders Form"
          formSubTitle="Upload Batch Orders File Here"
          successModalTitle="Orders Added!"
          successModalMessage="You'll be sent back to the Batch Orders index page"
          submitButtonText="Add Orders"
          successUrl="/orders/batch-sales-orders"
          customErrorMapper={this.errorMapper}
        >
          <FormSectionWrapper>
            <Col md={6}>
              <ExcelUpload
                fileNameFieldName="fileName"
                fileDataFieldName="orders"
                mapData={this.mapData}
              />
            </Col>
          </FormSectionWrapper>
        </FormWrapper>
      </Container>
    );
  }
}

Form.propTypes = {
  filterables: PropTypes.shape({
    carrierServices: PropTypes.array,
    shipmentProfiles: PropTypes.array,
  }),
};

Form.defaultProps = {
  filterables: {
    carrierServices: [],
    shipmentProfiles: [],
  },
};

const mapStateToProps = (state) => ({
  filterables: getFilterables(state),
});


export default withRouter(connect(mapStateToProps)(Form));
