export const isFullyAssigned = (shipment) => {
  const list = [];
  shipment.shipmentRequests.forEach((sr) => {
    sr.assignments.forEach((asgn) => {
      list.push(asgn.contentIdentifiableId);
    });
  });
  return !list.some((e) => !e);
};

export const formatStatus = (status) => {
  let desc;
  switch (status) {
    case 'fulfilled':
      desc = 'Fulfilled';
      break;
    case 'canceled':
      desc = 'Canceled';
      break;
    case 'processed':
      desc = 'Processed';
      break;
    case 'unprocessed':
      desc = 'Not Processed';
      break;
    case 'processed_incomplete':
      desc = 'Processed Incomplete';
      break;
    case 'unfulfilled':
      desc = 'Not Fulfilled';
      break;
      // no default
  }
  return desc;
};
