import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';

const FormSectionWrapper = ({ children }) => (
  <div
    className="customizer__form-group-wrap no-bottom-line col-md-12"
    style={{ width: '100%' }}
  >
    <div className="mx-3">
      <Row style={{ width: '100%' }}>
        {children}
      </Row>
    </div>
  </div>
);

FormSectionWrapper.propTypes = {
  children: PropTypes.oneOfType(
    [
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.element,
    ],
  ).isRequired,
};

export default FormSectionWrapper;
