import React from 'react';

const LoadingAnimation = () => (
  <div className="loading">
    <div className="loader">
      <div className="line" />
      <div className="line" />
      <div className="line" />
      <div className="line" />
    </div>
  </div>
);

export default LoadingAnimation;
