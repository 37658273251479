import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const WithTrackingNumber = ({ shippingLabel, trackingNumber, id }) => (
  Object.keys(shippingLabel).length > 0
    ? (
      <div>
        <b className="mr-4">Tracking Number</b>
        {trackingNumber}
        <span className="mx-2">|</span>
        <Link className="ml-1" to={`/orders/sales-shipments/${id}/remove-tracking`}>
          Drop
        </Link>
        <span className="mx-2">|</span>
        <Link className="ml-1" to={`/orders/sales-shipments/${id}/print-label`}>
          Print Label
        </Link>
      </div>
    )
    : (
      <div>
        <b className="mr-4">Tracking Number</b>
        {trackingNumber}
        <Link className="ml-1" to={`/orders/sales-shipments/${id}/remove-tracking`}>
          <b>x</b>
        </Link>
      </div>
    )
);

WithTrackingNumber.propTypes = {
  id: PropTypes.number.isRequired,
  shippingLabel: PropTypes.shape().isRequired,
  trackingNumber: PropTypes.string,
};

WithTrackingNumber.defaultProps = {
  trackingNumber: null,
};

const TrackingNumber = ({ trackingNumber, shippingLabel, id }) => (
  trackingNumber
    ? (
      <WithTrackingNumber
        shippingLabel={shippingLabel}
        trackingNumber={trackingNumber}
        id={id}
      />
    )
    : (
      <div>
        <b className="mr-4">Tracking Number</b>
        <Link to={`/orders/sales-shipments/${id}/add-tracking`}>
          Add
        </Link>
        <span className="mx-2">|</span>
        <Link to={`/orders/sales-shipments/${id}/create-label`}>
          Create Label
        </Link>
      </div>
    )
);

TrackingNumber.propTypes = {
  id: PropTypes.number,
  shippingLabel: PropTypes.shape().isRequired,
  trackingNumber: PropTypes.string,
};

TrackingNumber.defaultProps = {
  id: null,
  trackingNumber: null,
};

export default TrackingNumber;
