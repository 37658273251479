import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { sortNumericallyBy } from '../../../helpers/resourceItem';
import randInt from '../../../helpers/randInt';

const KitsInformation = ({ resourceitem: { kits } }) => {
  const sorted = sortNumericallyBy(kits, 'id');
  const display = sorted.map((kit, index) => {
    const { id, ssid } = kit;
    let desc;
    if (ssid) {
      desc = (
        <div>
          Kit ID: {id}
          <span className="ml-4">
            SSID: {ssid}
          </span>
        </div>
      );
    } else {
      desc = `Kit ID: ${id}`;
    }

    return (
      <tr key={randInt()}>
        <th style={{ width: '22px' }}>{index + 1}) </th>
        <td>{desc}</td>
      </tr>
    );
  });

  const halfzies = Math.ceil(display.length / 2);
  const shouldSplit = display.length > 5;
  const leftSide = shouldSplit ? display.splice(0, halfzies) : display;

  return (
    <Row>
      <Col md={12}>
        <h3 className="page-title mb-0">
          Built Kits
        </h3>
      </Col>
      <Col md={shouldSplit ? 6 : 12}>
        <div className="project-summary">
          <table className="project-summary__info ml-2 mb-0">
            <tbody>
              {leftSide}
            </tbody>
          </table>
        </div>
      </Col>
      {
        shouldSplit
          ? (
            <Col md={6}>
              <div className="project-summary">
                <table className="project-summary__info ml-2">
                  <tbody>
                    {display}
                  </tbody>
                </table>
              </div>
            </Col>
          )
          : null
      }
    </Row>
  );
};

KitsInformation.propTypes = {
  resourceitem: PropTypes.shape({
    id: PropTypes.number,
    kits: PropTypes.arrayOf(PropTypes.object),
  }),
};

KitsInformation.defaultProps = {
  resourceitem: {},
};

export default KitsInformation;
