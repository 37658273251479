import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';

const SnVariantExpanded = ({ status, trackingNumber, assignments }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <>
      {
        assignments.map((asgn, index) => {
          const { id: asgnId, contentIdentifiableId, returnedData } = asgn;
          return (
            <div key={`asgn${asgnId}`} className="ml-4">
              <span className="mr-1">
                { `Device ${index + 1}: `}
              </span>
              { contentIdentifiableId }
              {
                contentIdentifiableId && status === 'unprocessed'
                && (
                  <Link className="ml-1" to={`/orders/assignments/${asgnId}/remove`}>
                    <b>x</b>
                  </Link>
                )
              }
              {
                !contentIdentifiableId && trackingNumber && status === 'unprocessed'
                  && (
                    <Link to={`/orders/assignments/${asgnId}/assign-device`}>
                      Assign
                    </Link>
                  )
              }
              {
                !contentIdentifiableId && !trackingNumber && status === 'unprocessed'
                  && (
                    <span>
                      <span className="link-text" id={`Tooltip-${asgnId}`}>Assign</span>
                      <Tooltip
                        placement="right"
                        target={`Tooltip-${asgnId}`}
                        isOpen={tooltipOpen}
                        toggle={toggle}
                      >
                        Add Tracking First!
                      </Tooltip>
                    </span>
                  )
               }
              {
                contentIdentifiableId
                && (
                  <div className="ml-4">
                    <div>
                      <span className="mr-2">
                        To Container:
                      </span>
                      {returnedData.returnToContainer}
                    </div>
                    <div>
                      <span className="mr-2">
                        To Stock:
                      </span>
                      {returnedData.returnToStock}
                    </div>
                  </div>
                )
              }
            </div>
          );
        })
      }
    </>
  );
};

SnVariantExpanded.propTypes = {
  assignments: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  status: PropTypes.string.isRequired,
  trackingNumber: PropTypes.string,
};

SnVariantExpanded.defaultProps = {
  trackingNumber: null,
};

export default SnVariantExpanded;
