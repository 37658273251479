import React from 'react';
import PaginationInfo from './PaginationInfo';
import IndexTableContext from './IndexTableContext';

const totalLength = (totalPages) => (
  totalPages < 5 ? totalPages : 5
);

const startPage = (page, totalPages) => {
  let start = page - Math.floor(totalLength(totalPages) / 2);
  start = Math.max(start, 1);
  start = Math.min(start, 1 + totalPages - totalLength(totalPages));
  return start;
};

const pageRange = (page, totalPages) => (
  Array.from([...Array(totalLength(totalPages))
    .keys()], (_, i) => (startPage(page, totalPages) + i))
    .filter((value) => !Number.isNaN(value))
);

const Pagination = () => (
  <IndexTableContext.Consumer>
    {
      ({
        history,
        updatePageAndSearch,
        searchMeta: { page, totalPages },
      }) => (
        totalLength(totalPages) > 1
        && (
          <div className="d-flex justify-content-between mr-5 pagination__bottom-wrap">
            <PaginationInfo />
            <div className="pagination__wrap mr-5">
              <nav className="pagination" aria-label="pagination">
                <ul className="pagination">
                  <li className={`pagination__item page-item ${page === 1 && 'disabled'}`}>
                    <button
                      type="button"
                      className="pagination__link pagination__link--arrow page-link"
                      onClick={() => (page !== 1 && updatePageAndSearch((page - 1), history))}
                    >
                      <svg
                        className="mdi-icon pagination__link-icon"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                      </svg>
                    </button>
                  </li>
                  {
                    pageRange(page, totalPages).map((num) => {
                      const currentPage = page === num;
                      return (
                        <li
                          className={`pagination__item page-item ${currentPage ? 'active' : ''}`}
                          key={`pagination${num}`}
                        >
                          <button
                            type="button"
                            className="pagination__link page-link"
                            onClick={() => {
                              if (!currentPage) {
                                updatePageAndSearch(num, history);
                              }
                            }}
                          >
                            {num}
                          </button>
                        </li>
                      );
                    })
                  }
                  <li className={`pagination__item page-item ${page === totalPages && 'disabled'}`}>
                    <button
                      type="button"
                      className="pagination__link pagination__link--arrow page-link"
                      onClick={() => (page !== totalPages && updatePageAndSearch((page + 1), history))}
                    >
                      <svg
                        className="mdi-icon pagination__link-icon"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                      </svg>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )
      )
    }
  </IndexTableContext.Consumer>
);

export default Pagination;
