import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import TextField from '../../shared/components/form/TextField';

class Form extends Component {
  handleValidate = (values) => {
    const errors = {};
    if (!values.id) errors.id = 'Required';
    if (!values.kdvId) errors.kdvId = 'Required';
    if (!values.serialNumber) errors.serialNumber = 'Required';
    return errors;
  }

  render() {
    return (
      <Container>
        <ShowContainer>
          {
            ({ id }) => {
              const { match: { params: { kdvId } } } = this.props;

              if (!id || !kdvId) return null;
              const initialValues = { id, kdvId, serialNumber: '' };

              return (
                <FormWrapper
                  initialValues={initialValues}
                  onValidate={this.handleValidate}
                  formUrlSuffix={`/${id}/add_device`}
                  formTitle="Kits Add Device Form"
                  formSubTitle={`Please add relevant information to add a Device to Kit #${id}`}
                  successModalTitle="Device Added To Kit!"
                  successModalMessage={`You will be redirected back to the Kits #${id} page`}
                  submitButtonText="Add Device"
                  successUrl={`/inventory/kits/${id}`}
                >
                  <div
                    className="customizer__form-group-wrap no-bottom-line col-md-12"
                    style={{ width: '100%' }}
                  >
                    <div className="mx-3">
                      <Row style={{ width: '100%' }}>
                        <Col lg={6}>
                          <div className="form__form-group">
                            <TextField
                              fieldName="serialNumber"
                              label="Device Serial Number"
                              placeholder="Device Serial Number..."
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </FormWrapper>
              );
            }
          }
        </ShowContainer>
      </Container>
    );
  }
}

Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};

export default withRouter(connect()(Form));
