import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { FieldArray } from 'formik';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import TextField from '../../shared/components/form/TextField';

const Form = ({ match: { params: { key } } }) => (
  <Container>
    <ShowContainer>
      {
        ({ id, returnedData }) => {
          if (!id) return null;
          const initialValues = {
            id,
            kitKey: key,
            variantQuantities: returnedData[key].map((vq, index) => (
              {
                formId: index,
                variantId: vq.variantId,
                quantity: vq.quantity,
                returnedQuantity: vq.returnedQuantity,
                restockableQuantity: vq.restockableQuantity,
                itemName: vq.itemName,
              }
            )),
          };

          const validationSchema = Yup.object().shape({
            id: Yup.number().required('Required'),
            variantQuantities: Yup.array().of(
              Yup.object().shape({
                quantity: Yup.number()
                  .required('Required')
                  .integer('Must be whole number')
                  .moreThan(0, 'Must be at least 1')
                  .typeError('Enter a valid number'),
                returnedQuantity: Yup.number()
                  .required('Required')
                  .integer('Must be whole number')
                  .moreThan(-1, 'Must be positive')
                  // .lessThan(quantity + 1, `Must be less than or equal to ${quantity}`)
                  .typeError('Enter a valid number'),
                restockableQuantity: Yup.number()
                  .required('Required')
                  .integer('Must be whole number')
                  .moreThan(-1, 'Must be positive')
                  .test('less than returned', 'Must be less than or equal to Returned Quantity',
                    function test() {
                      // eslint-disable-next-line
                      const returned = this.parent.returnedQuantity;
                      // eslint-disable-next-line
                      const restockable = this.parent.restockableQuantity;
                      return restockable <= returned;
                    })
                  .typeError('Enter a valid number'),
              }),
            ),
          });

          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/update_returned_data`}
              formTitle="Update Returned Data"
              formSubTitle="Please fill out the relevant information to update the line item for this shipment"
              successModalTitle="Line Item Updated!"
              successModalMessage="You will be redirected back to the Return Shipments index page"
              submitButtonText="Update Return Data"
              successUrl="/orders/return-shipments"
            >
              <div className="customizer__form-group-wrap no-bottom-line" style={{ width: '100%' }}>
                <div className="mx-3">
                  <FieldArray name="variantQuantities">
                    <>
                      {
                        returnedData[key].map((vq, i) => (
                          <div key={vq.variantId} className="form__form-group">
                            <h4 className="form__form-group-label">{vq.itemName}</h4>
                            <Row key={vq.variantId} style={{ width: '100%' }}>
                              <Col md={4}>
                                <TextField
                                  label="Expected Quantity"
                                  fieldName={`variantQuantities.${i}.quantity`}
                                  disabled
                                />
                              </Col>
                              <Col md={4}>
                                <TextField
                                  label="Returned Quantity"
                                  fieldName={`variantQuantities.${i}.returnedQuantity`}
                                  placeholder="Returned Quantity..."
                                />
                              </Col>
                              <Col md={4}>
                                <TextField
                                  label="Restockable Quantity"
                                  fieldName={`variantQuantities.${i}.restockableQuantity`}
                                  placeholder="Restockable Quantity..."
                                />
                              </Col>
                            </Row>
                          </div>
                        ))
                      }
                    </>
                  </FieldArray>
                </div>
              </div>
            </FormWrapper>
          );
        }
      }
    </ShowContainer>
  </Container>
);

Form.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
};

export default withRouter(connect()(Form));
