import React from 'react';
import { Col, Row } from 'reactstrap';
import IndexTableContext from './IndexTableContext';

const PageHeader = () => (
  <IndexTableContext.Consumer>
    {
      ({ pageTitle }) => (
        <Row>
          <Col xs={12}>
            <h3 className="page-title">{pageTitle}</h3>
          </Col>
        </Row>
      )
    }
  </IndexTableContext.Consumer>
);

export default PageHeader;
