import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { formatDescriptionArrayToString, formatStockType } from '../../helpers/resourceItem';

class DevicesIndexContainer extends Component {
  createRows = (list) => (
    list.map((device) => {
      const {
        serialNumber,
        itemName,
        status,
        container,
      } = device;

      return (
        <tr key={serialNumber}>
          <td>{serialNumber}</td>
          <td>{itemName}</td>
          <td>{formatStockType(status)}</td>
          <td>{container}</td>
          <td><Link to={`/inventory/devices/${serialNumber}/edit`}>Edit</Link></td>
        </tr>
      );
    })
  );

  buildBadgeList = (filter, defaultFilter) => {
    const {
      container,
      itemId,
      search,
      status,
    } = filter;

    const {
      container: defaultContainer,
      item_id: defaultItemId,
      search: defaultSearch,
      status: defaultStatus,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('container'):
          if (container && container !== defaultContainer) {
            badgeList.push(
              {
                order: 3,
                title: 'Container ID',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('itemId'):
          if (itemId && itemId !== defaultItemId) {
            badgeList.push(
              {
                order: 4,
                title: 'Item Type',
                descFilterable: 'serialItems',
                filterKey: key,
              },
            );
          }
          break;
        case ('status'):
          if (status && status !== defaultStatus) {
            const baseDesc = filter[key];
            const builtDesc = [];
            if (baseDesc.includes('a_stock') && baseDesc.includes('a_stock_assigned')) {
              builtDesc.push('A-Stock(with Assigned)');
            } else if (baseDesc.includes('a_stock') && !baseDesc.includes('a_stock_assigned')) {
              builtDesc.push('A-Stock(w/o Assigned)');
            } else if (!baseDesc.includes('a_stock') && baseDesc.includes('a_stock_assigned')) {
              builtDesc.push('A-Stock(only Assigned)');
            }

            if (baseDesc.includes('b_stock') && baseDesc.includes('b_stock_assigned')) {
              builtDesc.push('B-Stock(with Assigned)');
            } else if (baseDesc.includes('b_stock') && !baseDesc.includes('b_stock_assigned')) {
              builtDesc.push('B-Stock(w/o Assigned)');
            } else if (!baseDesc.includes('b_stock') && baseDesc.includes('b_stock_assigned')) {
              builtDesc.push('B-Stock(only Assigned)');
            }

            if (baseDesc.includes('defective_stock')) {
              builtDesc.push('Defective');
            }

            if (baseDesc.includes('field_stock')) {
              builtDesc.push('Field');
            }

            if (baseDesc.includes('sunset_stock')) {
              builtDesc.push('Sunset');
            }

            const desc = formatDescriptionArrayToString(builtDesc);
            badgeList.push(
              {
                order: 2,
                title: 'Stock Types',
                desc,
                filterKey: key,
              },
            );
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search Serial Number',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  }

  render() {
    return (
      <IndexTableContainer
        pageTitle="Inventory Devices"
        searchPlaceholder="Search by Serial Number..."
        heads={
          [
            { key: 'serialNumber', name: 'Serial Number' },
            { key: 'itemName', name: 'Item Type' },
            { key: 'status', name: 'Status' },
            { key: 'containerId', name: 'Container' },
            { key: 'edit', name: 'Edit' },
          ]
        }
        filterForm={
          [
            {
              type: 'CheckBoxGroup',
              groupLabel: 'Stock Types',
              filterKey: 'status',
              options: [
                { value: 'a_stock', label: 'A-Stock' },
                { value: 'a_stock_assigned', label: 'A-Stock Assigned' },
                { value: 'b_stock', label: 'B-Stock' },
                { value: 'b_stock_assigned', label: 'B-Stock Assigned' },
                { value: 'defective_stock', label: 'Defective Stock' },
                { value: 'field_stock', label: 'Field Stock' },
                { value: 'sunset_stock', label: 'Sunset Stock' },
              ],
            },
            {
              type: 'Text',
              groupLabel: 'Container ID',
              filterKey: 'container',
              placeholder: 'Search by Container ID...',
            },
            {
              type: 'Select',
              groupLabel: 'Item Type',
              filterKey: 'itemId',
              placeholder: 'Filter by Item Type...',
              optionsKey: 'serialItems',
            },
          ]
        }
        createRows={this.createRows}
        buildBadgeList={this.buildBadgeList}
      />
    );
  }
}

export default DevicesIndexContainer;
