import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { formatReceivingStatus } from '../../helpers/resourceItem';

class ReceivingShipmentsIndexContainer extends Component {
  createRows = (list) => (
    list.map((receivingShipment) => {
      const {
        id,
        vendorOrderId,
        itemName,
        actualQuantity,
        expectedQuantity,
        status,
        arrivalDate,
        itemType,
      } = receivingShipment;

      let displayDevices;
      let displayArrived;
      let displayProcessed;
      if (status === 'unprocessed' && itemType === 'serial_number') {
        displayDevices = (
          <Link to={`receiving-shipments/${id}/devices`}>Add Devices</Link>
        );
      }

      if (status === 'unprocessed' && !arrivalDate) {
        displayArrived = (
          <Link to={`receiving-shipments/${id}/mark-as-arrived`}>Mark As Arrived</Link>
        );
      }

      if (status === 'unprocessed' && arrivalDate) {
        displayProcessed = (
          <Link to={`receiving-shipments/${id}/mark-as-processed`}>Mark As Processed</Link>
        );
      }

      return (
        <tr key={id}>
          <td>{id}</td>
          <td>{vendorOrderId}</td>
          <td>{itemName}</td>
          <td>{actualQuantity || '0'} / {expectedQuantity}</td>
          <td>{formatReceivingStatus(status)}</td>
          <td>
            <Link to={`receiving-shipments/${id}`}>Info</Link>
            { displayDevices && <span className="mx-2">|</span> }
            { displayDevices }
            { displayArrived && <span className="mx-2">|</span> }
            { displayArrived }
            { displayProcessed && <span className="mx-2">|</span> }
            { displayProcessed }
          </td>
        </tr>
      );
    })
  );

  buildBadgeList = (filter, defaultFilter) => {
    const {
      itemId,
      search,
      status,
      vendorOrderId,
    } = filter;
    const {
      itemId: defaultItemId,
      search: defaultSearch,
      status: defaultStatus,
      vendorOrderId: defaultReceivingOrderId,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('itemId'):
          if (itemId && itemId !== defaultItemId) {
            badgeList.push(
              {
                order: 4,
                title: 'Item Type',
                descFilterable: 'items',
                filterKey: key,
              },
            );
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('status'):
          if (status && status !== defaultStatus) {
            const desc = filter[key].join(', ').replace('unprocessed', 'not processed');
            badgeList.push(
              {
                order: 2,
                title: 'Status',
                desc,
                filterKey: key,
              },
            );
          }
          break;
        case ('vendorOrderId'):
          if (vendorOrderId && vendorOrderId !== defaultReceivingOrderId) {
            badgeList.push(
              {
                order: 3,
                title: 'Receiving Order ID',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  }

  render() {
    return (
      <IndexTableContainer
        pageTitle="Receiving Shipments"
        searchPlaceholder="Search by ID or Receiving..."
        heads={
          [
            { key: 'id', name: 'ID' },
            { key: 'vendorOrderId', name: 'Receiving Order ID' },
            { key: 'itemName', name: 'Item' },
            { key: 'quantity', name: 'Qty Expected / Actual' },
            { key: 'status', name: 'Status' },
            { key: 'information', name: 'Info' },
          ]
        }
        filterForm={
          [
            {
              type: 'CheckBoxGroup',
              groupLabel: 'Status',
              filterKey: 'status',
              options: [
                { value: 'processed', label: 'Processed' },
                { value: 'unprocessed', label: 'Not Processed' },
                { value: 'canceled', label: 'Canceled' },
              ],
            },
            {
              type: 'Text',
              groupLabel: 'Receiving Order Search',
              filterKey: 'vendorOrderId',
              placeholder: 'Receiving Order ID...',
            },
            {
              type: 'Select',
              groupLabel: 'Item Type',
              filterKey: 'itemId',
              placeholder: 'Filter by Item Type...',
              optionsKey: 'items',
            },
          ]
        }
        createRows={this.createRows}
        buildBadgeList={this.buildBadgeList}
      />
    );
  }
}

export default ReceivingShipmentsIndexContainer;
