import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { formatRange, formatDescriptionArrayToString, formatBatchStatus } from '../../helpers/resourceItem';
import printLabels from '../../helpers/batchHelper';
import '../../helpers/dymo';

const DisabledLink = ({ id }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <span>
      <span className="link-text" id={`Tooltip-${id}`}>Print Labels</span>
      <Tooltip
        placement="right"
        target={`Tooltip-${id}`}
        isOpen={tooltipOpen}
        toggle={toggle}
      >
        Printer Not Found
      </Tooltip>
    </span>
  );
};

DisabledLink.propTypes = {
  id: PropTypes.number.isRequired,
};

class BatchsIndexContainer extends Component {
  formatIdRange = (kitIds, kitSsids) => {
    if (kitSsids && kitSsids.length > 0) {
      return (
        <td>
          <div>ID {formatRange(kitIds)}</div>
          <div>SSID {formatRange(kitSsids)}</div>
        </td>
      );
    }
    return <td>ID {formatRange(kitIds)}</td>;
  };

  createRows = (list) => {
    // eslint-disable-next-line
    const { framework } = dymo.label;
    const printers = framework.getPrinters();
    const { length } = printers;
    return (
      list.map((item) => {
        const {
          orderSize,
          id,
          status,
          kitIds,
          kitSsids,
          buildListName: type,
        } = item;
        let action;
        if (status === 'not_started') {
          action = (
            <Link to={`/inventory/batches/${id}/mark-as-in-progress`}>
              Mark As In Progress
            </Link>
          );
        } else if (status === 'in_progress' || status === 'in_progress_fully_scanned') {
          action = (
            <Link to={`/inventory/batches/${id}/devices`}>
              Devices
            </Link>
          );
        }
        let secondaryAction;
        if (status === 'in_progress_fully_scanned') {
          secondaryAction = (
            <Link to={`/inventory/batches/${id}/mark-as-completed`}>
              Mark As Completed
            </Link>
          );
        }

        return (
          <tr key={id}>
            <td>{type}</td>
            <td>{id}</td>
            <td>{formatBatchStatus(status)}</td>
            <td>{orderSize}</td>
            {this.formatIdRange(kitIds, kitSsids)}
            <td>
              <Link to={`/inventory/batches/${id}`}>Show</Link>
              {action && <span className="mx-2">|</span>}
              {action }
              {secondaryAction && <span className="mx-2">|</span>}
              {secondaryAction}
              <span className="mx-2">|</span>
              {
                framework
                && length !== 0
                  ? (
                    <span
                      role="link"
                      className="link-text"
                      onClick={() => printLabels(framework, item)}
                      onKeyDown={() => {}}
                      tabIndex={0}
                    >
                      Print Labels
                    </span>
                  )
                  : <DisabledLink id={id} />
              }
            </td>
          </tr>
        );
      })
    );
  };

  buildBadgeList = (filter, defaultFilter) => {
    const {
      buildListId,
      search,
      status,
    } = filter;
    const {
      buildListId: defaultbuildListId,
      search: defaultSearch,
      status: defaultStatus,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('buildListId'):
          if (buildListId && buildListId !== defaultbuildListId) {
            badgeList.push(
              {
                order: 3,
                title: 'Type',
                descFilterable: 'bundles',
                filterKey: key,
              },
            );
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('status'):
          if (status && status !== defaultStatus) {
            const baseDesc = filter[key];
            const builtDesc = [];
            if (baseDesc.includes('planned')) builtDesc.push('Planned');
            if (baseDesc.includes('in_progress')) builtDesc.push('In Progress');
            if (baseDesc.includes('completed')) builtDesc.push('Completed');
            const desc = formatDescriptionArrayToString(builtDesc);
            badgeList.push(
              {
                order: 2,
                title: 'Stock Types',
                desc,
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });
    return badgeList;
  }

  render() {
    return (
      <IndexTableContainer
        newButton
        newButtonText="New Batch"
        newFormUri="batches/new"
        pageTitle="Batches"
        searchPlaceholder="Search by ID..."
        heads={
          [
            { key: 'type', name: 'Type' },
            { key: 'id', name: 'ID' },
            { key: 'status', name: 'Status' },
            { key: 'orderSize', name: 'Order Size' },
            { key: 'idRange', name: 'ID Range' },
            { key: 'actions', name: 'Actions' },
          ]
        }
        filterForm={
          [
            {
              type: 'CheckBoxGroup',
              groupLabel: 'Status',
              filterKey: 'status',
              options: [
                { value: 'not_started', label: 'Planned' },
                { value: 'in_progress', label: 'In Progress' },
                { value: 'in_progress_fully_scanned', label: 'Fully Scanned' },
                { value: 'completed', label: 'Completed' },
              ],
            },
            {
              type: 'Select',
              groupLabel: 'Bundle Type',
              filterKey: 'buildListId',
              placeholder: 'Filter by Bundle...',
              optionsKey: 'prebuiltBundles',
            },
          ]
        }
        createRows={this.createRows}
        buildBadgeList={this.buildBadgeList}
      />
    );
  }
}

export default BatchsIndexContainer;
