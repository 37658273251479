import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const SidebarLink = ({
  title, icon, newLink, route, onClick, display,
}) => {
  if (!display) return null;

  return (
    <NavLink
      to={route}
      onClick={onClick}
      activeClassName="sidebar__link-active"
    >
      <li className="sidebar__link">
        <span className="sidebar__link-icon">
          {icon}
        </span>
        <p className="sidebar__link-title">
          {title}
          {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
        </p>
      </li>
    </NavLink>
  );
};

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
  display: PropTypes.bool.isRequired,
};

SidebarLink.defaultProps = {
  icon: null,
  newLink: false,
  route: '/',
  onClick: null,
};

export default SidebarLink;
