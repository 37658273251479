import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row, Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';

const DevicesInformation = ({ resourceitem: { id, devices, status } }) => (
  <Row>
    <Col md={12}>
      <h3 className="page-title mb-0">
        Devices List
      </h3>
      {
        status
        && status === 'unprocessed'
        && (
          <Link to={`/orders/receiving-shipments/${id}/devices`}>
            <Button
              className="project-summary__btn mr-4"
              outline
              size="sm"
            >
              Add Devices
            </Button>
          </Link>
        )
      }
    </Col>
    <Col md={12}>
      <Card className="pb-0">
        <CardBody className="pt-3 pb-0">
          <div className="project-summary">
            <Row>
              {
                devices && devices.map((d) => (
                  <Col sm={6} md={4} lg={3} key={d.serialNumber}>
                    <span className="page-text">
                      {d.serialNumber}
                    </span>
                  </Col>
                ))
              }
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

DevicesInformation.propTypes = {
  resourceitem: PropTypes.shape({
    status: PropTypes.string,
    receiving: PropTypes.string,
    id: PropTypes.number,
    devices: PropTypes.arrayOf(
      PropTypes.shape({
        serialNumber: PropTypes.string,
      }),
    ),
  }),
};

DevicesInformation.defaultProps = {
  resourceitem: {},
};

export default DevicesInformation;
