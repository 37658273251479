import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container, Col } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import FormSectionWrapper from '../../shared/components/form/FormSectionWrapper';
import TextField from '../../shared/components/form/TextField';
import SelectField from '../../shared/components/form/SelectFfield';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
  contentIdentifiableId: Yup.string().required('Required'),
  returnedKitAction: Yup.string().required('Required'),
});

const Form = () => (
  <Container>
    <ShowContainer>
      {
        ({
          id,
          contentableType,
          contentableName,
          shipmentId,
          defaultRestockAction,
        }) => {
          if (!id || !contentableType || !shipmentId) return null;
          const initialValues = {
            id,
            contentIdentifiableType: 'Kit',
            contentIdentifiableId: '',
            returnedKitAction: defaultRestockAction || 'sunset',
          };
          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/assign`}
              formTitle="Shipment Assignment Form"
              formSubTitle={`Add ${contentableName} Kit to Shipment #${shipmentId}`}
              successModalTitle="Kit Added!"
              successModalMessage="You'll be sent back to the Shipments index page"
              submitButtonText="Assign"
              successUrl="/orders/return-shipments"
            >
              <FormSectionWrapper>
                <Col md={6}>
                  <TextField
                    label="Kit ID"
                    placeholder="Kit ID..."
                    fieldName="contentIdentifiableId"
                  />
                </Col>
                <Col md={6}>
                  <SelectField
                    label="Return Action"
                    placeholder="Return Action..."
                    fieldName="returnedKitAction"
                    options={[
                      { label: 'Sunset', value: 'sunset' },
                      { label: 'Refurbish', value: 'refurbish' },
                    ]}
                  />
                </Col>
              </FormSectionWrapper>
            </FormWrapper>
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Form));
