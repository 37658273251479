import React from 'react';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import TextField from '../../shared/components/form/TextField';
import SelectField from '../../shared/components/form/SelectFfield';
import DatePicker from '../../shared/components/form/DatePickerFfield';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';

const validationSchema = Yup.object().shape({
  itemId: Yup.string().required('Required'),
  expectedQuantity: Yup.number()
    .required('Required')
    .integer('Must be whole number')
    .moreThan(0, 'Must be at least 1')
    .typeError('Enter a valid number'),
});

const Form = () => (
  <ShowContainer>
    {
      ({ id, lineItems }) => {
        if (!id) return null;
        const options = lineItems.map((li) => ({ label: li.name, value: li.itemId }));
        const initialId = options.length === 1 ? options[0].value : '';
        const initialValues = {
          expectedQuantity: '',
          trackingNumber: '',
          itemId: initialId,
          eta: '',
        };
        return (
          <FormWrapper
            initialValues={initialValues}
            validationSchema={validationSchema}
            formTitle="New Receiving Shipment Form"
            formSubTitle={`Use this form to create a new Receiving Shipment for Receiving Order ${id}`}
            successModalTitle="Receiving Shipment successfully created"
            successModalMessage={`You will be redirected back to the Receiving Orders #${id} page`}
            submitButtonText="Create"
            successUrl="/orders/receiving-orders"
            formUrlSuffix={`/${id}/vendor_shipments`}
          >
            <div className="customizer__form-group-wrap no-bottom-line col-md-12" style={{ width: '100%' }}>
              <div className="mx-3">
                <Row style={{ width: '100%' }}>
                  <Col lg={6}>
                    <TextField
                      label="Expected Quantity"
                      fieldName="expectedQuantity"
                      placeholder="Expected Quantity..."
                    />
                  </Col>
                  <Col lg={6}>
                    <SelectField
                      label="Stock Item"
                      fieldName="itemId"
                      placeholder="Select Incoming Item..."
                      options={options}
                    />
                  </Col>
                  <Col lg={6}>
                    <TextField
                      label="Tracking Number"
                      fieldName="eta"
                      placeholder="Tracking Number..."
                    />
                  </Col>
                  <Col lg={6}>
                    <DatePicker
                      label="ETA"
                      fieldName="trackingNumber"
                      placeholder="ETA..."
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </FormWrapper>
        );
      }
    }
  </ShowContainer>
);

export default withRouter(connect()(Form));
