import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { apiAction } from '../../redux/actions/apiActions';

const FetchDataHoc = ({
  dispatch,
  history: domHistory,
  children,
  fetchDataUrl,
}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    dispatch(
      apiAction(
        {
          method: 'GET',
          url: fetchDataUrl,
          onSuccess: (res) => {
            setData(res);
            return { type: 'FORM_REQUEST' };
          },
          onFailure: () => ({ type: 'FORM_REQUEST' }),
          domHistory,
        },
      ),
    );
  }, []);

  if (!data) return null;
  return <>{children(data)}</>;
};

FetchDataHoc.propTypes = {
  history: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  fetchDataUrl: PropTypes.string.isRequired,
};

export default withRouter(connect()(FetchDataHoc));
