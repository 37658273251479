import React from 'react';
import { Table } from 'reactstrap';
import IndexTableContext from './IndexTableContext';

const BorderedTable = () => (
  <IndexTableContext.Consumer>
    {
      ({ heads, rows, pendingRequest }) => (
        <Table className="table--bordered" responsive>
          <thead>
            <tr>
              {
                heads.map((header) => {
                  const { key, name } = header;
                  return <th key={key}>{name}</th>;
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              (pendingRequest || rows.length > 0)
                ? rows
                : (
                  <tr className="not-found-text">
                    <td>No results found with this filter</td>
                  </tr>
                )
            }
          </tbody>
        </Table>
      )
    }
  </IndexTableContext.Consumer>
);

export default BorderedTable;
