import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container, Col } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import FormSectionWrapper from '../../shared/components/form/FormSectionWrapper';
import TextField from '../../shared/components/form/TextField';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
  contentIdentifiableId: Yup.string().required('Required'),
});

const Form = () => (
  <Container>
    <ShowContainer>
      {
        ({
          id,
          contentableType,
          contentableName,
          shipmentId,
          shipmentFulfillmentType,
        }) => {
          if (!id || !contentableType || !shipmentId) return null;
          const ciType = contentableType === 'Bundle' ? 'Kit' : 'Device';
          const initialValues = {
            id,
            contentIdentifiableType: ciType,
            contentIdentifiableId: '',
          };
          const successResource = shipmentFulfillmentType === 'new_order' ? 'sales' : 'return';
          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/assign`}
              formTitle="Shipment Assignment Form"
              formSubTitle={`Add ${contentableName} ${ciType} to Shipment #${shipmentId}`}
              successModalTitle={`${ciType} Added!`}
              successModalMessage="You'll be sent back to the Shipments index page"
              submitButtonText="Assign"
              successUrl={`/orders/${successResource}-shipments`}
            >
              <FormSectionWrapper>
                <Col md={6}>
                  <TextField
                    label={ciType === 'Kit' ? 'Kit ID' : 'Serial Number'}
                    placeholder={`${ciType === 'Kit' ? 'Kit ID' : 'Serial Number'}...`}
                    fieldName="contentIdentifiableId"
                  />
                </Col>
              </FormSectionWrapper>
            </FormWrapper>
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Form));
