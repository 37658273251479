import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { sortAlphabeticallyBy } from '../../../helpers/resourceItem';
import randInt from '../../../helpers/randInt';

const ContentsInformation = ({ resourceitem: { kdvs, contents, id } }) => {
  const deviceIds = kdvs.map((kdv) => kdv.variantId);

  const deviceContents = sortAlphabeticallyBy(
    contents.filter((itm) => deviceIds.includes(itm.variantId)),
    'name',
  );

  const nonDeviceContents = sortAlphabeticallyBy(
    contents.filter((itm) => !deviceIds.includes(itm.variantId)),
    'name',
  );

  const displayDeviceContents = [];
  deviceContents.forEach((itm, index) => {
    const { name, quantity, variantId } = itm;
    displayDeviceContents.push(
      <tr key={randInt()}>
        <th>{index + 1}) </th>
        <td>Qty {quantity} - {name}</td>
      </tr>,
    );
    const variantKdvs = kdvs.filter((kdv) => kdv.variantId === variantId);
    variantKdvs.forEach((kdv) => {
      displayDeviceContents.push(
        <tr key={randInt()}>
          <th aria-label="serialNumberLabel" />
          <td>Serial Numbers:</td>
        </tr>,
        <tr key={randInt()}>
          <th aria-label="serialNumberList" />
          <td className="px-4">{kdv.serialNumber || 'Missing'}
            {
              kdv.serialNumber
                ? (
                  <Link className="ml-3" to={`/inventory/kits/${id}/replace-device/${kdv.serialNumber}`}>
                    Replace
                  </Link>
                )
                : (
                  <Link className="ml-3" to={`/inventory/kits/${id}/add-device/${kdv.id}`}>
                    { kdv.serialNumber ? 'Replace' : 'Add Device' }
                  </Link>
                )
            }
          </td>
        </tr>,
      );
    });
  });

  const displayNonDeviceContents = nonDeviceContents.map((itm, index) => {
    const { name, quantity } = itm;
    return (
      <tr key={randInt()}>
        <th>{index + 1 + deviceContents.length}) </th>
        <td>Qty {quantity} - {name}</td>
      </tr>
    );
  });
  return (
    <Row>
      <Col md={12}>
        <h3 className="page-title mb-0">
          Kit Contents
        </h3>
      </Col>
      <Col md={12}>
        <div className="project-summary">
          <Row>
            <Col lg={12}>
              <table className="project-summary__info ml-2">
                <tbody>
                  {displayDeviceContents}
                  {displayNonDeviceContents}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

ContentsInformation.propTypes = {
  resourceitem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    kdvs: PropTypes.arrayOf(PropTypes.object),
    contents: PropTypes.arrayOf(PropTypes.object),
  }),
};

ContentsInformation.defaultProps = {
  resourceitem: {
    kdvs: [],
    contents: [],
  },
};

export default ContentsInformation;
