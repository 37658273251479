import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

const DisabledLink = ({ id, userFlag }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  let action;
  switch (userFlag) {
    case 'hold':
      action = 'Shipment is on hold';
      break;
    case 'cancellation':
      action = 'Flagged for Cancellation';
      break;
    default:
      action = 'Updates Pending';
  }
  return (
    <span>
      <span className="link-text" id={`Tooltip-${id}`}>Assign</span>
      <Tooltip
        placement="right"
        target={`Tooltip-${id}`}
        isOpen={tooltipOpen}
        toggle={toggle}
      >
        {action}
      </Tooltip>
    </span>
  );
};

DisabledLink.propTypes = {
  id: PropTypes.number.isRequired,
  userFlag: PropTypes.number.isRequired,
};

export default DisabledLink;
