import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Row } from 'reactstrap';
import CarrierColumns from './CarrierColumns';
import ModalFormWrapper from '../../../../../../shared/components/form/ModalFormWrapper';
import { carrierToTitleCase } from './utils';
import FetchDataHoc from '../../../../../../shared/components/FetchDataHoc';
import setResourceData from '../../../../../../helpers/setResourceData';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
  carrierServiceCode: Yup.string().required('Required'),
});

const CarrierService = ({
  // eslint-disable-next-line react/prop-types
  orderSource, carrierServiceCode, id, history: domHistory,
}) => {
  const [visible, setVisible] = useState(false);
  const carrierName = carrierServiceCode ? carrierServiceCode.split('_')[0].toUpperCase() : '';
  const readableServiceCode = carrierServiceCode ? carrierToTitleCase(carrierServiceCode) : '';

  const resourceData = setResourceData(domHistory);
  const { resourceExtension } = resourceData;

  return (
    <>
      {
        visible && (
          <FetchDataHoc fetchDataUrl={`/${resourceExtension}/${id}/shipping_estimates`}>
            {
              (fetchedData) => (
                <ModalFormWrapper
                  visible
                  initialValues={{ id, carrierServiceCode }}
                  validationSchema={validationSchema}
                  formUrlSuffix={`/${id}/set_carrier_service`}
                  formTitle="Shipment Carrier Service Form"
                  successModalTitle="Carrier Service Updated!"
                  successModalMessage="This page will be reloaded!"
                  submitButtonText="Update"
                  onClose={() => setVisible(false)}
                >
                  <Row style={{ width: '100%' }}>
                    <div className="ml-4"><b>Internal Notes:</b> {orderSource.internalNotes || 'N/A'}</div><br />
                  </Row>
                  <Row style={{ width: '100%' }}>
                    <div className="ml-4"><b>Customer Notes:</b> {orderSource.customerNotes || 'N/A'}</div><br />
                  </Row>
                  <Row style={{ width: '100%' }}>
                    {/* eslint-disable-next-line max-len */}
                    <div className="ml-4"><b>Requested Shipping Service:</b> {orderSource.requestedShippingService || 'N/A'}</div><br />
                  </Row>
                  <Row style={{ width: '100%' }}>
                    <div className="ml-4"><b>Service Code:</b> {orderSource.serviceCode || 'N/A'}</div>
                  </Row>
                  <Row style={{ width: '100%' }}>
                    <CarrierColumns shippingEstimates={fetchedData} />
                  </Row>
                </ModalFormWrapper>
              )
            }
          </FetchDataHoc>
        )
      }
      <div>
        <b className="mr-2">Carrier Service</b>
        {`${carrierName} ${readableServiceCode}`}
        <span
          style={{ color: '#70bbfd', cursor: 'pointer' }}
          className="ml-3"
          role="link"
          tabIndex={0}
          onClick={() => setVisible(true)}
          onKeyDown={() => setVisible(true)}
        >
          {carrierServiceCode ? 'Update Carrier Service' : 'Set Carrier Service'}
        </span>
      </div>
    </>
  );
};

CarrierService.propTypes = {
  id: PropTypes.number.isRequired,
  carrierServiceCode: PropTypes.string,
  orderSource: PropTypes.shape({
    internalNotes: PropTypes.string,
    customerNotes: PropTypes.string,
    requestedShippingService: PropTypes.string,
    serviceCode: PropTypes.string,
  }),
};

CarrierService.defaultProps = {
  carrierServiceCode: null,
  orderSource: null,
};

export default withRouter(CarrierService);
