import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import TextField from '../../shared/components/form/TextField';

const Form = () => (
  <Container>
    <ShowContainer>
      {
        ({ id, quantity, returnedData }) => {
          if (!id) return null;
          const { returnedQuantity, restockableQuantity } = returnedData;
          const initialValues = {
            id, quantity, returnedQuantity, restockableQuantity,
          };

          const validationSchema = Yup.object().shape({
            id: Yup.number().required('Required'),
            returnedQuantity: Yup.number()
              .required('Required')
              .integer('Must be whole number')
              .moreThan(-1, 'Must be positive')
              .lessThan(quantity + 1, `Must be less than or equal to ${quantity}`)
              .typeError('Enter a valid number'),
            restockableQuantity: Yup.number()
              .required('Required')
              .integer('Must be whole number')
              .moreThan(-1, 'Must be positive')
              .test('less than returned', 'Must be less than or equal to Returned Quantity',
                function test() {
                  // eslint-disable-next-line
                  const returned = this.resolve(Yup.ref('returnedQuantity'));
                  // eslint-disable-next-line
                  const restockable = this.resolve(Yup.ref('restockableQuantity'));
                  return restockable <= returned;
                })
              .typeError('Enter a valid number'),
          });

          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/update_returned_data`}
              formTitle="Update Returned Data"
              formSubTitle="Please fill out the relevant information to update the line item for this shipment"
              successModalTitle="Line Item Updated!"
              successModalMessage="You will be redirected back to the Return Shipments index page"
              submitButtonText="Update Return Data"
              successUrl="/orders/return-shipments"
            >
              <div className="customizer__form-group-wrap no-bottom-line" style={{ width: '100%' }}>
                <div className="mx-3">
                  <Row style={{ width: '100%' }}>
                    <Col lg={4}>
                      <TextField
                        label="Expected Quantity"
                        fieldName="quantity"
                        disabled
                      />
                    </Col>
                    <Col lg={4}>
                      <TextField
                        label="Returned Quantity"
                        fieldName="returnedQuantity"
                        placeholder="Returned Quantity..."
                      />
                    </Col>
                    <Col lg={4}>
                      <TextField
                        label="Restockable Quantity"
                        fieldName="restockableQuantity"
                        placeholder="Restockable Quantity..."
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </FormWrapper>
          );
        }
      }
    </ShowContainer>
  </Container>
);

Form.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
};

export default withRouter(connect()(Form));
