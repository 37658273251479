import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import randInt from '../../../../helpers/randInt';

const AddressValidationTooltip = ({ id, validation }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  if (!validation) return null;
  const { status, errors } = validation;

  let action;
  switch (status) {
    case 'verified':
      action = 'Address Verified';
      break;
    case 'unverified':
      action = (
        <div>
          <div>Unverified Address:</div>
          { errors.map((e) => <div key={randInt()}>{e.message}</div>)}
        </div>
      );
      break;
      // no default
  }
  return (
    <span>
      <span className="link-text" id={id}>
        {
          status === 'verified'
            ? (<span aria-label="verified" role="img" color="green">&#10003;</span>)
            : (<span aria-label="unverified" role="img">&#10071;</span>)
        }
      </span>
      <Tooltip
        id={id}
        placement="right"
        target={id}
        isOpen={tooltipOpen}
        toggle={toggle}
      >
        {action}
      </Tooltip>
    </span>
  );
};

AddressValidationTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  validation: PropTypes.shape({
    status: PropTypes.string,
    errors: PropTypes.array,
  }).isRequired,
};

export default AddressValidationTooltip;
