import React from 'react';
import { Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import randInt from '../../helpers/randInt';

const WooCommerceSalesOrders = () => {
  const createRows = (list) => (
    list.map((salesOrder) => {
      const {
        id,
        createTimeFriendly,
        status: orderStatus,
        vectorStatus,
        shippingLines,
        shipping,
        lineItems,
        // serviceCode,
      } = salesOrder;

      return (
        <tr key={id}>
          <td>
            <b>Woo Order Number: </b>{id}<br />
            <b>Time: </b>{createTimeFriendly}<br />
            <b>Service: </b>{shippingLines[0] && shippingLines[0].methodTitle}
          </td>
          <td>
            <b>WooCommerce: </b>{orderStatus}<br />
            <b>Vector: </b> {vectorStatus}
          </td>
          <td>
            <div>{shipping.firstName} {shipping.lastName}</div>
            {shipping.company && <div>{shipping.company}</div>}
            <div>{shipping.address1}</div>
            {shipping.address2 && <div>{shipping.address2}</div>}
            {shipping.address3 && <div>{shipping.address3}</div>}
            <div>
              {shipping.city}, {shipping.state}
              <span className="ml-2">{shipping.postcode}</span>
            </div>
            <div>*No Residential Indicator*</div>
          </td>
          <td>
            {
              lineItems.map(({
                name, quantity, sku,
              }) => (
                <div>
                  Qty {quantity} - {sku} - {name}
                </div>
              ))
            }
          </td>
          <td>
            {
              vectorStatus === 'not_imported'
              && (
                <Link
                  to={{
                    pathname: '/orders/sales-orders/new',
                    state: {
                      // customerNotes,
                      // internalNotes,
                      // requestedShippingService: shippingLines[0].methodTitle,
                      // serviceCode,
                      initialValues: {
                        source: 'woo_commerce',
                        external_order_id: id,
                        name: `${shipping.firstName} ${shipping.lastName}`,
                        business: shipping.company,
                        address1: shipping.address1,
                        address2: shipping.address2,
                        city: shipping.city,
                        state: shipping.state,
                        zip: shipping.postcode,
                        country: shipping.country,
                        // isResidential: shipping.residential ? 'true' : 'false',
                        // carrierServiceCode: '',
                        fulfillmentType: 'new_order',
                        lineItems: lineItems.map((i) => (
                          {
                            contentId: i.sku,
                            quantity: i.quantity,
                            name: i.name,
                            id: randInt(),
                          }
                        )),
                      },
                    },
                  }}
                >
                  Create
                </Link>
              )
            }
          </td>
        </tr>
      );
    })
  );

  // eslint-disable-next-line
  const buildBadgeList = (filter, defaultFilter) => {
    const {
      orderStatus,
      search,
    } = filter;
    const {
      search: defaultSearch,
      orderStatus: defaultOrderStatus,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('orderStatus'):
          if (orderStatus && orderStatus !== defaultOrderStatus) {
            badgeList.push(
              {
                order: 2,
                title: 'Status',
                desc: filter[key].replace(/_/g, ' '),
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  };

  return (
    <IndexTableContainer
      newFormUri="sales-orders/new"
      pageTitle="WooCommerce Sales Orders"
      searchPlaceholder="Search by WooCommerce Order ID..."
      filterForm={
        [
          {
            type: 'RadioButtonGroup',
            groupLabel: 'Status',
            filterKey: 'orderStatus',
            options: [
              { value: 'processing', label: 'Processing' },
              // { value: 'pending', label: 'Pending' },
              { value: 'cancelled', label: 'Cancelled' },
              // { value: 'refunded', label: 'Refunded' },
              // { value: 'on-hold', label: 'On Hold' },
              // { value: 'failed', label: 'Failed' },
              // { value: 'trash', label: 'Trash' },
              { value: 'completed', label: 'Completed' },
              { value: 'any', label: 'All' },
            ],
          },
        ]
      }
      heads={
        [
          { key: 'id', name: 'Info' },
          { key: 'status', name: 'Status' },
          { key: 'recipient', name: 'Recipient' },
          { key: 'lineItems', name: 'Line Items' },
          { key: 'actions', name: 'Actions' },
        ]
      }
      createRows={createRows}
      buildBadgeList={buildBadgeList}
    />
  );
};

export default WooCommerceSalesOrders;
