import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import ShowContainer from '../../shared/components/show/ShowContainer';
import FormWrapper from '../../shared/components/form/FormWrapper';
import DatePickerField from '../../shared/components/form/DatePickerFfield';

const validationSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
  processedDate: Yup.string().required('Required'),
});

const Form = () => (
  <Container>
    <ShowContainer>
      {
        ({ id }) => {
          if (!id) return null;
          const initialValues = { id, processedDate: '' };

          return (
            <FormWrapper
              initialValues={initialValues}
              validationSchema={validationSchema}
              formUrlSuffix={`/${id}/mark_as_processed`}
              formTitle="Mark As Processed Form"
              formSubTitle={`Please fill in the correct information to process Receiving Order #${id}`}
              successModalTitle="Receiving Order Marked As Processed!"
              successModalMessage={`You'll be sent back to the Receiving Order ${id} info page`}
              submitButtonText="Mark As Processed"
              successUrl={`/orders/receiving-orders/${id}`}
            >
              <div
                className="customizer__form-group-wrap no-bottom-line col-md-12"
                style={{ width: '100%' }}
              >
                <div className="mx-3">
                  <Row style={{ width: '100%' }}>
                    <Col lg={6}>
                      <div className="form__form-group">
                        <DatePickerField
                          fieldName="processedDate"
                          label="Processed Date"
                          placeholder="Processed Date..."
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </FormWrapper>
          );
        }
      }
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Form));
