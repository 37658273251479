export const API = 'API';
export const API_START = 'API_START';
export const API_END = 'API_END';

export const apiStart = (label) => ({
  type: API_START,
  payload: label,
});

export const apiEnd = (label) => ({
  type: API_END,
  payload: label,
});

export function apiAction({
  url = '',
  method = 'GET',
  params = {},
  onSuccess = null,
  onFailure = null,
  setLoading = true,
  resource = '',
  history = null,
  successHistoryPushTo = null,
  successTimeout = 1000,
}) {
  return {
    type: API,
    payload: {
      url,
      method,
      params,
      onSuccess,
      onFailure,
      setLoading,
      resource,
      history,
      successHistoryPushTo,
      successTimeout,
    },
  };
}
